import { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Popover } from '@material-ui/core'
import { Emitter, EVENT_TYPE } from '../helpers/EventEmitter'
import CloseIcon from '@material-ui/icons/Close'
import { FlowBuilderContext, NodeState } from '../FlowBuilderProvider'
import SearchBar from 'components/SearchBar'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 15px 5px 15px'
  },
  nodeList: {
    maxHeight: 260,
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: 80
  },
  subHeader: {
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '5px 15px'
  },
  nodeDiv: {
    display: 'grid',
    gridTemplateColumns: '30px auto',
    gridGap: 10,
    alignItems: 'center',
    padding: '7px 15px',
    fontFamily: 'Poppins, sans serif',
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  nodeName: {
    paddingBottom: 3,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  searchDiv: {
    padding: '10px 15px',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    marginBottom: 10
  }
}))

interface PopoverProps {
  anchorEl: HTMLDivElement
  source: any
  left: number
  top: number
}

function NodeRow (props: any): JSX.Element {
  const classes = useStyles()
  const { nodeMapping } = useContext(FlowBuilderContext)
  const node: string = props.node
  const mapping = nodeMapping[node]
  return (
    <div
      className={classes.nodeDiv}
      onClick={() => props.handleNodeSelection(node)}
    >
      <Icon icon={mapping.icon} color={mapping.color} />
      <div className={classes.nodeName}>
        {mapping.title}
      </div>
    </div>
  )
}

// Possibly have a mapping for the listTypes that will give a specific filtering for the node popover list
// Right now the only conditional filtering we have is if the port type is an integration port

type NodeListProps = {
  objectType?: string
  search: string
  handleNodeSelection: any
  listType?: string
}
function NodeList (props: NodeListProps): JSX.Element {
  const classes = useStyles()
  const { nodeCategories } = useContext(FlowBuilderContext)

  return (
    <div className={classes.nodeList}>
      {nodeCategories.filter((c) => {
        if (props.listType === 'integration') {
          return ['integrations', 'engagement'].includes(c.label.toLowerCase())
        }
        return ['attributes', 'buying signals', 'internal'].includes(c.label.toLowerCase()) || props.objectType !== 'plays'
      }).map((c, index) => {
        const catLabel = c.label.toLowerCase()
        const nodes = c.nodes.filter((node: any) => {
          const nodeLabel = node.label.toLowerCase()
          if ((props.objectType === 'plays') && catLabel === 'internal' && nodeLabel !== 'end play') {
            return false
          }
          if (props.listType === 'integration' && catLabel === 'engagement' && nodeLabel !== 'send email') {
            return false
          }
          return nodeLabel.includes(props.search.toLowerCase())
        })
        if (nodes.length === 0) {
          return (<></>)
        }
        return (
          <div key={index}>
            <div className={classes.subHeader}>{c.label}</div>
            {nodes.map((node: any) => (
              <NodeRow
                node={node.type}
                key={node}
                handleNodeSelection={props.handleNodeSelection}
              />
            ))}
          </div>
        )
      })}
    </div>
  )
}

export function NodePopover (props: any): JSX.Element {
  const [search, setSearch] = useState('')
  const [state, setState] = useState<PopoverProps | null>(null)
  const { addNodeFromEdge, toolkit } = useContext(FlowBuilderContext)
  const classes = useStyles()

  useEffect(() => {
    Emitter.on(EVENT_TYPE.DROP_EDGE, (payload: any) => {
      if (toolkit.id === payload.toolkitID) {
        setState(payload.data)
      }
    })
  }, [toolkit.id])

  const handleClose = (): void => {
    setState(null)
    setSearch('')
    const el = document.getElementById('popover-anchor-div')
    if (el) {
      el.remove()
    }
  }

  const handleNodeSelection = (node: string): void => {
    if (state) {
      handleClose()
      const nodeState: NodeState = {
        ...state,
        kind: node
      }
      addNodeFromEdge(nodeState)
    }
  }

  return (
    <Popover
      open={Boolean(state)}
      onClose={handleClose}
      anchorEl={state?.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div style={{ width: 265, height: 380 }}>
        <div className={classes.header}>
          <div>Insert Skill</div>
          <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
        </div>
        <div className={classes.searchDiv}>
          <SearchBar search={search} setSearch={setSearch} />
        </div>
        <NodeList
          handleNodeSelection={handleNodeSelection}
          objectType={props.objectType}
          listType={state?.source?.data?.type}
          search={search}
        />
      </div>
    </Popover>
  )
}
