import useBasicWebsocket from '../use-chat/useBasicWebsocket'
import { useRef } from 'react'

const liveWorker = new Worker('/livePingWorker.js')

export function useLiveChannel (settings) {
  const { url, token, messageCallback } = settings
  const isReconnectRef = useRef(false)
  const { sendMessage, readyState, reconnect } = useBasicWebsocket({
    url: url,
    onMessage: ({ event }) => {
      messageCallback(event)
      try {
        const parsed = JSON.parse(event.data)
        if (parsed.type === 'ping') {
          liveWorker.postMessage('ping')
        }
      } catch (e) {
        console.log('received invalid json:', event.data)
      }
    },
    reconnectDelayBackoff: 1.25,
    maxReconnectDelay: 10000,
    shouldReconnect: () => {
      liveWorker.postMessage('stop')
      isReconnectRef.current = true
      return true
    },
    readyToConnect: Boolean(token),
    onOpen: ({ event, socket }) => {
      liveWorker.postMessage('start')
      if (event.target !== socket) {
        event.target.close()
        return
      }
      if (token) {
        socket.send(JSON.stringify({
          type: 'id',
          token,
          is_reconnect: isReconnectRef.current
        }))
      }
    }
  })

  liveWorker.onmessage = (event) => {
    if (event.data === 'presence') {
      sendMessage({ type: 'presence' })
    } else if (event.data === 'reconnect') {
      reconnect()
    }
  }

  const connectionStatus = readyState

  return {
    connectionStatus
  }
}
