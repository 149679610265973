import { ModalContentsProps } from 'library/flowBuilder/nodes/NodeModal';
import { ModalSection } from 'library/Modal'
import { makeStyles } from '@material-ui/core/styles'
import * as yup from 'yup'
import { useCallback, useEffect, useState } from 'react';
import { getIntegration, getIntegrationFeature } from 'api/integrations'
import { Icon } from 'library/materialUI';
import { Button } from 'library/materialUI/Button';
import AdditionalIntegrationSection from './AdditionalIntegrationSection';
import { ContactList } from 'classes/classes';
import Picker from 'cf-components/Pickers';
import { IconType } from 'library/materialUI/Icon';
import { IntegrationBodyRow } from './IntegrationBodyRow';
import WarningDiv from '../WarningDiv';
import { truncateString } from 'library/helpers';

const useStyles = makeStyles(theme => ({
  categoryButtonContainer: {
    display: 'flex',
    alignItems: 'start',
    border: 'none',
    color: '#9933FF',
    textTransform: 'lowercase'
  }
}))

export const IntegrationSchema = yup.object().shape({
  settings: yup.object({
    listIDs: yup.array().min(0),
    mainIntegration: yup.string().min(1),
    title: yup.string().min(1),
    color: yup.string().min(0),
    integrations: yup.object({
      salesforce: yup.array().min(0),
      marketo: yup.array().min(0),
      hubspot: yup.array().min(0),
      sendinblue: yup.array().min(0),
      eloqua: yup.array().min(0)
    }),
    activeIntegrations: yup.object({
      salesforce: yup.bool(),
      marketo: yup.bool(),
      hubspot: yup.bool(),
      sendinblue: yup.bool(),
      eloqua: yup.bool()
    })
  })
})

type header = {
  icon: IconType
  color: string
  title: string
  style: any
}

export function IntegrationHeader ({ settings }: ModalContentsProps): header {
  if (settings.title === 'Integrations') {
    return {
      icon: 'upload',
      color: 'white',
      title: 'Integrations',
      style: { color: 'white', backgroundColor: '#9933FF' }
    }
  }
  return {
    icon: settings.mainIntegration,
    color: settings.color,
    title: settings.title,
    style: {}
  }
}

type IntegrationArray = [string, number] | []
interface AddIntegrationSectionProps {
  integrationEntryList: IntegrationArray[]
  availableIntegrations: { label: string, value: string }[]
  setAdditionalIntegration: (integration: string, listIDs: any[]) => void
  setIntegrationEntryList: (value: IntegrationArray[]) => void
  altText?: boolean
  setIntegration?: (integration: string) => void
  setListID?: (integration: string, value: string[]) => void
  disabled?: boolean
}

export function AddIntegrationSection ({ integrationEntryList, availableIntegrations, setAdditionalIntegration, setIntegrationEntryList, altText, setIntegration, setListID, disabled }: AddIntegrationSectionProps) {
  const classes = useStyles()
  const displayTitle = altText ? 'Integration' : 'Additional Integration (optional)'

  return (
    <>
      <ModalSection
        title={displayTitle}
      >
        {integrationEntryList.map((entry, index) => {
          return (
            <AdditionalIntegrationSection
              key={index}
              integration={entry[0]}
              listIDs={entry[1]}
              availableIntegrations={availableIntegrations}
              setAdditionalIntegration={setAdditionalIntegration}
              setListID={setListID}
              setIntegration={setIntegration}
            />
          )
        })}
        <Button
          className={classes.categoryButtonContainer}
          onClick={() => {
            setIntegrationEntryList(integrationEntryList.concat([['', []]]))
          }}
          variant='text'
          color='primary'
          style={{ marginTop: 10, cursor: 'pointer' }}
          disabled={disabled === false || availableIntegrations.length === 0}
        >
          {integrationEntryList.length === 0 && altText ? '+ send contact to integration' : '+ add other integration'}
        </Button>
      </ModalSection>
    </>
  )
}

export function IntegrationModal ({ settings, updateSettings }: ModalContentsProps): JSX.Element {
  const [displayWarning, setDisplayWarning] = useState(true)
  const [enabled, setEnabled] = useState(false)
  const [integrationEntryList, setIntegrationEntryList] = useState<any[]>([])
  const [availableIntegrations, setAvailableIntegrations] = useState([])
  const searchParams = {
    extraHeaders: { 'x-integration': settings.mainIntegration }
  }
  const classes = useStyles()

  const checkAdditionalIntegrations = useCallback(() => {
    const integrations = Object.entries(settings.integrations)
    setIntegrationEntryList(integrations.filter((value: any) => value[1].length !== 0))
  }, [settings.integrations])

  const setAdditionalIntegration = (integration: string, listIDs: any[]) => {
    settings.integrations[integration] = listIDs
    settings.activeIntegrations[integration] = true
    settings.title = 'Integrations'
    if (Object.values(settings.integrations).every(integration => integration.length === 0)) {
      settings.title = settings.mainIntegration.charAt(0).toUpperCase() + settings.mainIntegration.slice(1)
    }
  }

  const getAvailable = useCallback(() => {
    getIntegrationFeature('crm.create_contact').then((res: any) => {
      const available = res.data.attributes.available_integrations.filter((integration: any) => integration.enabled && (integration.name !== settings.mainIntegration))
      const enabled = available.map((integration: any) => { return { value: integration.name, label: integration.label } })
      setAvailableIntegrations(enabled)
    })
  }, [settings.mainIntegration])

  useEffect(() => {
    getIntegration(settings.mainIntegration).then((res: any) => {
      if (res.ok) {
        setDisplayWarning(false)
      }
      if (settings.mainIntegration !== '' && res.ok) {
        if (res.data.attributes.integration_settings ? res.data.attributes.integration_settings.enabled : res.data.attributes.enabled) {
          setEnabled(true)
          checkAdditionalIntegrations()
          getAvailable()
          settings.activeIntegrations[settings.mainIntegration] = true
        }
      }
    })
  }, [settings.mainIntegration, getAvailable, checkAdditionalIntegrations, settings.activeIntegrations])

  if (displayWarning || !enabled) {
    settings.listID = '0'
    return (
      <WarningDiv
        title={settings.title}
        notSetup={displayWarning}
      />
    )
  }

  return (
    <div>
      <ModalSection
        title='Campaign'
        subtitle='Which campaign/list should the contact(s) be added to?'
      >
        <Picker
          selection={settings.listIDs}
          setSelection={(e: any) => {
            updateSettings({ listIDs: e })
          }}
          objectType='contactList'
          label='Select a campaign/list'
          loadAllProps={{ searchParams: searchParams }}
          queryBackend
          multiple
        />
      </ModalSection>
      <ModalSection
        title='Additional Integration (optional)'
      >
        {integrationEntryList.map((entry, index) => {
          return (
            <AdditionalIntegrationSection
              key={index}
              integration={entry[0]}
              listIDs={entry[1]}
              availableIntegrations={availableIntegrations}
              setAdditionalIntegration={setAdditionalIntegration}
            />
          )
        })}
        <Button
          className={classes.categoryButtonContainer}
          onClick={() => {
            setIntegrationEntryList(integrationEntryList.concat([['', []]]))
          }}
          variant='outlined'
          color='inherit'
          style={{ marginTop: 10 }}
          disabled={availableIntegrations.length === 0}
        >
          + add other integration
        </Button>
      </ModalSection>
    </div>
  )
}

export function IntegrationBody ({ settings }: any): JSX.Element {
  const searchParams = { extraHeaders: { 'x-integration': settings.mainIntegration } }
  const [displayWarning, setDisplayWarning] = useState(true)
  const [enabled, setEnabled] = useState(false)
  const { data, isLoading } = ContactList.loadAll({ searchParams })

  const checkAdditionalIntegrations = () => {
    const allIntegrations = Object.entries(settings.integrations)
    return allIntegrations.filter((value: any) => value[1].length !== 0)
  }

  useEffect(() => {
    getIntegration(settings.mainIntegration).then((res: any) => {
      if (res.ok) {
        setDisplayWarning(false)
      }
      if (settings.mainIntegration !== '' && res.ok) {
        if (res.data.attributes.integration_settings ? res.data.attributes.integration_settings.enabled : res.data.attributes.enabled) {
          setEnabled(true)
        }
      }
    })
  }, [settings.mainIntegration])

  if (displayWarning || typeof data === 'undefined') {
    return (
      <div style={{ color: '#FF0000', fontSize: '16px', fontWeight: 'bolder' }}><Icon icon='warning' imgStyle={{ height: '16px', width: '16px' }} color='#FF0000' /> {settings.title} needs to be set up</div>
    )
  }
  let list: ContactList[] = []
  let integrations: any[] = []
  if (!displayWarning) {
    list = data.list.filter((row: any) => settings.listIDs.includes(row.id) || settings.integrations[settings.mainIntegration].includes(row.id))
    integrations = checkAdditionalIntegrations()
  }

  if (integrations.length !== 0) {
    return (
      <div>
        {enabled ? <></> : <Icon icon='warning' imgStyle={{ height: '30px', width: '30px' }} color='#FFFF00' />}
        {isLoading
          ? <></>
          : (
            <div style={{ display: 'flex' }}>
              <div style={{ borderRadius: 5 }}><Icon icon={settings.mainIntegration} /></div>
              <div style={{ marginLeft: '10px' }}>
                <div style={{ font: 'normal normal normal 13px/20px Poppins' }}>{settings.mainIntegration.charAt(0).toUpperCase() + settings.mainIntegration.slice(1)}</div>
                <b><i>{list?.map((item: any) => item.name).join(' & ')}</i></b>
              </div>
            </div>)}
        {integrations?.map((entry: any, index: number) => {
          if (entry[1].length !== 0) {
            return (
              <IntegrationBodyRow
                key={index}
                integration={entry[0]}
                listIDs={entry[1]}
              />
            )
          }
          return <div key={index} />
        })}
      </div>
    )
  }
  if (list.length === 0) {
    return (
      <div>
        {enabled ? <></> : <Icon icon='warning' imgStyle={{ height: '30px', width: '30px' }} color='#FFFF00' />}
        Add contacts to <br /> <b><i>{settings.mainIntegration.charAt(0).toUpperCase() + settings.mainIntegration.slice(1)}</i></b>
      </div>
    )
  }
  return (
    <div>
      {enabled ? <></> : <Icon icon='warning' imgStyle={{ height: '30px', width: '30px' }} color='#FFFF00' />}
      Add contacts to <br />
      {isLoading
        ? <></>
        : <b><i>{truncateString(list?.map((item: any) => item.name).join(' & '), 20, true)}</i></b>}
    </div>
  )
}
