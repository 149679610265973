import SalesforceIcon from '../img/salesforce_logo_padding.png'
import MarketoIcon from '../img/marketo_icon.png'
import BrevoLogo from '../img/brevo_logo.png'
import HubspotIcon from '../img/hubspot.png'
import EloquaIcon from '../img/eloqua_icon.svg'
import EmailIcon from '../img/contact-insight-email.png'
import dateFormat from 'dateformat'
import { getIntegrations } from 'api/integrations'

export type IntegrationMappingType = {
  name: string,
  avatarUrl: string
}

export type Integrations = typeof integrationsNames[number]
export const integrationsNames = ['salesforce', 'marketo', 'sendinblue', 'hubspot', 'eloqua', 'email'] as const

export function GetIntegrationMapping (integration: Integrations, createdTimestamp: string, name?: string) {
  const date = dateFormat(createdTimestamp, 'mm/dd/yy')
  const integrationMapping = {
    salesforce: {
      name: `Sent to Salesforce on ${date}`,
      avatarUrl: SalesforceIcon
    },
    marketo: {
      name: `Sent to Marketo on ${date}`,
      avatarUrl: MarketoIcon
    },
    sendinblue: {
      name: `Sent to Brevo on ${date}`,
      avatarUrl: BrevoLogo
    },
    hubspot: {
      name: `Sent to Hubspot on ${date}`,
      avatarUrl: HubspotIcon
    },
    eloqua: {
      name: `Sent to Eloqua on ${date}`,
      avatarUrl: EloquaIcon
    },
    email: {
      name: `Sent "${name}" email on ${date}`,
      avatarUrl: EmailIcon
    }
  }
  return integrationMapping[integration]
}

interface IntegrationAttributes {
  // eslint-disable-next-line
  integration_name: Integrations
  enabled: boolean
}
export async function GetEnabledIntegrations () {
  const { data: integrations }: { data: { attributes: IntegrationAttributes, id: number }[] } = await getIntegrations()
  const enabledIntegrations = {
    salesforce: false,
    marketo: false,
    hubspot: false,
    sendinblue: false,
    eloqua: false
  }
  integrations.forEach(integration => {
    enabledIntegrations[integration.attributes.integration_name] = integration.attributes.enabled
  })
  return enabledIntegrations
}
