import { useState, useEffect, useCallback } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import { faqSearch } from 'api/faq'
import OptionPort from '../OptionPort'
import { Divider, Chip, Input, FormControl, FormControlLabel, Checkbox, ListItemText, Radio, RadioGroup, Typography, Select as MuiSelect } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import theme from 'theme'
import MenuItem from '@material-ui/core/MenuItem'
import useNodeStyles from './NodeStyles'
import RenderEmoji from 'pages/chat/RenderEmoji'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import { getIntegrationFeature } from 'api/integrations'
import '../quill.css'
import 'jsplumb'
import PropTypes from 'prop-types'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FAQConditionModal from './FAQConditionModal'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import ButtonTextField from 'library/textFields/ButtonTextField'
import { Button, Icon, Switch } from 'library/materialUI'
import { EVENT_TYPE, Emitter } from 'emitter'
import { Modal, ModalSection } from 'library/Modal'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'

const useStyles = makeStyles(theme => ({
  form: {
    fontFamily: 'Poppins, sans serif'
  },
  subsection: {
    marginTop: 15,
    fontSize: '14px',
    fontWeight: 600
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  options: {
    display: 'flex',
    marginTop: 8,
    flexFlow: 'wrap'
  },
  deleteIcon: {
    fill: '#a6a6a6'
  },
  sectionHeader: {
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonText: {
    padding: 0
  },
  buttonRoot: {
    padding: '2px 5px',
    fontSize: '13px',
    lineHeight: 1
  },
  responseTypeDiv: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '13px',
    color: '#4C4C4C',
    marginBottom: 10,
    marginTop: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectInput: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    color: '#5C5C5C'
  },
  contentClass: {
    overflowY: 'hidden',
    padding: 0,
    height: 360,
    borderTop: 'none'
  },
  titleClass: {
    paddingBottom: 0
  },
  editableChip: {
    backgroundColor: '#D3D3D3',
    borderRadius: 16,
    fontSize: '13px',
    fontFamily: 'Poppins, sans serif',
    height: 32,
    padding: '7px 12px'
  },
  chip: {
    backgroundColor: '#D3D3D3',
    margin: 5
  },
  tags: {
    marginTop: 20,
    color: '#A7A7A7',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center'
  },
  selectTag: {
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    color: '#A7A7A7',
    borderRadius: 6,
    borderColor: '#CCCCCC',
    width: '100%'
  },
  label: {
    fontWeight: 600,
    fontSize: '14px'
  },
  questionText: {
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontSize: 14,
    color: 'grey',
    marginTop: 15,
    marginBottom: 5
  },
  liveChatDescription: {
    fontFamily: 'Poppins',
    fontSize: 14,
    color: 'grey',
    marginTop: -10
  },
  paper: {
    marginTop: -5
  },
  condition: {
    marginLeft: 5,
    padding: '5px 16px',
    borderRadius: 17,
    backgroundColor: '#DCDCDC',
    fontSize: 14
  },
  flexed: {
    display: 'flex',
    alignItems: 'center'
  },
  icons: {
    height: 22,
    color: theme.palette.primary.main,
    marginRight: 18
  },
  linkText: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.palette.primary.main,
    fontSize: '12px'
  },
  plusIcon: {
    marginLeft: '15px',
    cursor: 'pointer'
  }
}))

function getWidthOfInput (value) {
  const div = document.createElement('div')
  div.id = 'button-div'
  div.innerHTML = value.endsWith(' ') ? value + 's' : value
  div.style.cssText = 'position:absolute; font-size: 13px; font-family: Poppins, sans serif; visibility: hidden; min-width: 45px; padding: 12px; font-weight: 600'
  document.body.appendChild(div)
  const width = div.offsetWidth
  document.body.removeChild(div)
  return width
}

function EditableChip (props) {
  const classes = useStyles()
  const buttonType = props.optionId

  let text = ''
  if (buttonType === 'resolved') {
    text = <div style={{ whiteSpace: 'pre-line' }}>{'This button moves visitors\nto the next node.'}</div>
  } else if (buttonType === 'again') {
    text = <div style={{ whiteSpace: 'pre-line' }}>{'This button allows the\nvisitor to ask the bot\nanother question.'}</div>
  } else if (buttonType === 'live-chat') {
    text = <div style={{ whiteSpace: 'pre-line' }}>{'This button should connect\nto a live chat node.'}</div>
  }

  return (
    <div style={{ width: getWidthOfInput(props.value), margin: 5 }}>
      <Tooltip title={text} placement='bottom'>
        <Input
          value={props.value}
          disableUnderline
          onChange={e => props.setValue(props.index, e.target.value)}
          fullWidth
          className={classes.editableChip}
        />
      </Tooltip>
    </div>
  )
}

const LiveChatConditions = (props) => {
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)
  const [editIndex, setEditIndex] = useState(null)
  const [editCondition, setEditCondition] = useState({})
  const [disableButton, setDisableButton] = useState(false)
  // conditionArray will contain objects containing name and filters keys
  const conditionArray = props.conditionArray
  const setConditionArray = props.setConditionArray

  useEffect(() => {
    if (conditionArray?.length === 0) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [conditionArray])

  const saveCancel = (cond, idx, type) => {
    if (type === 'save') {
      if (idx !== null) {
        const tempArray = [...conditionArray]
        tempArray.splice(idx, 1, cond)
        setConditionArray(tempArray)
      } else {
        setConditionArray([...conditionArray, cond])
      }
    }
    setEditIndex(null)
    setEditCondition({})
    setOpenModal(!openModal)
  }

  const handleClick = (type, idx = null) => {
    if (type === 'delete') {
      // const tempArray = conditionArray.filter((e, i) => i !== idx)
      const tempArray = [...conditionArray]
      tempArray.splice(idx, 1)
      setConditionArray(tempArray)
    } else if (type === 'edit') {
      setEditIndex(idx)
      setEditCondition(conditionArray[idx])
      setOpenModal(!openModal)
    } else {
      setOpenModal(!openModal)
    }
  }

  const ConditionList = (props) => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={classes.flexed}>
            <DragIndicatorIcon style={{ paddingLeft: 2, fontSize: '1.5rem' }} />
            <div className={classes.condition}>{props.name}</div>
          </div>
          <div className={classes.flexed}>
            <DeleteForeverIcon className={classes.icons} onClick={() => handleClick('delete', props.index)} />
            <EditIcon className={classes.icons} onClick={() => handleClick('edit', props.index)} />
          </div>
        </div>
        <Divider className={classes.divider} />
      </>
    )
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, marginBottom: 10 }}>
        <Button
          variant='outlined'
          onClick={() => handleClick('add')}
          disabled={disableButton}
          style={{ textTransform: 'none' }}
        >
          <AddBoxIcon />
          <div style={{ marginLeft: 10 }}>Add Condition</div>
        </Button>
      </div>
      <Divider className={classes.divider} />
      {
        conditionArray?.map((obj, index) => {
          return (
            <ConditionList
              key={index}
              name={obj.name}
              index={index}
            />
          )
        })
      }
      {openModal &&
        <FAQConditionModal
          openModal={openModal}
          condition={editCondition}
          editIndex={editIndex}
          chatServiceUrl={props.chatServiceUrl}
          saveCancel={saveCancel}
        />}
    </div>
  )
}

function CategorySelect (props) {
  const classes = useStyles()

  return (
    <div className={classes.selectTag}>
      <MuiSelect
        variant='outlined'
        name={props.name}
        fullWidth
        margin='dense'
        value={props.value}
        multiple
        renderValue={(selected) => {
          if (selected.length === 0) {
            return 'Select ' + props.title
          } else {
            const disp = []
            props.goals.forEach(g => {
              if (selected.indexOf(g.id) > -1) {
                disp[disp.length] = g.value
              }
            })
            return disp.join(', ')
          }
        }}
        displayEmpty
        onChange={props.handleChange}
      >
        <MenuItem disabled value=''>Select {props.title}</MenuItem>
        {
          props.goals.map((g, index) => (
            <MenuItem value={g.id} key={index}>
              <Checkbox checked={props.value.indexOf(g.id) > -1} color='primary' />
              <ListItemText primary={g.value} />
            </MenuItem>
          ))
        }
      </MuiSelect>
    </div>
  )
}

function TagSelect (props) {
  const classes = useStyles()

  return (
    <div className={classes.selectTag}>
      <Select
        name={props.name}
        value={props.value}
        multiple
        renderValue={(selected) => selected.length === 0 ? '' : selected.join(', ')}
        onChange={props.handleChange}
        placeholder='Select tag(s)'
        options={props.goals.map(g => ({ value: g, label: g }))}
      />
    </div>
  )
}

function TabPanel (props) {
  const { children, value, index } = props

  return (
    <div
      hidden={value !== index}
    >
      {children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function FaqModal (props) {
  const classes = useStyles()
  const [text, setText] = useState(props.body)
  const [ports, setPorts] = useState(props.ports)
  const [failureMessage, setFailureMessage] = useState(props.failureMessage)
  const [retryMessage, setRetryMessage] = useState(props.retryMessage || '')
  const [buttonLabel, setButtonLabel] = useState('')
  const [selectedTags, setSelectedTags] = useState({ tags: props.tags })
  const [tenantTags, setTenantTags] = useState(props.tenantTags)
  const [tenantCategories, setTenantCategories] = useState(props.tenantCategories)
  const [selectedCategories, setSelectedCategories] = useState({ categories: props.categories })
  const [chatServiceUrl, setServiceUrl] = useState(null)
  const [kbEnabled, setKBEnabled] = useState(false)
  const [kbIntegrationName, setKBIntegrationName] = useState(false)
  const [faqSource, setFAQSource] = useState(props.faqSource || 'cf-faq')
  const [tab, setTab] = useState(0)
  const [conditionArray, setConditionArray] = useState(props.conditionArray || [])

  useEffect(() => {
    setConditionArray(props.conditionArray || [])
  }, [props.conditionArray])

  useEffect(() => {
    setFailureMessage(props.failureMessage)
  }, [props.failureMessage])

  useEffect(() => {
    setRetryMessage(props.retryMessage)
  }, [props.retryMessage])

  useEffect(() => {
    setText(props.body)
  }, [props.body])

  useEffect(() => {
    setPorts(props.ports)
  }, [props.ports])

  useEffect(() => {
    const loadKBCategories = () => {
      fetch('/api/integrations/knowledgebase/categories', {
        method: 'GET',
        headers: { 'Content-Type': 'application/vnd.api+json' }
      })
        .then(response => response.json())
        .then(response => {
          if (response.data && response.data) {
            const categories = []
            response.data.forEach((res) => {
              categories[categories.length] = {
                id: res.id,
                value: res.attributes.name
              }
            })
            if (!selectedCategories.categories) {
              setSelectedCategories({ categories: [] })
            }
            setTenantCategories(categories)
          }
        })
    }
    getIntegrationFeature('knowledgebase')
      .then((response) => {
        if (response.data.attributes.integration) {
          setKBEnabled(true)
          setKBIntegrationName(response.data.attributes.integration.label)
          loadKBCategories()
        }
      }, (err) => {
        console.error(err)
      })
  }, [selectedCategories.categories])

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
        }
      })
  }, [])

  useEffect(() => {
    if (chatServiceUrl) {
      faqSearch({ search: '', chatServiceUrl }).then(response => {
        const tags = [].concat.apply([], response.data.map(f => f.attributes.tags))
        setTenantTags(tags)
      })
    }
  }, [chatServiceUrl])

  function editText (value, delta, source, editor) {
    setText(value)
  }

  function editFailureResponse (value, delta, source, editor) {
    setFailureMessage(value)
  }

  function editRetryMessage (value, delta, source, editor) {
    setRetryMessage(value)
  }

  function cancelChanges () {
    props.onHide()
    setText(props.body)
    setPorts(props.ports)
  }

  function handleChange (value) {
    setSelectedTags({ tags: value })
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  function handleCategoryChange (event) {
    const name = event.target.name
    const value = event.target.value
    setSelectedCategories({ ...selectedCategories, [name]: value })
  }

  const handleDelete = optionToDelete => () => {
    setPorts(options => options.filter(option => option.id !== optionToDelete.id))
  }

  function addOption (label) {
    if (label === '') {
      return
    }
    const newOptions = ports.concat({
      id: jsPlumbUtil.uuid(),
      label: label
    })
    setPorts(newOptions)
  }

  function editOption (index, label) {
    const newPort = ports[index]
    newPort.label = label
    setPorts([
      ...ports.slice(0, index),
      newPort,
      ...ports.slice(index + 1)
    ])
  }

  function handleSwitchChange (checked) {
    if (checked) {
      const newOptions = ports.concat({
        id: 'live-chat',
        label: 'Chat with a real person'
      })
      setPorts(newOptions)
    } else {
      setPorts(ports.filter(port => port.id !== 'live-chat'))
    }
  }

  function saveChanges () {
    let newOptions
    const tags = selectedTags.tags
    const categories = selectedCategories.categories
    if (buttonLabel) {
      newOptions = ports.concat({
        id: jsPlumbUtil.uuid(),
        label: buttonLabel
      })
      setPorts(newOptions)
      setButtonLabel('')
    } else {
      newOptions = ports
    }
    props.save({
      message: text,
      ports: newOptions,
      tags,
      failureMessage,
      retryMessage,
      faqSource,
      categories,
      conditionArray
    })
  }

  const checked = ports.filter(port => port.id === 'live-chat').length === 1

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={() => {
          saveChanges()
          setTab(0)
        }}
        title='FAQ'
        helplink='home/bots/nodes/FAQ'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
        tabLabels={checked ? ['General', 'Live Chat Availability'] : null}
        tab={tab}
        handleTabChange={handleTabChange}
      >
        <TabPanel value={tab} index={0}>
          <ModalSection
            title='Bot Messages'
            subtitle='What should the bot say to prompt the visitor to ask their question?'
          >
            <div className='customQuill'>
              <RichTextEditor
                message={text}
                onChange={editText}
                placeholder='Enter a message here...'
                lines={3}
                showMergeFields
              />
            </div>
            <div className={classes.questionText}>
              If it’s unable to find an answer, what should the bot say?
            </div>
            <div className='emailResponse'>
              <div className='customQuill'>
                <RichTextEditor
                  message={failureMessage}
                  onChange={editFailureResponse}
                  placeholder="What should the bot say if it can't find an answer?"
                  lines={3}
                />
              </div>
            </div>
            <div className={classes.questionText}>
              If the visitor wants to try asking again, what should the bot say?
            </div>
            <div className='emailResponse'>
              <div className='customQuill'>
                <RichTextEditor
                  message={retryMessage}
                  onChange={editRetryMessage}
                  placeholder='What should the bot say when someone requests to ask the question again?'
                  lines={3}
                />
              </div>
            </div>
          </ModalSection>
          <ModalSection
            title='Follow-up Actions'
            subtitle='After the bot gives a response, what options do you want to give the visitor? The default buttons seen below can’t be removed, but you can click on them and edit their text, and you can also add new buttons.'
          >
            <div className={classes.options} id='buttons'>
              {
                ports.map((option, index) => {
                  const undeletable = ['resolved', 'again', 'live-chat'].includes(option.id)
                  if (undeletable) {
                    return (
                      <EditableChip
                        key={option.id}
                        index={index}
                        value={option.label}
                        setValue={editOption}
                        optionId={option.id}
                      />
                    )
                  }
                  return (
                    <Chip
                      key={option.id}
                      label={option.label}
                      onDelete={undeletable ? null : handleDelete(option)}
                      className={classes.chip}
                      classes={{ deleteIcon: classes.deleteIcon }}
                    />
                  )
                })
              }
            </div>
            <div>
              <ButtonTextField
                addButton={addOption}
                label={buttonLabel}
                setLabel={setButtonLabel}
              />
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                <Typography variant='body1' style={{ fontSize: 14 }}>
                  Allow Live Chat
                </Typography>
                <Switch
                  checked={checked}
                  handleChange={handleSwitchChange}
                  color='secondary'
                />
              </div>
              <div className={classes.liveChatDescription}>
                Click on the "Live Chat Availability" tab to set live chat conditions
              </div>
            </div>
          </ModalSection>
          {kbEnabled ? (
            <ModalSection
              title='FAQ Source'
            >
              <div className={classes.subsection}>
                <FormControl component='fieldset'>
                  <RadioGroup aria-label='faq-source' name='faqSource' value={faqSource} onChange={(e) => setFAQSource(e.target.value)}>
                    <FormControlLabel
                      key='faq-source-1'
                      value='cf-faq'
                      control={<Radio color='primary' classes={{ root: classes.radio }} />}
                      label='Signals FAQ'
                      classes={{ label: classes.label }}
                    />
                    <FormControlLabel
                      key='faq-source-2'
                      value='external-kb-faq'
                      control={<Radio color='primary' classes={{ root: classes.radio }} />}
                      label={kbIntegrationName}
                      classes={{ label: classes.label }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </ModalSection>
          ) : (
            <></>
          )}

          {/* {kbEnabled === false || faqSource === 'cf-faq' ? ( */}
          {faqSource === 'asd' ? (
            <ModalSection
              title='FAQ Tags'
              subtitle='Use FAQ tags to only give answers from certain groups of FAQs. Only FAQs with the selected tags will be shown.'
            >
              <div className={classes.subsection}>
                <div className={classes.tags}>
                  <TagSelect
                    name='tags'
                    title='FAQ Tags'
                    goals={[...new Set(tenantTags)]}
                    value={selectedTags.tags.filter(value => tenantTags?.includes(value))}
                    handleChange={handleChange}
                  />
                  <Tooltip title='Edit FAQ Settings'>
                    <div onClick={() => window.open('/#/settings/faq_management/faqs')} className={classes.plusIcon}>
                      <Icon icon='addBox' size='md' />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </ModalSection>
          ) : (
            <>
              {tenantCategories && tenantCategories.length > 0 ? (
                <ModalSection
                  title='Categories'
                  subtitle='If you’d like the bot to only give answers from certain categories and sections, you can select which ones you want to use below.'
                >
                  <div className={classes.subsection}>
                    <div className={classes.sectionHeader}>
                      Categories
                    </div>
                    <Divider className={classes.divider} />

                    <div className={classes.questionText} style={{ fontSize: 13, color: '#383838', marginBottom: -10 }}>
                      If you’d like the bot to only give answers from certain categories and sections, you can select which ones you want to use below.
                    </div>
                    <div className={classes.subsection}>
                      <CategorySelect
                        name='categories'
                        title='Categories'
                        goals={tenantCategories}
                        value={selectedCategories.categories}
                        handleChange={handleCategoryChange}
                      />
                    </div>
                  </div>
                </ModalSection>
              ) : (<></>)}
            </>
          )}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ModalSection
            subtitle='Set conditions for when live chat will be available. Website visitors will only be given the option to live chat if these conditions are met.'
          >
            <div style={{ marginTop: 10 }}>
              <Divider className={classes.divider} />
              <LiveChatConditions
                chatServiceUrl={chatServiceUrl}
                conditionArray={conditionArray}
                setConditionArray={setConditionArray}
              />
            </div>
          </ModalSection>
        </TabPanel>
      </Modal>
    </ThemeProvider>
  )
}

const queryClient = new QueryClient()

const FaqNode = (props) => {
  const [, setServiceUrl] = useState(null)
  const base = props.base
  const assignKey = (data) => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    }).then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
        }
      })
  }, [])

  const saveChanges = useCallback(({ message, ports, tags, failureMessage, retryMessage, categories, faqSource, conditionArray }) => {
    const new_ids = ports.map(port => port.id)

    setTimeout(() => {
      base.toolkit.batch(() => {
        const node = base.getNode()
        const new_data =
        {
          ...base.state,
          body: message,
          tags: tags,
          categories: categories,
          faqSource: faqSource,
          failureMessage: failureMessage,
          retryMessage: retryMessage,
          conditionArray: conditionArray
        }
        base.toolkit.updateNode(node, new_data)
        const oldPorts = node.getPorts()
        const port_ids = oldPorts.map(i => i.id)
        for (const port_id of port_ids) {
          if (!(new_ids.includes(port_id))) {
            base.toolkit.removePort(node, port_id)
          }
        }
        for (const port of ports) {
          if (!(port_ids.includes(port.id))) {
            base.addNewPort('default', port)
          }
        }
      })
    }, 0)
    base.toolkit.batch(() => {
      props.setModalOpen(false)
    })
  }, [base, props])

  const classes = useNodeStyles(props)

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <div
          className={`${classes.dragger} ${classes.visitorInteraction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><QuestionAnswerIcon /></div>
            <div className={classes.cardTitle}>FAQ</div>
          </div>
          <jtk-target
            scope='default'
          />
          <div className='node-body' style={{ minHeight: 36 }}>
            <RenderEmoji
              text={base.state.body === '' ? 'Enter a message...' : base.state.body}
              rich='true'
              skipLinkPreview
            />
            <Divider style={{ marginTop: 5 }} />
            {base.state.ports.filter(port => port.id !== 'again').map((c, index) => {
              return (
                <OptionPort
                  index={index}
                  data={c}
                  key={assignKey(c)}
                  parent={base}
                  kind='visitorInteraction'
                />
              )
            })}
          </div>
        </div>
      </div>
      <FaqModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        body={base.state.body}
        ports={base.state.ports}
        tags={base.state.tags}
        categories={base.state.categories}
        faqSource={base.state.faqSource}
        failureMessage={base.state.failureMessage}
        retryMessage={base.state.retryMessage}
        conditionArray={base.state.conditionArray}
      />
    </QueryClientProvider>
  )
}

export default FaqNode
