import { createContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PickerObjectType } from 'cf-components/Pickers'
import { IconType } from 'library/materialUI/Icon';
import { getTrafficCampaigns, getTrafficMediums } from 'api/reports';
import dateFormat from 'dateformat';

const useStyles = makeStyles(theme => ({
  filterDiv: {
    backgroundColor: '#EDE5F8',
    fontSize: '13px',
    width: 'fit-content',
    padding: '5px 10px',
    borderRadius: 5,
    marginRight: 2,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5
  },
  filterDivError: {
    backgroundColor: '#FDEBE9',
    color: '#EB4932',
    fontSize: '13px',
    width: 'fit-content',
    padding: '5px 10px',
    borderRadius: 5,
    marginRight: 2,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5
  }
}))

interface ICriteriaContext {
  classes: any
  selectOptions: Record<string, any>[]
  filterOptions: Record<string, any>
  addNewFilter: (option: string) => void
}

const defaultValues = {
  selectOptions: [],
  filterOptions: {},
  classes: {},
  addNewFilter: () => undefined
}

export const CriteriaContext = createContext<ICriteriaContext>(defaultValues)

type BaseCriteriaType = 'numeric' | 'time' | 'url' | 'knownUnknown' | 'geography' | 'crm' | 'select'

type PickerCriteria = {
  label: string
  type: 'picker'
  multiple: boolean
  objectType: PickerObjectType
  icon: IconType
}

type SelectCriteria = {
  label: string
  type: 'select'
  multiple: boolean
  options: any
  icon: IconType
}

type BaseCriteria = {
  label: string
  type: BaseCriteriaType
  icon: IconType
}

type CriteriaOption = BaseCriteria | PickerCriteria | SelectCriteria

export type BranchType = 'bot' | 'url' | 'segment' | 'geography' | 'segmentCriteriaOptions' | 'integration'

type FilterOptions = Record<string, CriteriaOption>

export const getTrafficSourceOptions = () => {
  const sourceDict = [
    { value: 'Organic Search' },
    { value: 'Social Media', children: [{ value: 'Facebook' }, { value: 'Instagram' }, { value: 'LinkedIn' }, { value: 'TikTok' }, { value: 'Twitter' }] },
    { value: 'Paid Search', children: [{ value: 'Google Ad' }, { value: 'LinkedIn Ad' }] },
    { value: 'Campaign', children: [] },
    { value: 'Other Mediums', children: [] },
    { value: 'Direct Traffic' }
  ]
  getTrafficCampaigns({ start: '2000-01-01', end: dateFormat(Date.now(), 'yyyy-mm-dd') })
    .then(res => {
      sourceDict[3].children = res.data.map((data: any) => {
        return { label: data.attributes.traffic_campaign, value: data.attributes.traffic_campaign + '*' }
      })
    })
  getTrafficMediums({ start: '2000-01-01', end: dateFormat(Date.now(), 'yyyy-mm-dd') })
    .then(res => {
      sourceDict[4].children = res.data.map((data: any) => {
        return { value: data.attributes.traffic_medium }
      })
    })
  return sourceDict
}

const websiteOptions: FilterOptions = {
  url: { label: 'URL', type: 'url', icon: 'webPage' },
  intentPages: { label: 'High Intent Page(s)', type: 'picker', multiple: true, objectType: 'highIntentCategories', icon: 'webPage' },
  visits: { label: 'Total website visits', type: 'numeric', icon: 'webPage' }, // Switched from visitor attributes
  daysSinceLastVisit: { label: 'Days since last visit', type: 'numeric', icon: 'webPage' }, // Switched from visitor attributes
  trafficSource: { label: 'Traffic source', type: 'select', multiple: true, options: getTrafficSourceOptions(), icon: 'webPage' }
}

const segmentOptions: FilterOptions = {
  segment: { label: 'Company Segment', type: 'picker', multiple: false, objectType: 'segments', icon: 'pieChart' }
}

const geographyOptions: FilterOptions = {
  geography: { label: 'Visitor Location', type: 'geography', icon: 'world' }
}

const segmentCriteriaOptions: FilterOptions = {
  crm: { label: 'CRM Attributes', type: 'crm', icon: 'crm' }
}

const botCriteriaOptions: FilterOptions = {
  botInteraction: { label: 'Interacted with bots', type: 'picker', multiple: false, objectType: 'bots', icon: 'chat' }, // Switched from visitor attributes
  meetingBooked: { label: 'Booked a meeting', type: 'time', icon: 'calendar' } // Switched from visitor attributes
}

// Options for the Email Signal Skill
// const emailCriteriaOptions: FilterOptions = {
//   emailOpened: { label: 'Email opened', type: 'time', icon: 'email' },
//   emailClicked: { label: 'Email clicked', type: 'time', icon: 'email' },
//   emailVisit: { label: 'Visit from email', type: 'time', icon: 'email' },
//   smartIQL: { label: 'Smart IQL', type: 'time', icon: 'email' }
// }

const filterOptionsDict: Record<BranchType, FilterOptions> = {
  // visitor: visitorOptions,
  bot: botCriteriaOptions,
  url: websiteOptions,
  segment: segmentOptions,
  geography: geographyOptions,
  segmentCriteriaOptions: segmentCriteriaOptions
}

type CriteriaContextProps = {
  children: any
  addNewFilter: any
  branchType: BranchType
}

export function CriteriaContextProvider ({ children, addNewFilter, branchType }: CriteriaContextProps): JSX.Element {
  const classes = useStyles()

  const filterOptions = filterOptionsDict[branchType]

  const selectOptions = Object.keys(filterOptions)
    .map((key: string) => ({ value: key, label: filterOptions[key].label, icon: filterOptions[key].icon }))

  return (
    <CriteriaContext.Provider value={{
      filterOptions,
      selectOptions,
      classes,
      addNewFilter
    }}
    >
      {children}
    </CriteriaContext.Provider>
  )
}
