import { makeStyles, styled } from '@material-ui/core/styles'
import {
  Select as MuiSelect,
  Button as MuiButton,
  IconButton as MuiIconButton,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  FormControlLabel,
  InputLabel,
  TextField,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  Theme
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/CancelOutlined'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Dispatch, HTMLInputTypeAttribute, SetStateAction, useEffect, useRef, useState } from 'react'
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete'

declare global {
  interface Window { autocompleteOpen: boolean }
}

type MakeStylesProps = {
  width?: number
}

const useStyles = makeStyles<Theme, MakeStylesProps>(theme => ({
  floatingLabelFocusStyle: {
    color: 'rgba(180, 186, 194, 1)',
    fontStyle: 'italic'
  },
  muiComponent: {
    margin: 0
  },
  textfield: {
    '& .MuiInputBase-input': {
      width: props => `${props.width}ch`,
      minWidth: 30
    }
  }
}))

export interface SelectOption {
  value: any
  label: string
  disabled?: boolean
  type?: string
}
export interface SelectProps {
  onChange: any
  value: any
  options: SelectOption[]
  label?: string
  placeholder?: string
  multiple?: boolean
  autofocus?: boolean
  error?: boolean
  disableClear?: boolean
  freeSolo?: boolean
  inputType?: HTMLInputTypeAttribute
  disabled?: boolean
  max?: number
  min?: number
  onBlur?: (e: any) => void
  filterOptions?: boolean
  width?: string | number
  onFocus?: (e: any) => void
  onKeyPress?: (e: any) => void
  disableHandleEnter?: boolean
}

export interface CheckboxProps {
  onChange: any
  checked: boolean
  label: string
}

function RenderInput (props: any): JSX.Element {
  const [labelWidth, setLabelWidth] = useState(0)
  const textFieldRef = useRef<HTMLDivElement>(null)
  const classes = useStyles({ width: labelWidth })
  HandleInputWidth({ setLabelWidth, label: props.inputProps.value, type: props.type })
  props.inputProps.max = props.max
  props.inputProps.min = props.min

  const handleKeyPress = (e: any) => {
    if (props.max && e.target.value > props.max) {
      e.preventDefault()
      e.target.value = props.max
    }
    if (props.min && e.target.value < props.min) {
      e.preventDefault()
      e.target.value = props.min
    }
    if (e.key === 'Enter') {
      props.onEnter(props.inputProps.value)
      e.preventDefault()
    }
  }
  const textFieldProps: Record<string, any> = {}
  Object.keys(props).forEach(key => {
    if (key !== 'onEnter') {
      textFieldProps[key] = props[key]
    }
  })

  return (
    <TextField
      ref={textFieldRef}
      {...textFieldProps}
      variant='outlined'
      inputProps={props.inputProps}
      label={props.label}
      placeholder={props.placeholder}
      type={props.type}
      className={classes.textfield}
      onKeyUp={(e: any) => handleKeyPress(e)}
    />
  )
}

function HandleInputWidth ({ setLabelWidth, label, type }: { setLabelWidth: Dispatch<SetStateAction<number>>, label: string, type: HTMLInputTypeAttribute }) {
  useEffect(() => {
    const addToWidth = type === 'number' ? 2 : 0
    const width = label.length + addToWidth
    setLabelWidth(width)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label])
}

const isNumber = (evt: any): boolean => {
  evt = (evt) || window.event
  const charCode = (evt.which) ? evt.which : evt.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault()
    return false
  }
  return true
}

const getValue = (option: SelectOption, prop: string): string => {
  if (typeof option === 'string') {
    return option
  } else {
    return option[prop as keyof SelectOption]
  }
}

function renderValue (selection: string[], options: SelectOption[], placeholder?: string): string {
  if (!selection.length) {
    return placeholder || ''
  } else {
    let labels = selection
    // Might need to double check undefineds are not being added to the labels list
    if (options[0]?.value) {
      labels = selection.map(s => options.filter(o => o.value === s)[0]?.label)
    }
    return (
      labels.filter(l => l && l !== '').join(', ')
    )
  }
}

function MultiSelect (props: SelectProps): JSX.Element {
  const classes = useStyles({})
  return (
    <FormControl fullWidth variant='outlined' margin='dense' className={classes.muiComponent}>
      <InputLabel>{props.label}</InputLabel>
      <MuiSelect
        disabled={props.disabled}
        error={props.error}
        variant='outlined'
        fullWidth
        margin='dense'
        value={props.value}
        displayEmpty
        onChange={(e: any) => props.onChange(e.target.value)}
        label={props.label}
        renderValue={(selected: any) => renderValue(selected, props.options, props.placeholder)}
        multiple
        autoFocus={props.autofocus}
        MenuProps={{
          variant: 'menu',
          autoFocus: false,
          disableAutoFocusItem: true,
          disableEnforceFocus: true,
          disableAutoFocus: true,
          getContentAnchorEl: null
        }}
      >
        <MenuItem disabled value=''>{props.placeholder}</MenuItem>
        {props.options.map((option: SelectOption, index: number) => {
          const value = getValue(option, 'value')
          return (
            <MenuItem value={value} key={index} style={{ paddingBottom: 3, paddingTop: 3 }}>
              <Checkbox
                checked={props.value.indexOf(value) > -1}
                color='primary'
                style={{ padding: 5, marginRight: 5 }}
              />
              <ListItemText primary={getValue(option, 'label')} />
            </MenuItem>
          )
        })}
      </MuiSelect>
    </FormControl>
  )
}

function SingleSelect (props: SelectProps): JSX.Element {
  const [value, setValue] = useState(() => {
    let startVal = props.options.filter(obj => props.value === obj.value)[0]
    if (!startVal) startVal = props.value
    return startVal
  })
  HandleChangeFromParent({ setValue, propValue: props.value, value, options: props.options })

  const onChange = (_: any, select: any): void => {
    props.onChange(select ? select.value : null)
    setValue(select)
  }

  const handleFilterOptions = (options: any[], _: any) => {
    return options
  }

  const acAdditionalProps = props.filterOptions === false
    ? {
      filterOptions: (options: any[], state: FilterOptionsState<any>) => handleFilterOptions(options, state)
    }
    : {}

  const handleEnter = (val: any) => {
    if (props.disableHandleEnter) return
    setValue({ label: value.label, value: val })
    props.onChange(val)
  }

  return (
    <div style={{ width: props.width || undefined }}>
      <Autocomplete
        disabled={props.disabled}
        options={props.options}
        groupBy={(opt) => opt.group}
        fullWidth
        value={value}
        autoHighlight
        onChange={(e: any, select: any): void => onChange(e, select)}
        renderInput={(params) => <RenderInput {...params} label={props.label} placeholder={props.placeholder} error={props.error} type={props.inputType} max={props.max} min={props.min} onEnter={handleEnter} />}
        getOptionLabel={(option) => props.freeSolo ? option.value || option : option.label}
        getOptionDisabled={(option) => typeof option.disabled !== 'undefined' ? option.disabled : false}
        renderOption={(option: any) => (<div>{option.label}</div>)}
        size='small'
        onOpen={() => { window.autocompleteOpen = true }}
        onClose={() => { setTimeout(() => { window.autocompleteOpen = false }, 100) }}
        disableClearable={props.disableClear}
        freeSolo={props.freeSolo}
        onBlur={(e: any) => props.onBlur ? props.onBlur(e) : ({})}
        onFocus={(e: any) => props.onFocus ? props.onFocus(e) : ({})}
        onKeyPress={(e: any) => props.onKeyPress ? props.onKeyPress(e) : ({})}
        getOptionSelected={(option, value) => value.value ? option.value === value.value : option.value === value}
        {...acAdditionalProps}
      />
    </div>
  )
}

export function LabeledCheckbox (props: CheckboxProps): JSX.Element {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={(event: any) => props.onChange(event.target.checked)}
          color='primary'
        />
      }
      label={props.label}
    />
  )
}

export type ButtonProps = {
  variant?: 'outlined' | 'contained' | 'text'
  size?: 'small' | 'medium' | 'large'
  onClick: () => void
  label: string
  fullWidth?: boolean
  disabled?: boolean
}

export function Button (props: ButtonProps): JSX.Element {
  return (
    <MuiButton
      color='primary'
      variant={props.variant || 'outlined'}
      onClick={props.onClick}
      size={props.size || 'medium'}
      fullWidth={props.fullWidth || false}
      disabled={props.disabled}
    >
      {props.label}
    </MuiButton>
  )
}

type IconButtonVariant = {
  disabled?: boolean
  variant: keyof typeof iconMapping
  onClick: () => void
  icon?: false
}

type BaseIconButton = {
  disabled?: boolean
  icon: any
  onClick: () => void
  variant?: false
}

export type IconButtonProps = BaseIconButton | IconButtonVariant

const iconMapping = {
  cancel: CancelIcon,
  delete: DeleteIcon,
  add: AddIcon
}

export function IconButton (props: IconButtonProps): JSX.Element {
  let Icon
  if (props.icon) {
    Icon = props.icon
  } else if (props.variant) {
    Icon = iconMapping[props.variant]
  }
  return (
    <MuiIconButton
      disabled={props.disabled}
      color='primary'
      onClick={props.onClick}
      style={{ height: 40 }}
    >
      <Icon />
    </MuiIconButton>
  )
}

export interface TextBoxProps {
  onChange: any
  value: string
  label?: string
  rows?: number
  type?: 'number' | undefined
  autoFocus?: boolean
  id?: string
}

export function TextBox (props: TextBoxProps): JSX.Element {
  const classes = useStyles({})

  const handleKeyPress = props.type ? isNumber : undefined

  return (
    <TextField
      id={props.id}
      type={props.type}
      label={props.label}
      fullWidth
      margin='dense'
      variant='outlined'
      value={props.value}
      onChange={(e: any) => props.onChange(e.target.value)}
      onKeyPress={handleKeyPress}
      className={classes.muiComponent}
      rows={props.rows}
      multiline={Boolean(props.rows)}
      autoFocus={Boolean(props.autoFocus)}
      InputLabelProps={{
        className: classes.floatingLabelFocusStyle
      }}
    />
  )
}

export function Select (props: SelectProps): JSX.Element {
  if (props.multiple) {
    return (
      <MultiSelect
        {...props}
      />
    )
  } else {
    return (
      <SingleSelect
        {...props}
      />
    )
  }
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: 'max-content'
}))

const StyledMuiRadio = styled(MuiRadio)(({ theme }) => ({
  padding: 4
}))

export type RadioProps = MuiRadioProps & {
  label: string
  value: unknown
}

export const Radio = ({ value, label, ...props }: RadioProps): JSX.Element => (
  <StyledFormControlLabel
    control={<StyledMuiRadio color='primary' {...props} />}
    value={value}
    label={label}
  />
)

function HandleChangeFromParent ({ setValue, propValue, value, options }: { setValue: Dispatch<any>, propValue: any, value: any, options: SelectOption[] }) {
  const selectedOption = options.filter(so => so.value === propValue)[0] || propValue
  if (selectedOption !== value) {
    setValue(selectedOption)
  }
}
