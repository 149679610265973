import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as OpenOppIcon } from 'img/Icon open-target.svg'
import { ReactComponent as NoOppIcon } from 'img/Icon material-filter-tilt-shift.svg'
import { ReactComponent as NoOppLiveView } from 'img/Icon no-opp-circle.svg'

const useStyles = makeStyles(theme => ({
  closedWon: {
    display: 'flex',
    height: '20px',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#49CDA214',
    color: '#49CDA2',
    border: '1px solid #fff'
  },
  closedLost: {
    display: 'flex',
    height: '20px',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#E9412A14',
    color: '#EA422A',
    border: '1px solid #fff'
  },
  openOpp: {
    display: 'flex',
    height: '20px',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#4972CC14',
    color: '#4972CC',
    border: '1px solid #fff'
  },
  noOpp: {
    display: 'flex',
    height: '20px',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#3A3A3A14',
    color: '#000000BF',
    border: '1px solid #fff'
  },
  innerText: {
    fontSize: '12px',
    fontFamily: 'poppins',
    margin: 'auto',
    paddingLeft: '5px',
    paddingRight: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

interface OppStatusProps {
  status: string
  liveView?: boolean
}

export default function OppStatusText (props: OppStatusProps) {
  const classes = useStyles()

  const mapping = [
    { id: 'Open Opp', style: classes.openOpp, text: 'OPEN OPP', icon: OpenOppIcon },
    { id: 'No Opp', style: classes.noOpp, text: 'NO OPP', icon: props.liveView ? NoOppLiveView : NoOppIcon }
  ]

  let oppStatus = mapping.find(status => status.id === props.status)

  if (!oppStatus) {
    oppStatus = mapping[1]
  }

  const Component = oppStatus.icon

  return (
    <div className={oppStatus.style}>
      <Component style={{ margin: 'auto', paddingLeft: '5px' }} />
      <div className={classes.innerText} style={{ color: props.liveView ? '#fff' : undefined }}>
        {oppStatus.text}
      </div>
    </div>
  )
}
