import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'
import EnhancedTable from '../../../components/EnhancedTable'

const useStyles = makeStyles(theme => ({
  percent: {
    color: 'rgba(0,0,0,0.5)',
    paddingTop: 2
  },
  botName: {
    fontSize: '1.1em',
    fontWeight: 600,
    textDecoration: 'none',
    color: 'rgba(0,0,0,0.8)'
  },
  link: {
    textDecoration: 'none'
  },
  colorAB: {
    color: '#FFFFFF',
    width: 20,
    height: 20,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5
  }
}))

const metricConversions = {
  email_captured: 'emails_captured',
  primary: 'primary_goals',
  secondary: 'secondary_goals',
  meeting_booked: 'meetings_booked',
  routed_to_live_chat: 'live_chats',
  first_visitor_interaction: 'conversations'
}

const metrics = [
  'conversations',
  'emails_captured',
  'meetings_booked',
  'primary_goals',
  'secondary_goals',
  'live_chats'
]

const headCells = [
  { id: 'variant', align: 'left', disablePadding: true, label: 'Variant', paddingLeft: 15 },
  { id: 'bot_prompts', align: 'center', disablePadding: true, label: 'Bot Prompts', paddingLeft: 15, height: 51 },
  { id: 'conversations', align: 'center', disablePadding: true, label: 'Conversations', paddingLeft: 15 },
  { id: 'emails_captured', align: 'center', disablePadding: true, label: 'Emails Captured', paddingLeft: 15 },
  { id: 'meetings_booked', align: 'center', disablePadding: true, label: 'Meetings Booked', paddingLeft: 15 },
  { id: 'primary_goals', align: 'center', disablePadding: true, label: 'Primary Goals', paddingLeft: 15 },
  { id: 'secondary_goals', align: 'center', disablePadding: true, label: 'Secondary Goals', paddingLeft: 15 },
  { id: 'live_chats', align: 'center', disablePadding: true, label: 'Routed to Live Chat', paddingLeft: 15 }
]

function TestStatsTable (props) {
  const primaryMetric = metricConversions[props.primaryMetric]
  const originalFlowID = props.originalFlowID
  const rows = props.rows.map(row => {
    return (
      {
        id: row.id,
        variant: row.attributes.variant,
        sessions: row.attributes.sessions,
        bot_prompts: row.attributes.bot_prompts,
        conversations: row.attributes.conversations,
        live_chats: row.attributes.live_chat_routes,
        emails_captured: row.attributes.emails_captured,
        meetings_booked: row.attributes.meetings_booked,
        primary_goals: row.attributes.primary_goals,
        secondary_goals: row.attributes.secondary_goals
      })
  })

  function CustomRow (props) {
    const classes = useStyles()
    const row = props.row
    const initials = row.id === originalFlowID ? 'A' : 'B'
    const color = row.id === originalFlowID ? '#8e5ae2' : '#fe9d10'

    function CustomCell (props) {
      const percent = (Math.round((row[props.metric] / row.bot_prompts) * 100) || 0) + '%'
      return (
        <TableCell align='center'>
          <div>{row[props.metric]}</div>
          <div className={classes.percent}>
            {percent}
          </div>
        </TableCell>
      )
    }

    return (
      <>
        <TableCell align='left'>
          <div style={{ height: 'fit-content', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
            <div className={classes.colorAB} style={{ backgroundColor: color }}>
              {initials}
            </div>
            {row.variant}
          </div>
        </TableCell>
        <TableCell align='center'>
          <div>{row.bot_prompts}</div>
          <div className={classes.percent}>
            ---
          </div>
        </TableCell>
        {
          metrics.filter(m => m !== primaryMetric).map((m, index) => (
            <CustomCell metric={m} key={index} />
          ))
        }
      </>
    )
  }

  function columnWidths () {
    return (
      <colgroup>
        <col style={{ width: '16%' }} />
        <col style={{ width: '14%' }} />
        <col style={{ width: '14%' }} />
        <col style={{ width: '14%' }} />
        <col style={{ width: '14%' }} />
        <col style={{ width: '14%' }} />
        <col style={{ width: '14%' }} />
      </colgroup>
    )
  }

  return (
    <EnhancedTable
      hideCheckbox
      headCells={headCells.filter(c => c.id !== primaryMetric)}
      selected={[]}
      rows={rows}
      CustomRow={CustomRow}
      columnWidths={columnWidths}
      hidePagination
      orderBy='variant'
      emptyMessage='Not enough data...yet'
      height={rows.length * 90}
    />
  )
}

export default TestStatsTable
