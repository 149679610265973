import { getPlanLimits, updateToInsights } from 'api/billing'
import { getFeatureFlags } from 'api/feature_flags'
import { getIntegrationFeature } from 'api/integrations'
import { Emitter, EVENT_TYPE } from 'emitter'
import { useEffect, useState, createContext } from 'react'
import { useQuery } from 'react-query'
import { SNAPSHOT_PLAN_NAME } from 'library/constants'
import SnapshotUpgradeModal from 'pages/billing/SnapshotUpgradeModal'

export const FAQ_FLAG = 'faq'
export const CLOUD_AGENT_FLAG = 'cloud-agents'

interface ShareProviderInterface {
  crmIntegration: string,
  setCRMIntegration: (integration: string) => void,
  flags: Record<string, boolean>,
  loadingFlags: boolean,
  loadingPlanComponents: boolean,
  isOn: (component: string) => boolean,
  canAdd: (component: string) => number
  planName: () => string
}

const ShareContext = createContext<ShareProviderInterface>({
  crmIntegration: '',
  setCRMIntegration: () => undefined,
  flags: {},
  loadingFlags: true,
  loadingPlanComponents: true,
  isOn: () => false,
  canAdd: () => 0,
  planName: () => SNAPSHOT_PLAN_NAME
})

function ShareProvider ({ children }: { children?: JSX.Element | JSX.Element[] }) {
  const [crmIntegration, setCRMIntegration] = useState('')
  const [loadingCRM, setLoadingCRM] = useState(false)
  const { data: flags, isLoading: loadingFlags } = useQuery('featureFlags', async () => {
    return await getFeatureFlags()
  })
  const [showSnapshotUpgradeModal, setShowSnapshotUpgradeModal] = useState(false)

  useEffect(() => {
    if (!crmIntegration && !loadingCRM) {
      setLoadingCRM(true)
      getIntegrationFeature('crm.abe')
        .then(response => {
          // Default to none for users that don't have a crm integration
          setCRMIntegration(response?.data?.attributes?.integration?.name || 'none')
          setLoadingCRM(false)
        })
        .catch(_ => {
          setCRMIntegration('none')
          setLoadingCRM(false)
        })
    }
  }, [crmIntegration, loadingCRM])

  // useEffect(() => {
  //   // Set up event listener for Snapshot Upgrade Modal
  //   const checkQueryParams = () => {
  //     const searchParams = new URLSearchParams(window.location.hash.split('?')[1] || window.location.search)
  //     if (searchParams.get('snapshot_upgrade') === 'true') {
  //       setShowSnapshotUpgradeModal(true)
  //     }
  //   }

  //   checkQueryParams()
  //   window.addEventListener('queryParamChange', checkQueryParams)

  //   return () => {
  //     window.removeEventListener('queryParamChange', checkQueryParams)
  //   }
  // }, [])

  const closeSnapshotModal = () => {
    const searchParams = new URLSearchParams(window.location.hash.split('?')[1] || window.location.search)
    if (searchParams.get('snapshot_upgrade')) {
      searchParams.delete('snapshot_upgrade')
      const currentHash = window.location.hash.split('?')[0]
    window.history.pushState({}, '', `${window.location.origin}${window.location.pathname}${currentHash}${searchParams.toString() ? '?' + searchParams.toString() : ''}`)
    }
    setShowSnapshotUpgradeModal(false)
  }

  Emitter.on(EVENT_TYPE.DATA_CHANGED, () => {
    refetchComponents()
  })

  const { data: planComponents, isLoading: loadingPlanComponents, isError, refetch: refetchComponents } = useQuery('componentStates', async () => {
    const response = await getPlanLimits()
    const componentsData: any = {}
    for (const component of response.data) {
      componentsData[component.attributes.name] = component.attributes
    }
    return componentsData
  })

  function isOn (component: string) {
    return !loadingPlanComponents && !isError && planComponents[component] && planComponents[component].on
  }

  function canAdd (component: string) {
    if (loadingPlanComponents || isError || !planComponents[component]) {
      return 0
    }
    return Math.max(planComponents[component].max - planComponents[component].qty, 0)
  }

  function planName () {
    if (loadingPlanComponents || isError || !planComponents.users) {
      return SNAPSHOT_PLAN_NAME
    }
    return planComponents.users.plan_name
  }

  const providerValues: ShareProviderInterface = {
    crmIntegration,
    setCRMIntegration,
    flags: flags || {},
    loadingFlags,
    loadingPlanComponents,
    isOn,
    canAdd,
    planName
  }

  return (
    <ShareContext.Provider value={providerValues}>
      {children}
      {showSnapshotUpgradeModal &&
        <SnapshotUpgradeModal
          open={showSnapshotUpgradeModal}
          onClose={closeSnapshotModal}
          onUpgrade={({ ipCredits, contactLookups, userCount }) => {
            updateToInsights({ ipCredits, contactLookups, userCount })
              .then((res: any) => {
                const updateRes = res.data.attributes
                if (updateRes.service_url) {
                  window.open(updateRes.service_url, '_self')
                  return
                }
                closeSnapshotModal();
                window.location.reload()
              })
          }}
        />}
    </ShareContext.Provider>
  )
}

export { ShareContext, ShareProvider }
