import { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Divider from '@material-ui/core/Divider'
import { cardList } from './mappings'
import { TextOnlyButton } from 'components/TextOnlyButton'
import { Icon } from 'library/materialUI'
import { ShareContext } from 'share-context'

const useStyles = makeStyles(theme => ({
  exteriorCtn: {
    // NOTE: Do not do this on draggable cards
    marginBottom: 10
  },
  addCard: {
    color: theme.palette.primary.main,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '12px',
    cursor: 'pointer',
    zIndex: -2500
  },
  icon: {
    marginRight: 8
  },
  menu: {
    padding: '5px 10px',
    width: 200,
    borderRadius: 10,
    fontFamily: theme.typography.fontFamily
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 7,
    paddingBottom: 7,
    alignItems: 'center',
    fontSize: '14px',
    cursor: 'pointer'
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  },
  purpleText: {
    color: theme.palette.primary.main
  }
}))

const cardDescriptions = [
  'See information about the visitor, like phone number and email',
  'Easily insert a pre-typed message into the chat',
  'See where the visitor came from, including webpage, chatbot, and traffic source',
  'See other recent conversations from a given visitor',
  'Leave notes about a conversation for yourself or others on your team',
  'All of your available conversation tags for easy manual tagging',
  'See which of your segments the visitor falls into',
  'See recent activity across all contacts at the visitor\'s company',
  'Easily see at a glance who is available on your team',
  'See your stats like response rate, response time, chat length',
  'See firmographic data bout the visitor\'s company',
  'See the visiting account\'s Signals Score, account owner, and opp. status'
]

function MenuItem (props) {
  const classes = useStyles()
  const card = props.card
  const index = props.index
  return (
    <div className={classes.menuItem} onClick={props.onClick}>
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '181px' }}>
        <div style={{ fontWeight: 'bold' }}>
          {card.name}
        </div>
        <div style={{ fontSize: '10px' }}>
          {cardDescriptions[index]}
        </div>
      </div>
      {card.pinned ? <Icon icon='pin' size='sm' /> : <></>}
    </div>
  )
}

function MenuContents (props) {
  const length = props.cards.length
  const classes = useStyles()

  const toggleCard = (card) => {
    if (card.pinned) {
      props.removeCard(card.id)
    } else {
      props.addCard(card.id)
    }
  }

  if (!length) {
    return (
      <div className={classes.menu}>
        All cards have been added
      </div>
    )
  }

  return (
    <div id='conversation_details_3' className={classes.menu}>
      {props.cards.map((c, index) => {
        const lastCard = index === length - 1
        return (
          <div key={index}>
            <MenuItem
              index={index}
              card={c}
              onClick={() => toggleCard(c)}
            />
            <Divider className={classes.divider} />
            {lastCard && (
              <div style={{ display: 'flex', flexDirection: 'row', textDecoration: 'underline', fontSize: '12px' }}>
                <div style={{ marginTop: '8px' }}>
                  <Icon icon='smallHelp' size='sm' />
                </div>
                <div style={{ margin: '5px' }}>
                  <a className={classes.purpleText} href='https://help.getsignals.ai/article/miiw5nrhro-customizing-conversation-details' target='_blank' rel='noopener noreferrer'>Learn more about each card</a>
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

function AddCard (props) {
  const classes = useStyles()
  const { flags } = useContext(ShareContext)
  const open = props.open
  const setOpen = props.setOpen
  const currentCards = props.currentCards.map(c => c.id)
  const [cardChoices, setCardChoices] = useState([])
  // const { data: hasTicketing } = useQuery('ticketing', () => getIntegrationFeature('ticketing').then(res => {
  //   const i = res?.data?.attributes?.integration
  //   if (i) {
  //     return true
  //   }
  //   return false
  // }), { staleTime: 120000 })

  useEffect(() => {
    let choices = []
    choices = cardList.map((card) => {
      if (currentCards.includes(card.id)) {
        card.pinned = true
      } else {
        card.pinned = false
      }
      return card
    })
    // if (!hasTicketing) {
    //   choices = choices.filter(c => c.id !== 'addTicket')
    // }
    choices = choices.filter(card => {
      if (card.featureRequired) {
        return flags[card.featureRequired]
      }
      return true
    })
    setCardChoices(choices)
  }, [currentCards.length]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.show) {
    return <></>
  }

  return (
    <div className={classes.exteriorCtn}>
      <TextOnlyButton
        text='Edit Cards'
        onClick={() => setOpen(true)}
      />
      <Popover
        anchorEl={props.anchorEl}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuContents
          cards={cardChoices}
          addCard={props.addCardToList}
          removeCard={props.removeCardFromList}
        />
      </Popover>
    </div>
  )
}

export default AddCard
