import { doPost, doGet, doDelete } from './api'

export interface AIDirective {
  id: number
  prompt: string
  conversation_id?: number
  bot_id?: number
  conversation_context?: string
  type: string
  tags?: string[]
  cloud_agent_id: number
}

export function defaultAIDirective (): AIDirective {
  return {
    id: 0,
    prompt: '',
    type: '',
    cloud_agent_id: 0
  }
}

export interface AIOnboardingData {
  id: number
  prompt: string
  bot_id: number
  objectives: string[]
}

const getAllTrainingData = () => {
  const path = '/api/core/ai_settings/training_data'
  return doGet({ path, useChatURL: false })
}

const getTrainingData = (id: number) => {
  const path = `/api/core/ai_settings/training_data/${id}`
  return doGet({ path, useChatURL: false })
}

const getTrainingByConversationId = (conversationId: number) => {
  const path = `/api/core/ai/get_training_by_conversation/${conversationId}`
  return doGet({ path, useChatURL: false })
}

const getCloudAgentByBotId = (botId: number) => {
  const path = `/api/core/ai_settings/cloud_agents_bot/${botId}`
  return doGet({ path, useChatURL: false })
}

const saveTrainingData = (trainingData: AIDirective) => {
  const path = '/api/core/ai_settings/training_data'
  const data = { attributes: trainingData }
  return doPost({ path, data, useChatURL: false })
}

const deleteTrainingData = (id: number) => {
  const path = `/api/core/ai_settings/training_data/${id}`
  return doDelete({ path, useChatURL: false })
}

const getOnboardingByBotId = (botId: number) => {
  const path = `/api/core/ai_settings/onboarding/${botId}`
  return doGet({ path, useChatURL: false })
}

const getGlobalOnboarding = () => {
  const path = '/api/core/ai_settings/onboarding'
  return doGet({ path, useChatURL: false })
}

export {
  getTrainingData,
  saveTrainingData,
  getTrainingByConversationId,
  getAllTrainingData,
  deleteTrainingData,
  getOnboardingByBotId,
  getGlobalOnboarding,
  getCloudAgentByBotId
}
