import ContactCard from './ContactCard'
import SavedRepliesCard from './SavedRepliesCard'
import ConversationDetailsCard from './ConversationDetails'
import RecentConversations from './RecentConversations'
import NotesCard from './NotesCard'
import TagsCard from './TagsCard'
import SegmentsCard from './SegmentsCard'
import ActivityHistory from './ActivityHistory'
// import AddTicket from './AddTicket'
import AvailabilityCard from './AvailabilityCard'
import PerformanceCard from './PerformanceCard'
import ABECard from './ABECard'
import CompanyInfoCard from './CompanyInfoCard'
import AITrainingCard from './AITrainingCard'
import { CLOUD_AGENT_FLAG } from 'share-context'

/**
 * This list contains all the card options and is ordered
 * how it will appear in the 'Add Card' popover
 */
const masterCardList = [
  { id: 'contactInfo', name: 'Contact info', component: ContactCard, pinned: false },
  { id: 'savedReplies', name: 'Saved replies', component: SavedRepliesCard, pinned: false },
  { id: 'conversationDetails', name: 'Conversation details', component: ConversationDetailsCard, pinned: false },
  { id: 'recentConversations', name: 'Recent conversations', component: RecentConversations, pinned: false },
  { id: 'notes', name: 'Notes', component: NotesCard, pinned: false },
  { id: 'tags', name: 'Tags', component: TagsCard, pinned: false },
  { id: 'segments', name: 'Segments', component: SegmentsCard, pinned: false },
  { id: 'activityHistory', name: 'Activity history', component: ActivityHistory, pinned: false },
  // { id: 'addTicket', name: 'Add Ticket', component: AddTicket, pinned: false },
  { id: 'availabityStatus', name: 'Availability', component: AvailabilityCard, pinned: false },
  { id: 'performanceStats', name: 'Performance', component: PerformanceCard, pinned: false },
  { id: 'companyInfo', name: 'Company info', component: CompanyInfoCard, pinned: false },
  { id: 'abe', name: 'Account-Based Engagement', component: ABECard, pinned: false },
  { id: 'aiTraining', name: 'AI Training', component: AITrainingCard, pinned: false, featureRequired: CLOUD_AGENT_FLAG }
]

const mapping = masterCardList.reduce((acc, { id, component }) => {
  acc[id] = component
  return acc
}, {})

export { mapping, masterCardList as cardList }
