import BotEditTab from './BotEditTab'
import { saveBotDetails } from 'api/bots'
import { getTabs } from './botConfig'
import AppPage from 'cf-components/AppPage'
import ContentPageManagement from 'pages/content_pages/ContentPageManagement'
import BotDetailsPage from './BotDetailsPage'

function ConversationalContentPage (props) {
  const values = props.values
  const setFieldValue = props.setFieldValue
  const flow = props.flow
  const bot = props.bot
  const chatServiceUrl = props.chatServiceUrl
  const botID = props.botID
  const botType = props.botType
  const disabled = props.disabled
  const submit = props.submit
  const publishBot = props.publishBot
  const enabled = bot.attributes.enabled

  function saveDescription (description) {
    if (botID) {
      saveBotDetails({ chatServiceUrl, description, botID })
        .then(response => {
          props.setBot(response.data)
        })
    } else {
      props.setBot(prevState => ({
        ...prevState,
        attributes: {
          ...prevState.attributes,
          description: description
        }
      }))
    }
  }

  const tabs = getTabs(botID, botType)

  return (
    <AppPage
      title={values.name}
      saveName={(name) => setFieldValue('name', name)}
      breadcrumbs={props.breadcrumbs}
      editableTitle={Boolean(bot)}
      tabs={tabs}
    >
      <BotEditTab
        save={submit}
        publishBot={publishBot}
        values={values}
        disabled={disabled}
        enabled={enabled}
      >
        <ContentPageManagement
          values={values}
          setFieldValue={setFieldValue}
          subDomain={props.subdomain}
          bot={bot}
          disabled={disabled}
          flow={flow}
          botParticipant={props.botParticipant}
          flowBuilderDataRef={props.flowBuilderDataRef}
          setFlowID={props.setFlowID}
          setBot={props.setBot}
          canSave={props.canSave}
        />
      </BotEditTab>
      <BotDetailsPage
        bot={bot}
        chatServiceUrl={chatServiceUrl}
        saveDescription={saveDescription}
      />
    </AppPage>
  )
}

export default ConversationalContentPage
