import { makeStyles } from '@material-ui/core/styles'
import { Dialog } from 'library/materialUI'
import { useState } from 'react'
import MuiDialogContent from '@material-ui/core/DialogContent'
import FreeTrialEnded from './FreeTrialEnded'
import ChoosePlan from './ChoosePlan'
import ConfirmPlan from './ConfirmPlan'
import AccountOnHold from './AccountOnHold'
import { BillingProvider } from 'billing-context'
import { Tooltip } from '@material-ui/core'
import { triggerInAppBot } from 'pages/bot/InAppBot/triggerInAppBot'

const useStyles = makeStyles(theme => ({
  colStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  helpButton: {
    alignSelf: 'center',
    width: '30px',
    height: '30px',
    backgroundColor: '#975ce6',
    display: 'flex',
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: '6px',
    justifyContent: 'center',
    textAlign: 'center'
  },
  helpButtonRight: {
    marginLeft: 'auto',
    width: '30px',
    height: '30px',
    backgroundColor: '#975ce6',
    display: 'flex',
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: '6px',
    justifyContent: 'center',
    textAlign: 'center'
  }
}))

type NonActiveAccountProps = {
  inactiveType: string
  initialStage?: number
  allowClose?: boolean
  curPlanType?: number
  defaultPlan?: number
  annual?: boolean
  skipReload?: boolean
}

export default function NonActiveAccountModal (props: NonActiveAccountProps) {
  const classes = useStyles()
  const [modalStage, setModalStage] = useState(props.initialStage || 0)
  const [planType, setPlanType] = useState(props.defaultPlan || 0)
  const curPlanType = props.curPlanType || 0
  const [annual, setAnnual] = useState(props.annual)
  const size = 'md'
  const inactiveType = props.inactiveType
  const allowClose = props.allowClose || false
  const [isOpen, setOpen] = useState(true)
  const closeModal = () => {
    setOpen(false)
    if (!props?.skipReload) {
      window.location.reload()
    }
  }

  return (
    <BillingProvider>
      <div>
        <Dialog
          disableEnforceFocus // setting disableEnforceFocus and disableAutoFocus to true to allow the support bot to take focus
          disableAutoFocus
          onClose={() => (allowClose ? closeModal() : null)}
          open={isOpen}
          fullWidth
          maxWidth={size}
        >
          <MuiDialogContent
            dividers
          >
            <div className={classes.colStyle}>
              <Tooltip title='Need help?'>
                <div onClick={() => triggerInAppBot(1423)} className={classes.helpButtonRight}>
                  ?
                </div>
              </Tooltip>
              {inactiveType !== 'on_hold' && (
                <>
                  {modalStage === 0 && (
                    <FreeTrialEnded setModalStage={setModalStage} />
                  )}
                  {modalStage === 1 && (
                    <ChoosePlan setModalStage={setModalStage} parentSetPlanType={setPlanType} parentSetAnnual={setAnnual} curPlanType={curPlanType} defaultPlan={props.defaultPlan} annual={annual} />
                  )}
                  {modalStage === 2 && (
                    <ConfirmPlan setModalStage={setModalStage} planType={planType} annual={annual} curPlanType={curPlanType} />
                  )}
                </>
              )}
              {inactiveType === 'on_hold' && (
                <>
                  {modalStage === 0 && (
                    <AccountOnHold setModalStage={setModalStage} />
                  )}
                  {modalStage === 1 && (
                    <ChoosePlan setModalStage={setModalStage} parentSetPlanType={setPlanType} parentSetAnnual={setAnnual} curPlanType={curPlanType} defaultPlan={props.defaultPlan} annaul={annual} />
                  )}
                  {modalStage === 2 && (
                    <ConfirmPlan setModalStage={setModalStage} planType={planType} annual={annual} curPlanType={curPlanType} onHold />
                  )}
                </>
              )}
            </div>
          </MuiDialogContent>
        </Dialog>
      </div>
    </BillingProvider>
  )
}
