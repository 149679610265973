import Bot from 'classes/bots'
import { HighIntentCategory } from 'classes/highIntentCategories'
import { Segment } from 'classes/segments'
import { getPosition } from 'library/helpers'
import { Filter } from './CriteriaPicker'
import { timeOptions } from './selectors/ValueSelector'

export const compDict = {
  gt: 'is greater than',
  lt: 'is less than',
  '!contains': 'does not contain',
  known: 'is known',
  unknown: 'is not known',
  eq: 'is'
}

const getFilterValue = (filter: Filter, option: Record<string, any>) => {
  if (option.type === 'time') {
    if (filter.value) {
      const timeOption = timeOptions.filter((option: any) => option.value === filter.value)[0]
      return timeOption.label
    }
  } else if (option.type === 'knownUnknown') {
    return compDict[filter.cmp]
  }
  const compText = filter.cmp in compDict ? compDict[filter.cmp] : filter.cmp
  const value = Array.isArray(filter.value) ? filter.value.join(' or ') : filter.value
  const text = compText + ' ' + value

  return text
}

type FilterTextProps = {
  filter: Filter
  option: Record<string, any>
}

const getPickerText = (object: any, isLoading: boolean, ID: number) => {
  let text = ''
  if (ID && !isLoading) {
    if (ID in object.dict) {
      const name = object.dict[ID].name
      text = '- ' + name
    } else {
      const objectName = object.list.length ? object.list[0].objectType.toUpperCase() : ''
      text = 'DELETED ' + objectName
    }
  }
  return text
}

const getMultiPickerText = (object: any, isLoading: boolean, IDList: number[]) => {
  let text = ''
  if (IDList) {
    IDList.forEach((ID) => {
      if (ID && !isLoading) {
        if (ID in object.dict) {
          const name = object.dict[ID].name
          text += ' - ' + name
        } else {
          const objectName = object.list.length ? object.list[0].objectType.toUpperCase() : ''
          text = 'DELETED ' + objectName
        }
      }
    })
  }
  return text
}

function BotFilterText (props: FilterTextProps) {
  const { data: bots, isLoading } = Bot.loadAll()

  const botID = props.filter.value
  const text = getPickerText(bots, isLoading, botID)

  return (
    <div>
      {text}
    </div>
  )
}

function SegmentFilterText (props: FilterTextProps) {
  const { data: segments, isLoading } = Segment.loadAll()
  const segmentID = props.filter.value
  const text = getPickerText(segments, isLoading, segmentID)

  return (
    <div>
      {text}
    </div>
  )
}

function GeographyFilterText (props: FilterTextProps) {
  const { filter, option } = props
  let text = getFilterValue(filter, option)

  const regions = text.split(' or ')
  const count = regions.length

  if (count > 3) {
    const index = getPosition(text, ' or ', 2)
    text = text.substring(0, index) + ` or ${count - 2} more`
  }

  return (
    <div>
      {text}
    </div>
  )
}

function HighIntentFilterText (props: FilterTextProps) {
  const { data: categories, isLoading } = HighIntentCategory.loadAll()

  const categoryIDList = props.filter.value
  const text = getMultiPickerText(categories, isLoading, categoryIDList)

  return (
    <div>
      {text}
    </div>
  )
}

export function FilterText (props: FilterTextProps) {
  const { filter, option } = props

  if (option.type === 'picker') {
    if (option.objectType === 'bots') {
      return (
        <BotFilterText {...props} />
      )
    } else if (option.objectType === 'segments') {
      return (
        <SegmentFilterText {...props} />
      )
    } else if (option.objectType === 'highIntentCategories') {
      return (
        <HighIntentFilterText {...props} />
      )
    }
  } else if (option.type === 'geography') {
    return (
      <GeographyFilterText {...props} />
    )
  }

  const text = getFilterValue(filter, option)

  return (
    <div>
      {text}
    </div>
  )
}
