import { doPut } from 'api/api'
import { useDoQuery } from './useDoQuery'

const createMapping = (mapping: Record<string, any>[]) => {
  const newMapping: MappingType[] = []
  mapping.forEach((map) => {
    newMapping.push({
      type: map.type,
      value: map.value,
      data_type: map.data_type,
      overwrite: map.overwrite,
      external_field: map.external_field,
      internal_field: map.internal_field
    })
  })
  return newMapping
}
// if you refactor MappingRow, you can change these to CamelCase
interface MappingType {
  type: string
  value: string
  data_type: string
  overwrite: boolean
  external_field: string
  internal_field: string
}

export class IntegrationMappingCls {
  mapping: MappingType[]

  constructor ({ row }: { row: Record<string, any> }) {
    const im = row.attributes
    this.mapping = createMapping(im.mapping)
  }

  static loadOne (objectType: string, integration: string): { data: IntegrationMappingCls, isLoading: boolean } {
    return useDoQuery({ path: `/integrations/crm/mapping/${objectType}`, objectClass: IntegrationMappingCls, extraHeaders: { 'x-integration': integration } })
  }

  static save (objectType: string, mapping: MappingType[], integration: string): {} {
    const data = {
      attributes: {
        mapping: mapping
      }
    }
    return doPut({ path: `/integrations/crm/mapping/${objectType}`, extraHeaders: { 'x-integration': integration }, data })
  }
}
