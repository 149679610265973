import { makeStyles } from '@material-ui/core/styles';
import Picker from 'cf-components/Pickers';
import { Contact } from 'classes/contacts';
import { GetEnabledIntegrations, Integrations } from 'helpers/integrations';
import { Icon, RadioButtons } from 'library/materialUI';
import { dynamicButton, Modal, ModalSection } from 'library/Modal';
import { useContext, useMemo, useState } from 'react';
import { SessionContext, components } from 'session-context';

interface IntegrationModalProps {
  onClose: () => void
  contactID: number
}

const useStyles = makeStyles(theme => ({
  integrationRow: {
    display: 'flex',
    alignItems: 'center'
  },
  iconContainer: {
    display: 'flex',
    marginRight: 5
  }
}))

export default function IntegrationModal ({ onClose, contactID }: IntegrationModalProps) {
  const classes = useStyles()
  const { isOn } = useContext(SessionContext)
  const [enabledIntegrations, setEnabledIntegrations] = useState({
    salesforce: false,
    marketo: false,
    hubspot: false,
    sendinblue: false,
    eloqua: false
  })
  const hasBrevo = isOn(components.BREVO)
  const hasMarketo = isOn(components.MARKETO)
  const hasEloqua = isOn(components.ELOQUA)
  const [selectedIntegration, setSelectedIntegration] = useState<Integrations | null>(null)
  const [selectedList, setSelectedList] = useState<number | null>(null)

  useMemo(() => {
    GetEnabledIntegrations().then((newIntegrations) => setEnabledIntegrations(newIntegrations))
  }, [])

  const buttons: [dynamicButton, dynamicButton, dynamicButton] = [
    {
      text: 'ADD',
      action: () => {
        if (selectedIntegration) {
          Contact.sendToCRMList({ contactId: contactID, listId: selectedList, integration: selectedIntegration })
          onClose()
        } else {
          // set errors
          console.log('todo - set errors')
        }
      },
      color: 'primary'
    },
    { text: 'CANCEL', action: () => onClose() },
    undefined
  ]

  const radioButtons = [
    { value: 'salesforce', label: <div className={classes.integrationRow}> <div className={classes.iconContainer}><Icon icon='salesforce' /></div> Salesforce </div>, disabled: !enabledIntegrations.salesforce },
    { value: 'hubspot', label: <div className={classes.integrationRow}> <div className={classes.iconContainer}><Icon icon='hubspot' /></div> Hubspot </div>, disabled: !enabledIntegrations.hubspot }
  ]
  if (hasBrevo) {
    radioButtons.push({ value: 'sendinblue', label: <div className={classes.integrationRow}> <div className={classes.iconContainer}><Icon icon='brevo' /></div> Brevo </div>, disabled: !enabledIntegrations.sendinblue })
  }
  if (hasMarketo) {
    radioButtons.push({ value: 'marketo', label: <div className={classes.integrationRow}> <div className={classes.iconContainer}><Icon icon='marketo' /></div> Marketo </div>, disabled: !enabledIntegrations.marketo })
  }
  if (hasEloqua) {
    radioButtons.push({ value: 'eloqua', label: <div className={classes.integrationRow}> <div className={classes.iconContainer}><Icon icon='eloqua' /></div> Eloqua </div>, disabled: !enabledIntegrations.eloqua })
  }

  return (
    <Modal
      open
      onHide={() => onClose()}
      title={<p style={{ fontSize: 20 }}>Add to Integration</p>}
      subtitle={<p>Choose an integration to add this contact to</p>}
      handleSave={() => console.log}
      dynamicButtons={buttons}
      noDividers
    >
      <ModalSection
        title={undefined}
      >
        <RadioButtons
          value={undefined}
          handleChange={(value: string) => { setSelectedIntegration(value); }}
          buttons={radioButtons}
        />
        {selectedIntegration === null
          ? <></> : (
            <Picker
              selection={selectedList}
              setSelection={(value: any) => setSelectedList(value)}
              objectType='contactList'
              label='Select a list/campaign'
              loadAllProps={{ searchParams: { extraHeaders: { 'x-integration': selectedIntegration } } }}
              queryBackend
            />
          )}
      </ModalSection>
    </Modal>
  )
}
