import { useState, useEffect, useRef } from 'react'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import OptionPort from '../OptionPort'
// import RichTextEditor from 'cf-components/rich-text/RichTextEditor'
import useNodeStyles from './NodeStyles'
import RenderEmoji from 'pages/chat/RenderEmoji'
import ChatIcon from '@material-ui/icons/Chat'
import AlertIcon from '@material-ui/icons/Warning'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import 'jsplumb'
import '../quill.css'
import { EVENT_TYPE, Emitter } from 'emitter'
import { getBot } from 'api/bots.js'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  questionText: {
    textAlign: 'left',
    font: 'normal normal normal 11px/15px Poppins',
    letterSpacing: 0,
    color: '#989898',
    opacity: 1,
    marginTop: -10,
    marginBottom: 13
  },
  defaultPrompt: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    lineHeight: '21px',
    maxHeight: '100px',
    maxWidth: '340px',
    overflow: 'hidden'
  },
  miniPrompt: {
    borderRadius: '10px',
    fontFamily: 'Open Sans',
    fontSize: '20px',
    lineHeight: '21px',
    marginLeft: '15px',
    maxHeight: '80px',
    maxWidth: '225px',
    overflow: 'hidden',
    display: ['inline-block', 'flex'],
    alignItems: 'center',
    minHeight: '32px',
    padding: '10px 16px',
    '& p': {
      margin: 0,
      maxHeight: '63px'
    }
  }
}))

function MessageModal (props) {
  const classes = useStyles()
  const [text, setText] = useState(props.body)
  const promptStyle = props.botMeta?.prompt_style || 'big'
  const promptSizeRef = useRef(null)
  const isPromptOverflowing = props.isPromptOverflowing
  const setIsPromptOverflowing = props.setIsPromptOverflowing

  useEffect(() => {
    if (!promptSizeRef.current) return
    setIsPromptOverflowing(promptSizeRef.current.scrollHeight > promptSizeRef.current.clientHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  useEffect(() => {
    setText(props.body)
  }, [props.body])

  function cancelChanges () {
    props.onHide()
    setText(props.body)
  }

  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <>
      <ThemeProvider theme={theme}>
        <div
          ref={promptSizeRef}
          dangerouslySetInnerHTML={{ __html: text }}
          className={promptStyle !== 'open' && (promptStyle === 'big' ? classes.defaultPrompt : classes.miniPrompt)}
          style={{ visibility: 'hidden' }}
        />
        <Modal
          open={props.open}
          onHide={cancelChanges}
          handleSave={() => props.save(text.trim())}
          title='Edit Message'
          helplink='home/bots/nodes/Message'
          helplinkLabel='Learn more about this skill'
          saveIcon='save'
        >
          <ModalSection
            subtitle='What message would you like the bot to send to visitors?'
          >
            {!props.isContentBot && (
              <Collapse in={props.isFirstNode && isPromptOverflowing}>
                <Alert severity='warning'>Your message may be too long to fully appear in the bot prompt on some devices and could be partially cut off. To avoid having your message cut off, consider shortening your message.</Alert>
              </Collapse>
            )}
            <RichTextEditor
              message={text}
              onChange={setText}
              lines={3}
              placeholder='Type your message here...'
              showMergeFields
            />
          </ModalSection>
        </Modal>
      </ThemeProvider>
    </>
  )
}

const MessageNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)
  const [isFirstNode, setIsFirstNode] = useState(false)
  const [isPromptOverflowing, setIsPromptOverflowing] = useState(false)
  const nodePromptSizeRef = useRef(null)
  const promptStyle = base.state.botMeta?.prompt_style || 'big'
  const botID = props?.data?.botMeta?.bot_id
  const [isContentBot, setIsContentBot] = useState(false)

  useEffect(() => {
    if (isFirstNode && botID) {
      getBot({ botID }).then(botResponse => {
        if (botResponse.data.attributes.kind === 'content_page') {
          setIsContentBot(true)
        }
      })
    }
  }, [botID, isFirstNode])

  useEffect(() => {
    if (!nodePromptSizeRef.current) return
    setIsPromptOverflowing(nodePromptSizeRef.current.scrollHeight > nodePromptSizeRef.current.clientHeight)
  }, [base.state.body, promptStyle, nodePromptSizeRef])

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = (message) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const new_data = { ...base.state, body: message }
    base.toolkit.updateNode(node, new_data)
    props.setModalOpen(false)
  }

  Emitter.on(EVENT_TYPE.SET_FIRST_NODE, (payload) => {
    if (base?.state?.id === payload?.firstID) {
      setIsFirstNode(true)
    } else {
      setIsFirstNode(false)
    }
  })

  return (
    <>
      <div>
        <div
          className={`${classes.dragger} ${classes.visitorInteraction}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><ChatIcon /></div>
            <div className={classes.cardTitle}>Message</div>
            {isFirstNode && isPromptOverflowing && !isContentBot ? <AlertIcon className={classes.iconDiv} style={{ color: 'yellow', marginLeft: 'auto', marginRight: '10px' }} /> : null}
          </div>
          <div className='node-body' style={{ minHeight: 18, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <RenderEmoji
              text={base.state.body === '' ? 'Enter a message...' : base.state.body}
              rich='true'
              skipLinkPreview
            />
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='visitorInteraction'
                    label='Next'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <MessageModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        isFirstNode={isFirstNode}
        isPromptOverflowing={isPromptOverflowing}
        setIsPromptOverflowing={setIsPromptOverflowing}
        botMeta={base.state.botMeta}
        body={base.state.body}
        isContentBot={isContentBot}
      />
      {/* <div
        ref={nodePromptSizeRef}
        dangerouslySetInnerHTML={{ __html: base.state.body }}
        className={promptStyle !== 'open' && (promptStyle === 'big' ? classes.defaultPrompt : classes.miniPrompt)}
        style={promptStyle !== 'open' && (promptStyle === 'big' ? { visibility: 'hidden', position: 'absolute', maxHeight: '100px', maxWidth: '199px', fontSize: '12px', lineHeight: '21px' } : { visibility: 'hidden', position: 'absolute', fontSize: '20px', lineHeight: '21px', marginLeft: '15px', maxHeight: '80px', maxWidth: '225px', overflow: 'hidden', display: ['inline-block', 'flex'], alignItems: 'center', minHeight: '32px', padding: '10px 16px' })}
      /> */}
    </>
  )
}

export default MessageNode
