export const getSearchParam = (searchString: string, param: string) => {
  const resp = new URLSearchParams(searchString).get(param) || ''

  return resp
}

export const capitalizeString = (value: string) => {
  return value?.charAt(0).toUpperCase() + value?.slice(1) || ''
}

export const abbreviateName = (name: string) => {
  const parts = name.trim().split(' ')
  if (parts.length === 1) { // Handle case where name is an email address
    return name.trim().split('@')[0]
  }
  const firstName = parts[0] || ''
  const lastName = parts[1] ? parts[1][0] + '.' : ''
  return firstName + (lastName ? ' ' + lastName : '')
}

export const removeHtmlTags = (html: string): string => {
  const parsedHtml = new DOMParser().parseFromString(html, 'text/html')
  const text = parsedHtml.body.textContent || ''
  return text
}
