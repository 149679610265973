import React, { useState, useReducer, useContext, useEffect } from 'react'
import AppPage from 'cf-components/AppPage'
import IntegrationsList from './IntegrationsList'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import IntegrationSettings from './IntegrationSettings'
import {
  updateIntegrationFeatures, getIntegrationFeatures
} from 'api/integrations'
import { ShareContext } from 'share-context'

const initialState = {
  saveSettingsDisabled: true,
  changedFeatures: {},
  featureSettings: [],
  maxIntegrations: 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'initialize':
      return { ...state, maxIntegrations: action.maxIntegrations, featureSettings: action.featureSettings }
    case 'updateFeatureSettings':
      return { ...state, featureSettings: action.featureSettings }
    case 'settingsChanged':
      return { ...state, saveSettingsDisabled: action.saveSettingsDisabled, changedFeatures: action.changedFeatures }
    default:
      return state
  }
}

const IntegrationsPage = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const { flags, setCRMIntegration } = useContext(ShareContext)
  const errMessage = (msg) => {
    setSnackState({
      open: true,
      variant: 'error',
      message: msg
    })
  }
  const saveSettingsChanges = () => {
    const changes = Object.keys(state.changedFeatures).map(k => {
      const cf = state.changedFeatures[k]
      return {
        id: k,
        attributes: {
          integrations: cf.integrations,
          integration: cf.integration
        }
      }
    })
    updateIntegrationFeatures(changes).then((response) => {
      dispatch({ type: 'settingsChanged', saveSettingsDisabled: true, changedFeatures: {} })
      setCRMIntegration('')
    }).catch((err) => {
      console.error(err.message)
      errMessage(err.message)
    })
  }
  const settingsProps = {
    disabled: state.saveSettingsDisabled,
    save: saveSettingsChanges,
    saveText: 'Save Settings'
  }
  const settingsChanged = (feature, settings) => {
    const cf = state.changedFeatures
    cf[feature] = settings
    dispatch({ type: 'settingsChanged', saveSettingsDisabled: false, changedFeatures: cf })
  }
  const tabs = [
    { name: 'Integrations', url: '/settings/integrations/list' },
    { name: 'Settings', url: '/settings/integrations/settings', saveProps: settingsProps }
  ]

  document.title = 'Integrations | Signals'

  useEffect(() => {
    getIntegrationFeatures().then((res) => {
      const s = []
      let max = 0
      res.data.forEach((result, _) => {
        const res = result.attributes
        let numIntegrations = res.available_integrations.length
        if (!res.allow_multiple) {
          numIntegrations += 1 // account for 'None' option
        }
        max = Math.max(max, numIntegrations)
        s.push(
          {
            feature: result.id,
            featureLabel: res.feature_label,
            integration: res.integration,
            integrations: res.integrations,
            allow_multiple: res.allow_multiple,
            available_integrations: res.available_integrations
          }
        )
      })
      dispatch({
        type: 'initialize',
        maxIntegrations: max,
        featureSettings: s
      })
    }, (err) => {
      console.log(err)
      // props.errMessage(err.message)
    })
  }, [flags])

  return (
    <AppPage
      title='Integrations'
      tabs={tabs}
      alwaysLoaded={[1]}
      padding={20}
      disabled={state.saveSettingsDisabled}
    >
      <IntegrationsList />
      <IntegrationSettings state={state} dispatch={dispatch} settingsChanged={settingsChanged} errMessage={errMessage} />
      <CustomizedSnackbar state={snackState} handler={setSnackState} />
    </AppPage>
  )
}

export default IntegrationsPage
