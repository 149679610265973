import { doGet, doPatch, doPost, doPut } from './api'
const FEATURES = {
  CRMABE: 'crm.abe',
  CRMENROLL: 'crm.create_contact'
}

const INTEGRATIONS = {
  GoogleAnalytics: 'google_analytics',
  GoogleAds: 'google-ads',
  Zapier: 'zapier',
  Salesforce: 'salesforce',
  Domo: 'domo',
  // Clearbit: 'clearbit',
  Marketo: 'marketo',
  // ZoomInfo: 'zoominfo',
  HubSpot: 'hubspot',
  Sendinblue: 'sendinblue',
  Eloqua: 'eloqua',
  Slack: 'slack',
  // Zendesk: 'zendesk',
  Teams: 'teams',
  // Helpdocs: 'helpdocs',
  Apollo: 'apollo',
  Okta: 'okta',
  ConnectAndSell: 'connectandsell'
}

function addIntegrationSetting ({ integration }: { integration: any }): Promise<any> {
  return doPost({ path: '/integrations', data: integration })
}

// TODO: Deprecate this one. Adapt Zapier and Google Analytics to
// user addIntegrationSetting instead
function addIntegration (integrationValue: string): Promise<any> {
  return addIntegrationSetting({
    integration: {
      type: 'integrations',
      attributes: {
        integration_name: integrationValue,
        enabled: true
      }
    }
  })
}

function getIntegrations (): Promise<any> {
  return doGet({ path: '/integrations' })
}

function getTenantIntegrations (tenantID: number): Promise<any> {
  return doGet({ path: `/integrations/tenant/${tenantID}` })
}

function updateIntegration ({ integration }: { integration: Record<string, any> }): Promise<any> {
  return doPatch({ path: `/integrations/${integration.attributes.integration_name}`, data: integration })
}

function updateIntegrationFeatures (changes: Record<string, any>): Promise<any> {
  return doPatch({ path: '/integrations/features', data: changes })
}

function getIntegrationFeatures (): Promise<any> {
  return doGet({ path: '/integrations/features' })
}

function getIntegrationFeature (feature: string): Promise<any> {
  return doGet({ path: `/integrations/features/${feature}` })
}

function sendIntegrationFeatureRequest (feature: string, integration: string): Promise<any> {
  return doPost({ path: '/integrations/crm/integration_request', data: { attributes: { type: feature, name: integration } } })
}

// TODO: It would be good to remove these with the CRMContext when applicable
function getIntegrationFeatureRequest (feature: string): Promise<any> {
  return doGet({ path: `/integrations/crm/integration_request/${feature}` })
}

function getIntegration (integrationName: string): Promise<any> {
  return doGet({ path: `/integrations/${integrationName}` })
}

function getCredentialsStatus (integration: string): Promise<any> {
  return doGet({ path: `/credentials/${integration}/info` })
}

function patchCredentials (integration: string, credentials: any[]): Promise<any> {
  return doPatch({
    path: `/credentials/${integration}`,
    data: credentials
  })
}

function setCredentials (integration: string, credentials: any[]): Promise<any> {
  return doPut({
    path: `/credentials/${integration}`,
    data: credentials
  })
}

function getIntegrationObjectMappings ({ chatfunnelsObjectType, chatfunnelsObjectID }: { chatfunnelsObjectType: string, chatfunnelsObjectID: string | number }): Promise<any> {
  return doGet({
    path: `/integration_object_mappings?filter[chatfunnels_object_type]=${chatfunnelsObjectType}&filter[chatfunnels_object_id]=${chatfunnelsObjectID}`
  })
}

function getIntegrationFields (integrationName: string, objectType: string): Promise<any> {
  return doGet({ path: `/api/integrations/crm/${objectType}/fields`, extraHeaders: { 'x-integration': integrationName } })
}

function getIntegrationUsers (integrationName: string): Promise<any> {
  return doGet({ path: '/api/integrations/crm/users', extraHeaders: { 'x-integration': integrationName } })
}

function createIntegrationAccount (accountInfo: any, integrationName: string): Promise<any> {
  return doPost({
    path: `/integrations/${integrationName}/sync_account`,
    data: accountInfo,
    useChatURL: true
  })
}

function getAPIKeys (integrationName: string): Promise<any> {
  return doGet({ path: `/auth/api_keys?filter[label]=${integrationName}` })
}

function getCases (contactEmail: string): Promise<any> {
  return doGet({
    path: `/api/integrations/crm/cases?contactEmail=${contactEmail}`
  })
}

function addCase (caseInfo: any) {
  return doPost({
    path: '/api/integrations/crm/cases',
    data: caseInfo,
    skipDataConvert: true
  })
}

interface IntegrationPayload {
  integrationName: string
  enabled?: boolean
  config?: Record<string, string>
  mappings?: Record<string, string>
  eventSettings?: Record<string, string>
}

function updateIntegrationV2 ({ integrationName, enabled, config, mappings, eventSettings }: IntegrationPayload): Promise<any> {
  return doPatch({ path: '/v2/integrations', data: { attributes: { integrationName, enabled, config, mappings, eventSettings } }, silent: true })
}

export {
  FEATURES,
  INTEGRATIONS,
  addIntegration,
  getIntegrations,
  updateIntegration,
  updateIntegrationFeatures,
  getIntegration,
  getIntegrationFeature,
  getIntegrationFeatures,
  sendIntegrationFeatureRequest,
  getIntegrationFeatureRequest,
  addIntegrationSetting,
  getIntegrationObjectMappings,
  getIntegrationFields,
  getIntegrationUsers,
  createIntegrationAccount,
  getCredentialsStatus,
  patchCredentials,
  setCredentials,
  getTenantIntegrations,
  updateIntegrationV2,
  getAPIKeys,
  getCases,
  addCase
}
