import { Field } from 'formik'
import {
  FilterPropSelect,
  FilterCompSelect,
  FilterTextValue,
  FilterMultipleTextValues,
  FilterNumericValue,
  FilterDayOfWeek,
  FilterAvailabilitySelect,
  FilterCurrentTime,
  FilterKnownUnknown,
  FilterEventTimeframe,
  FilterBotNameSelect,
  FilterTeamSelect,
  FilterContactFieldSelect,
  FilterSegments,
  FilterAccountFieldSelect,
  FilterAccountSegments,
  FilterCurrency
} from './FilterProperties'
import { StateSelect, ProvinceSelect, CountrySelect } from './GeographyFilters'
import RemoveIcon from '@material-ui/icons/HighlightOff'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { getCustomFields } from 'api/custom_fields'
import { useQuery } from 'react-query'

const DefaultCompare = ({ propPrefix, filterComps, filterValues, filter, validatorFunctions }) => {
  /** @type {string} */
  const prop = filter.prop
  /** @type {string} */
  const cmp = filter.cmp

  if (!prop) {
    return <></>
  }
  const menuItems = filterComps[prop] || DefaultTextFilterComps
  const filterComponent = filterValues[prop] || FilterTextValue

  return (
    <>
      <Field
        component={FilterCompSelect}
        name={`${propPrefix}.cmp`}
        id={`${propPrefix}.cmp`}
        menuItems={menuItems}
        propPrefix={propPrefix}
        disabled={filter.editable === false}
        propName={prop}
      />
      {cmp === '' || cmp === 'is_known' || cmp === 'not_known' ? <></> : (
        <Field
          component={filterComponent}
          name={`${propPrefix}.value`}
          id={`${propPrefix}.value`}
          propPrefix={propPrefix}
          disabled={filter.editable === false}
          // NOTE: validatorFunctions are only setup for FilterMultipleTextValues currently
          validatorFunction={validatorFunctions[prop]}
        />)}
    </>
  )
}

const ContactCompare = ({ propPrefix }) => {
  return (
    <>
      <div>
        <div style={{ width: 175 }}>
          <Field
            component={FilterContactFieldSelect}
            name={`${propPrefix}.field`}
            id={`${propPrefix}.field`}
            propPrefix={propPrefix}
          />
        </div>
        <br />
        <div style={{ width: 175 }}>
          <Field
            component={FilterCompSelect}
            name={`${propPrefix}.cmp`}
            id={`${propPrefix}.cmp`}
            menuItems={FilterComps.contact}
            propPrefix={propPrefix}
            fullWidth
          />
        </div>
      </div>
      <div>
        <Field
          component={FilterTextValue}
          name={`${propPrefix}.value`}
          id={`${propPrefix}.value`}
          propPrefix={propPrefix}
          label='Value to compare'
        />
      </div>

    </>
  )
}

const AccountCompare = ({ propPrefix, filter }) => {
  const field = filter?.field
  const cmp = filter?.cmp
  const accountFilterValues = {
    days_since_last_visit: FilterNumericValue,
    annual_revenue: FilterCurrency,
    industry: FilterTextValue,
    employee_count: FilterNumericValue,
    address_city: FilterTextValue,
    address_state: FilterTextValue,
    domain: FilterTextValue,
    name: FilterTextValue,
    account_type: FilterTextValue
  }

  const accountFilterComps = {
    annual_revenue: [
      { value: 'gt', name: 'greater than' },
      { value: 'lt', name: 'less than' }
    ],
    industry: [
      { value: 'contains', name: 'contains' },
      { value: 'is', name: 'is' },
      { value: 'does_not_contain', name: 'does not contain' }
      // { value: 'is_not', name: 'is not' }
    ],
    employee_count: [
      { value: 'gt', name: 'greater than' },
      { value: 'lt', name: 'less than' }
    ],
    address_city: [
      { value: 'contains', name: 'contains' },
      { value: 'is', name: 'is' },
      { value: 'does_not_contain', name: 'does not contain' }
      // { value: 'is_not', name: 'is not' }
    ],
    address_state: [
      { value: 'contains', name: 'contains' },
      { value: 'is', name: 'is' },
      { value: 'does_not_contain', name: 'does not contain' }
      // { value: 'is_not', name: 'is not' }
    ],
    domain: [
      { value: 'contains', name: 'contains' },
      { value: 'is', name: 'is' },
      { value: 'does_not_contain', name: 'does not contain' }
      // { value: 'is_not', name: 'is not' }
    ],
    name: [
      { value: 'contains', name: 'contains' },
      { value: 'is', name: 'is' },
      { value: 'does_not_contain', name: 'does not contain' }
      // { value: 'is_not', name: 'is not' }
    ],
    account_type: [
      { value: 'contains', name: 'contains' },
      { value: 'is', name: 'is' },
      { value: 'does_not_contain', name: 'does not contain' }
      // { value: 'is_not', name: 'is not' }
    ]

  }

  return (
    <>
      <div>
        <div style={{ width: 175 }}>
          <Field
            component={FilterAccountFieldSelect}
            name={`${propPrefix}.field`}
            id={`${propPrefix}.field`}
            propPrefix={propPrefix}
          />
        </div>
        <br />
        <div style={{ width: 175 }}>
          <Field
            component={FilterCompSelect}
            name={`${propPrefix}.cmp`}
            id={`${propPrefix}.cmp`}
            menuItems={accountFilterComps[field]}
            propPrefix={propPrefix}
            fullWidth
          />
        </div>
      </div>
      {accountFilterValues[field] && cmp &&
        <div style={{ width: '100%' }}>
          <Field
            component={accountFilterValues[field]}
            name={`${propPrefix}.value`}
            id={`${propPrefix}.value`}
            propPrefix={propPrefix}
            label='Value to compare'
          />
        </div>}

    </>
  )
}

const useStyles = makeStyles(theme => ({
  fields: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr',
    gridGap: 20
  },
  container: {
    padding: '5px 15px 5px 25px',
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: 'auto 50px'
  },
  filterDeleteBtn: {
    height: 50
  }
}))

const FilterProps = [
  // { value: 'url_path', name: 'Page Path', group: 'web_page' },
  { value: 'url', name: 'URL', group: 'web_page' },
  { value: 'country', name: 'Country', group: 'demographics' },
  { value: 'us_state', name: 'US state', group: 'demographics' },
  { value: 'canadian_province', name: 'Canadian province', group: 'demographics' },
  { value: 'visits', name: 'Total website visits', group: 'visitor_behavior' },
  { value: 'days_since_last_visit', name: 'Days since last visit', group: 'visitor_behavior' },
  { value: 'pages', name: 'Total page views', group: 'visitor_behavior' },
  { value: 'traffic_source', name: 'Traffic source', group: 'visitor_behavior' },
  { value: 'day_of_week', name: 'Day of week', group: 'availability' },
  { value: 'company_availability', name: 'Company chat availability', group: 'availability' },
  { value: 'account_owner_availability', name: 'Account owner availability', group: 'availability' },
  { value: 'current_time', name: 'Current time', group: 'availability' },
  { value: 'email', name: "Visitor's email", group: 'visitor_information' },
  { value: 'visitor_phone', name: "Visitor's phone", group: 'visitor_information' },
  { value: 'bot_prompts', name: 'Visitor bot prompts', group: 'visitor_behavior' },
  { value: 'meeting_booked', name: 'Booked a meeting', group: 'visitor_behavior' },
  { value: 'calendar_no_meeting', name: 'Failed to book a meeting from calendar', group: 'visitor_behavior' },
  { value: 'interacted_with_bots', name: 'Interacted with bots', group: 'visitor_behavior' },
  { value: 'team', name: 'Team availability', group: 'availability' },
  { value: 'contacts_email', name: 'Email', group: 'contact_information' },
  { value: 'contacts_first_name', name: 'First Name', group: 'contact_information' },
  { value: 'contacts_last_name', name: 'Last Name', group: 'contact_information' },
  { value: 'contacts_phone', name: 'Phone', group: 'contact_information' },
  { value: 'contacts_lead_score', name: 'Lead Score', group: 'contact_information' },
  { value: 'contacts_city', name: 'City', group: 'contact_information' },
  { value: 'contacts_state', name: 'State', group: 'contact_information' },
  { value: 'contacts_description', name: 'Description', group: 'contact_information' },
  { value: 'contacts_company_name', name: 'Company Name', group: 'contact_information' },
  { value: 'contacts_company_industry', name: 'Industry', group: 'contact_information' },
  { value: 'contacts_company_website', name: 'Company Website', group: 'contact_information' },
  { value: 'contacts_company_employee_count', name: 'Company Employee Count', group: 'contact_information' },
  { value: 'contacts_company_revenue', name: 'Company Revenue', group: 'contact_information' },
  { value: 'contacts_title', name: 'Title', group: 'contact_information' },
  { value: 'accounts_account_type', name: 'Account Type', group: 'account_information' },
  { value: 'accounts_name', name: 'Account Name', group: 'account_information' },
  { value: 'accounts_annual_revenue', name: 'Annual Revenue', group: 'account_information' },
  { value: 'accounts_address_city', name: 'City', group: 'account_information' },
  { value: 'accounts_domain', name: 'Domain', group: 'account_information' },
  { value: 'accounts_employee_count', name: 'Employee Count', group: 'account_information' },
  { value: 'accounts_industry', name: 'Industry', group: 'account_information' },
  { value: 'accounts_address_state', name: 'State', group: 'account_information' }
]

const DefaultTextFilterComps = [
  { value: 'eq', name: 'equals' },
  { value: 'contains', name: 'contains' },
  { value: 'startswith', name: 'starts with' },
  { value: 'endswith', name: 'ends with' }
]

const DefaultNumberFilterComps = [
  { value: 'gt', name: 'greater than' },
  { value: 'lt', name: 'less than' },
  { value: 'eq', name: 'equals' },
  { value: 'ne', name: 'not equal to' }
]

const FilterComps = {
  url: [
    { value: 'contains', name: 'contains' },
    { value: 'does_not_contain', name: 'does not contain' },
    { value: 'is', name: 'is - exact match' }
    // { value: 'ends_with', name: 'ends with' },
    // { value: 'starts_with', name: 'starts with' }
  ],
  url_path: [
    { value: 'contains', name: 'contains' },
    { value: 'does_not_contain', name: 'does not contain' },
    { value: 'is', name: 'is - exact match' }
  ],
  country: [
    { value: 'in', name: 'in' },
    { value: 'not_in', name: 'not in' }
  ],
  us_state: [
    { value: 'in', name: 'in' },
    { value: 'not_in', name: 'not in' }
  ],
  canadian_province: [
    { value: 'in', name: 'in' },
    { value: 'not_in', name: 'not in' }
  ],
  visits: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' }
  ],
  days_since_last_visit: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' },
    { value: 'ne', name: 'not equal to' }
  ],
  pages: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' }
  ],
  day_of_week: [
    { value: 'eq', name: 'is' }
  ],
  company_availability: [
    { value: 'eq', name: 'is' }
  ],
  account_owner_availability: [
    { value: 'eq', name: 'is' }
  ],
  current_time: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' }
  ],
  email: [
    { value: 'eq', name: 'is' }
  ],
  visitor_phone: [
    { value: 'eq', name: 'is' }
  ],
  bot_prompts: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' }
  ],
  meeting_booked: [
    { value: 'lt', name: 'within' }
  ],
  calendar_no_meeting: [
    { value: 'lt', name: 'within' }
  ],
  interacted_with_bots: [
    { value: 'in', name: 'including' }
  ],
  team: [
    { value: 'available', name: 'is available' },
    { value: 'unavailable', name: 'is not available' }
  ],
  contact: [
    { value: 'gt', name: 'greater than' },
    { value: 'lt', name: 'less than' },
    { value: 'eq', name: 'equals' },
    { value: 'contains', name: 'contains' },
    { value: 'startswith', name: 'starts with' },
    { value: 'endswith', name: 'ends with' }
  ],
  segment: [
    { value: 'in', name: 'in' }
  ],
  account_segment: [
    { value: 'in', name: 'in' }
  ],
  contacts_email: DefaultTextFilterComps,
  contacts_first_name: DefaultTextFilterComps,
  contacts_last_name: DefaultTextFilterComps,
  contacts_phone: DefaultTextFilterComps,
  contacts_lead_score: DefaultNumberFilterComps,
  contacts_city: DefaultTextFilterComps,
  contacts_state: DefaultTextFilterComps,
  contacts_description: DefaultTextFilterComps,
  contacts_company_name: DefaultTextFilterComps,
  contacts_company_industry: DefaultTextFilterComps,
  contacts_company_website: DefaultTextFilterComps,
  contacts_company_employee_count: DefaultNumberFilterComps,
  contacts_company_revenue: DefaultNumberFilterComps,
  contacts_title: DefaultTextFilterComps,
  accounts_account_type: DefaultTextFilterComps,
  accounts_name: DefaultTextFilterComps,
  accounts_annual_revenue: DefaultNumberFilterComps,
  accounts_address_city: DefaultTextFilterComps,
  accounts_domain: DefaultTextFilterComps,
  accounts_employee_count: DefaultTextFilterComps,
  accounts_industry: DefaultTextFilterComps,
  accounts_address_state: DefaultTextFilterComps
}

/** @type {{ [prop: string]: (value: string|number) => string | null); }} */
const ValidatorFunctions = {
  url_path: (value) => {
    try {
      if (!value.length) return null
      // eslint-disable-next-line
      if (/^[^\/][^\.\/]+?\.[^\/]/.test(value)) {
        return 'Full URLs not valid'
      }
      return null
    } catch {
      return null
    }
  }
}

const FilterValues = {
  url: FilterMultipleTextValues,
  url_path: FilterMultipleTextValues,
  us_state: StateSelect,
  canadian_province: ProvinceSelect,
  country: CountrySelect,
  day_of_week: FilterDayOfWeek,
  visits: FilterNumericValue,
  pages: FilterNumericValue,
  company_availability: FilterAvailabilitySelect,
  account_owner_availability: FilterAvailabilitySelect,
  current_time: FilterCurrentTime,
  email: FilterKnownUnknown,
  visitor_phone: FilterKnownUnknown,
  bot_prompts: FilterNumericValue,
  meeting_booked: FilterEventTimeframe,
  calendar_no_meeting: FilterEventTimeframe,
  interacted_with_bots: FilterBotNameSelect,
  team: FilterTeamSelect,
  segment: FilterSegments,
  account_segment: FilterAccountSegments,
  days_since_last_visit: FilterNumericValue,
  contacts_email: FilterTextValue,
  contacts_first_name: FilterTextValue,
  contacts_last_name: FilterTextValue,
  contacts_phone: FilterTextValue,
  contacts_lead_score: FilterNumericValue,
  contacts_city: FilterTextValue,
  contacts_state: FilterTextValue,
  contacts_description: FilterTextValue,
  contacts_company_name: FilterTextValue,
  contacts_company_industry: FilterTextValue,
  contacts_company_website: FilterTextValue,
  contacts_company_employee_count: FilterNumericValue,
  contacts_company_revenue: FilterNumericValue,
  contacts_title: FilterTextValue,
  accounts_account_type: FilterTextValue,
  accounts_name: FilterTextValue,
  accounts_annual_revenue: FilterNumericValue,
  accounts_address_city: FilterTextValue,
  accounts_domain: FilterTextValue,
  accounts_employee_count: FilterTextValue,
  accounts_industry: FilterTextValue,
  accounts_address_state: FilterTextValue
}

function FilterSelector (props) {
  const propPrefix = props.propPrefix
  const classes = useStyles()
  const { data: customPeopleFields } = useQuery(['custom-fields', 'people'], () => getCustomFields('people').then(res => res.data.map(({ attributes }) => ({
    value: `contacts_${attributes.slug}`, name: attributes.label, group: 'custom_contact_fields'
  }))), {
    staleTime: 12000
  })
  const { data: customAccountFields } = useQuery(['custom-fields', 'accounts'], () => getCustomFields('accounts').then(res => res.data.map(({ attributes }) => ({
    value: attributes.slug, name: attributes.label, group: 'custom_account_fields'
  }))), {
    staleTime: 12000
  })
  const propFilter = props.propFilter
  const filterProps = props.customFilters?.filterProps || FilterProps.concat(customPeopleFields || []).concat(customAccountFields || [])
  const filterValues = props.customFilters?.filterValues || FilterValues
  const filterComps = props.customFilters?.filterComps || FilterComps
  const validatorFunctions = props.customFilters?.validatorFunctions || ValidatorFunctions
  const filterGroups = props.customFilters?.filterGroups

  const prop = props.filter.prop
  // const cmp = props.filter.cmp

  if (!filterProps.some(f => f.value === 'account_segment')) {
    filterProps.push({ value: 'account_segment', name: 'Account Segment', group: 'all_segments' })
  }

  let filteredMenuItems = filterProps
  if (propFilter) {
    filteredMenuItems = filteredMenuItems.filter(propFilter)
  }

  return (
    <div className={classes.container}>
      <div className={classes.fields}>
        <Field
          component={FilterPropSelect}
          name={`${propPrefix}.prop`}
          id={`${propPrefix}.prop`}
          menuItems={filteredMenuItems}
          propPrefix={propPrefix}
          fullWidth
          customFilterGroups={filterGroups}
          disabled={props.filter.editable === false}
        />
        {prop === 'contact' ? (
          <ContactCompare
            propPrefix={propPrefix}
          />
        ) : prop === 'account' ? (
          <AccountCompare
            propPrefix={propPrefix}
            filter={props.filter}
          />
        ) : (
          <DefaultCompare
            propPrefix={propPrefix}
            filterValues={filterValues}
            filter={props.filter}
            filterComps={filterComps}
            validatorFunctions={validatorFunctions}
          />
        )}

      </div>
      {props.filter.editable !== false && (
        <IconButton
          onClick={() => props.removeFilter()}
          className={classes.filterDeleteBtn}
          style={{ color: '#9933ff' }}
        >
          <RemoveIcon />
        </IconButton>
      )}
    </div>
  )
}

export default FilterSelector
