import React, { useEffect, useRef, useState } from 'react'
import Loader from 'library/loading/Loader'
import { EQUALS, GREATER_THAN_OR_EQUAL, IS_NOT, LESS_THAN_OR_EQUAL, parameterizeFilters, QueryFilter, SearchParams } from 'classes/queryHelpers'
import { SignalsScoreHistory } from 'cf-components/SignalsScoreHistory'

declare global {
  interface Window {
    AnimateAddTableRow: (tr: any, rowID: string, e: any) => void
    AnimateRemoveTableRow: (tr: any, rowID: any) => void
  }
}

function LiveViewTable (props: any) {
  const { debouncedSearch } = props
  const mywindow = window as any
  const [url, setUrl] = useState('')
  const participantData = useRef<any>({})
  const [historyOpen, setHistoryOpen] = useState(false)
  const [currScore, setCurrScore] = useState(0)
  const [currDomain, setCurrDomain] = useState('')
  const [scoreAnchorEl, setScoreAnchorEl] = useState<HTMLElement | null>(null)
  const paused = props.paused
  const [queuedAddParticipants, setQueuedAddParticipants] = useState<any>([])
  const [queuedRemoveParticipants, setQueuedRemoveParticipants] = useState<any>([])

  const searchParams: SearchParams = {
    sortColumn: 'session_start',
    sortOrder: 'asc',
    pageNumber: 1,
    pageSize: 20,
    filters: []
  }

  useEffect(() => {
    if (debouncedSearch) {
      searchParams.search = debouncedSearch
    }

    searchParams.filters = formatFilters(props.filters, props.displayRegion)

    setUrl(parameterizeFilters({ path: '/api/core/web/live_view_table', searchParams }))
    // eslint-disable-next-line
  }, [debouncedSearch, props.filters, props.displayRegion])

  useEffect(() => {
    if (url) {
      initTable()
    }
    // eslint-disable-next-line
  }, [])

  // useInterval(() => {
  //   if (props.active) {
  //     initTable()
  //   }
  // }, 10000)

  useEffect(() => {
    const newParticipantData: any = {}
    for (const row of props.liveParticipants) {
      newParticipantData[row.id] = row
    }
    participantData.current = newParticipantData
    // eslint-disable-next-line
  }, [props.liveParticipants])

  useEffect(() => {
    if (!paused && queuedAddParticipants.length > 0) {
      for (const participant of queuedAddParticipants) {
        props.addParticipant(participant.participant)
        window.AnimateAddTableRow(participant.tr, participant.rowID, participant.event)
      }
      setQueuedAddParticipants([])
    }
    if (!paused && queuedRemoveParticipants.length > 0) {
      for (const participant of queuedRemoveParticipants) {
        props.removeParticipant(participant.id)
        window.AnimateRemoveTableRow(participant.tr, participant.rowID)
      }
      setQueuedRemoveParticipants([])
    }
    // eslint-disable-next-line
  }, [paused])

  function initTable () {
    const tableElement = document.getElementById('liveView-body')
    if (tableElement) {
      tableElement.setAttribute('hx-get', url)
      mywindow.htmx.process(tableElement)
      mywindow.htmx.trigger(tableElement, 'revealed')
    }
  }

  const rowEventListener = (e: any) => {
    const tableID = e.detail.tableID
    const rowAction = e.detail.action
    const rowID = e.detail.rowID
    const anchorDiv = document.getElementById(e.detail.anchor)
    const tr = $(e.detail.sender)

    if (tableID === 'liveView-body') {
      switch (rowAction) {
        case 'scoreHoverCancel':
          setHistoryOpen(false)
          break
        case 'scoreHover':
          if (anchorDiv) {
            setScoreAnchorEl(anchorDiv)
            setCurrDomain(e.detail.domain)
            setHistoryOpen(true)
            setCurrScore(e.detail.score)
          }
          break
        case 'load': {
          const rowData = e.detail.data
          if (!paused) {
            props.addParticipant(rowData)
            setTimeout(() => {
              window.AnimateAddTableRow(tr, rowID, e)
            }, 100)
          } else {
            setQueuedAddParticipants([...queuedAddParticipants, { participant: rowData, tr: tr, rowID: rowID, event: e }])
            setQueuedRemoveParticipants(queuedRemoveParticipants.filter((p: any) => p.id !== rowData.id))
          }
          break
        }
        case 'remove': {
          const pID = parseInt(rowID.split('-').pop())
          if (!paused) {
            props.removeParticipant(pID)
            window.AnimateRemoveTableRow(tr, rowID)
          } else {
            setQueuedAddParticipants(queuedAddParticipants.filter((p: any) => p.participant.id !== pID))
            setQueuedRemoveParticipants([...queuedRemoveParticipants, { id: pID, tr: tr, rowID: rowID }])
          }
          break
        }
        default:
          console.warn('Unknown row action', rowAction)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('rowEvent', rowEventListener)
    return () => {
      window.removeEventListener('rowEvent', rowEventListener)
    }
    // eslint-disable-next-line
  }, [paused])

  return (
    <>
      <div
        id='htmx-table'
        style={{ height: '100%', width: 'calc(100% - 12px)', borderLeft: '0.33px solid #F5F5F5', borderRight: '0.33px solid #F5F5F5', borderBottom: '0.33px solid #F5F5F5' }}
      >
        <div
          id='liveView-body-wrapper'
          hx-get={url}
          hx-on--before-request="body = document.getElementById('liveView-body'); if (body) { window.saveScrollTop = body.scrollTop }"
          hx-on--after-request="body = document.getElementById('liveView-body'); if (body) { body.scrollTop = window.saveScrollTop }"
          hx-trigger='revealed'
        >
          <div style={{ margin: 300 }}>
            <Loader size='xlg' />
          </div>
        </div>
      </div>
      <SignalsScoreHistory
        score={currScore}
        placement='left'
        domain={currDomain}
        anchorEl={scoreAnchorEl}
        open={historyOpen}
      />
    </>
  )
}

function formatFilters (filters: any, displayRegion: string): QueryFilter[][] {
  const locationFilters = ['africa', 'asia', 'europe', 'northAmerica', 'oceania', 'southAmerica', 'unitedStates']
  const filterKeys = Object.keys(filters)
  const formattedFilters: QueryFilter[][] = []
  for (const key of filterKeys) {
    switch (key) {
      case 'visits':
        if (filters[key] && filters[key].length === 2) {
          if (filters[key][0] !== 0) {
            formattedFilters.push([{ field: 'total_visits', operator: GREATER_THAN_OR_EQUAL, value: filters[key][0] }])
          }
          if (filters[key][1] !== 10) {
            formattedFilters.push([{ field: 'total_visits', operator: LESS_THAN_OR_EQUAL, value: filters[key][1] }])
          }
        }
        break
      case 'conversations':
        if (filters[key] && filters[key].length === 2) {
          if (filters[key][0] !== 0) {
            formattedFilters.push([{ field: 'conversation_count', operator: GREATER_THAN_OR_EQUAL, value: filters[key][0] }])
          }
          if (filters[key][1] !== 10) {
            formattedFilters.push([{ field: 'conversation_count', operator: LESS_THAN_OR_EQUAL, value: filters[key][1] }])
          }
        }
        break
      case 'pageViews':
        if (filters[key] && filters[key].length === 2) {
          if (filters[key][0] !== 0) {
            formattedFilters.push([{ field: 'page_views', operator: GREATER_THAN_OR_EQUAL, value: filters[key][0] }])
          }
          if (filters[key][1] !== 10) {
            formattedFilters.push([{ field: 'page_views', operator: LESS_THAN_OR_EQUAL, value: filters[key][1] }])
          }
        }
        break
      case 'timeOnSite':
        if (filters[key] && filters[key].length === 2) {
          if (filters[key][0] !== 0) {
            formattedFilters.push([{ field: 'time_on_site', operator: LESS_THAN_OR_EQUAL, value: filters[key][0] }])
          }
          if (filters[key][1] !== 30) {
            formattedFilters.push([{ field: 'time_on_site', operator: GREATER_THAN_OR_EQUAL, value: filters[key][1] }])
          }
        }
        break
      case 'sourceKnown':
        if (filters[key]) {
          formattedFilters.push([{ field: 'referrer', operator: IS_NOT, value: null }])
        }
        break
      case 'contactInfo':
        if (filters[key]) {
          formattedFilters.push([{ field: 'person_id', operator: IS_NOT, value: null }])
        }
        break
      default:
        break
    }
  }
  if (locationFilters.includes(displayRegion)) {
    formattedFilters.push([{ field: 'location', operator: EQUALS, value: displayRegion.charAt(0).toUpperCase() + displayRegion.slice(1) }])
  }
  return formattedFilters
}

export default LiveViewTable
