import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import NotificationsCenter from './NotificationsCenter'
import { useContext, useEffect, useState } from 'react'
import { SessionContext } from 'session-context'
import InAppNotification from 'components/InAppNotification'
import { Emitter, EVENT_TYPE } from 'emitter'

const useStyles = makeStyles(theme => ({
  drawerContents: {
    overflowY: 'auto',
    height: '100%'
  },
  drawerToggleButton: {
    position: 'fixed',
    bottom: 115,
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    padding: 8,
    paddingLeft: 4,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    boxShadow: '2px 2px 3px #AAA',
    backgroundColor: 'white',
    cursor: 'pointer',
    width: '25%'
  }
}))

function DrawerToggleButton (props) {
  const classes = useStyles()
  const handleToggle = props.toggleDrawer

  return (
    <div
      className={classes.drawerToggleButton}
      onClick={handleToggle}
    >
      {props.drawerOpen
        ? <ChevronLeftIcon />
        : <ChevronRightIcon />}
    </div>
  )
}

function NotificationsDrawer (props) {
  const classes = useStyles(props)
  const { user } = useContext(SessionContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    Emitter.on(EVENT_TYPE.NOTIFICATIONS, () => {
      setOpen(!open)
    })
    Emitter.on(EVENT_TYPE.HELP_CENTER, () => {
      setOpen(false)
    })
  }, [open])

  return (
    <>
      <Drawer
        open={open}
        onClose={() => {}}
        variant='persistent'
        anchor='right'
      >
        <div style={{ overflow: 'hidden', height: '100%', width: 375 }}>
          <div
            className={classes.drawerContents}
          >
            <NotificationsCenter
              close={() => {
                Emitter.emit(EVENT_TYPE.CLOSE_DRAWER, {})
                setOpen(false)
              }}
            />
          </div>
        </div>
      </Drawer>
      <InAppNotification
        user={user}
        openNotificationsDrawer={() => setOpen(true)}
      />
    </>
  )
};

export { NotificationsDrawer, DrawerToggleButton }
