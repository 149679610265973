import BetterBaseNode from '../nodes/BetterBaseNode'
import { jsPlumbToolkit, jsPlumbUtil } from 'jsplumbtoolkit'
import GenericPort from '../GenericPort'

function getView (toolkitInstanceRef, propData) {
  class BetterBaseNodeInjectData extends BetterBaseNode {
    get propData () { return propData || {} }
  }

  return ({
    nodes: {
      chat: { component: BetterBaseNodeInjectData },
      options: { component: BetterBaseNodeInjectData },
      start: { component: BetterBaseNodeInjectData },
      email: { component: BetterBaseNodeInjectData },
      lead_score: { component: BetterBaseNodeInjectData },
      lead_stage: { component: BetterBaseNodeInjectData },
      tag: { component: BetterBaseNodeInjectData },
      route: { component: BetterBaseNodeInjectData },
      calendar: { component: BetterBaseNodeInjectData },
      phone: { component: BetterBaseNodeInjectData },
      text_capture: { component: BetterBaseNodeInjectData },
      faq: { component: BetterBaseNodeInjectData },
      live_chat: { component: BetterBaseNodeInjectData },
      alert_agent: { component: BetterBaseNodeInjectData },
      conversation_status: { component: BetterBaseNodeInjectData },
      conversation_rating: { component: BetterBaseNodeInjectData },
      send_email: { component: BetterBaseNodeInjectData },
      create_contact: { component: BetterBaseNodeInjectData },
      goal: { component: BetterBaseNodeInjectData },
      end_flow: { component: BetterBaseNodeInjectData },
      conditional: { component: BetterBaseNodeInjectData },
      sequence: { component: BetterBaseNodeInjectData },
      sequence_output: { component: BetterBaseNodeInjectData },
      cta_button: { component: BetterBaseNodeInjectData }
    },
    edges: {
      default: {
        connector: ['Flowchart', { cornerRadius: 20, cssClass: 'connector' }],
        paintStyle: { outlineWidth: 15, outlineStroke: '#658AE5' },
        anchor: 'Continuous',
        overlays: [
          // eslint-disable-next-line
          ['Label', { location: 0.5, label: '${label}' }],
          ['PlainArrow', { location: 1 }],
          ['Label', {
            cssClass: 'delete-relationship',
            label: '<svg height="25" width="25" fill="white"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"></path></svg>',
            events: {
              tap: (params) => {
                if (!params.data?.edge.id.includes('preview')) {
                  toolkitInstanceRef.current.removeEdge(params.edge)
                }
              }
            }
          }]
        ],
        endpoint: 'Blank'
      }
    },
    ports: {
      default: {
        component: GenericPort,
        paintStyle: { fill: '#f76258' },
        hoverPaintStyle: { fill: '#434343' },
        edgeType: 'common',
        dropOptions: {
          hoverClass: 'drop-hover'
        }
      }
    }
  })
};

const renderParams = {
  layout: {
    type: 'Spring'
  },
  events: {

  },
  enableWheelZoom: true,
  wheelReverse: !!window.chrome,
  zoomToFit: true,
  consumeRightClick: true,
  style: { width: '600px', height: '400px' },
  dragOptions: {
    filter: '.nodrag, .nodrag *',
    grid: [10, 10]
  }
}

function mouseOverElement (id) {
  setTimeout(() => {
    const el = document.getElementById(id)
    if (el) {
      // eslint-disable-next-line
      var event = new MouseEvent('mouseover', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      el.dispatchEvent(event)
      // eslint-disable-next-line
      var leaveEvent = new MouseEvent('mouseout', {
        view: window,
        bubbles: true,
        cancelable: true
      })
      el.dispatchEvent(leaveEvent)
    }
  }, 30)
}

function initializeToolkit ({ toolkitInstanceRef, handleNodeConnectionRef }) {
  toolkitInstanceRef.current = jsPlumbToolkit.newInstance({
    portDataProperty: 'ports',
    beforeStartConnect: function (source, edgeType) {
      const edges = source.getEdges()
      if (edges.length) {
        return false
      }
    },
    beforeDetach: function (source, target, edge) {
      const sourceNode = source.getNode()
      mouseOverElement(sourceNode.id)
      mouseOverElement(target.id)
      if (handleNodeConnectionRef) {
        handleNodeConnectionRef.current(sourceNode, target, 'detach')
      }
      return true
    },
    beforeConnect: function (source, target, edgeData) {
      let parent
      if (source.objectType === 'Port') {
        parent = source.getNode()
      } else if (source.objectType === 'Node') {
        parent = source
      }
      mouseOverElement(parent.id)
      mouseOverElement(target.id)
      if (target.objectType === 'Port') {
        target = target.getNode()
        if (target.data.type !== 'sequence_output') {
          const id = jsPlumbUtil.uuid()
          const data = {
            id: id,
            type: 'common'
          }
          toolkitInstanceRef.current.addEdge({ source, target, data })
          return false
        }
      }
      const edges = source.getEdges()
      if (edges.length) {
        return false
      }
      if (handleNodeConnectionRef) {
        setTimeout(() => {
          handleNodeConnectionRef.current(parent, target, 'connect')
        }, 30)
      }
      return true
    },
    groupFactory: (type, data, callback) => {
      callback(data)
    },
    nodeFactory: (type, data, callback) => {
      data.top = Math.floor(data.top)
      data.left = Math.floor(data.left)
      if (!data.ports) {
        data.ports = [
          { id: 'default' }
        ]
      }
      data.id = data.id ? data.id : jsPlumbUtil.uuid()
      if (!data.body) {
        data.body = ''
      }
      data.type = type
      if (data.type === 'options') {
        data.ports = []
        data.responseType = 'buttons'
        data.invalidResponse = "Sorry, I didn't understand that. Please try again"
        data.secondResponse = "Still didn't get that. Go ahead and try again"
        data.captureTimes = 3
        data.keywordOptions = []
        data.buttons = []
        data.attribute = null
        data.mapAttribute = false
      } else if (data.type === 'faq') {
        data.ports = [
          { id: 'resolved', label: 'That worked' },
          { id: 'again', label: 'Let me ask again' },
          { id: 'live-chat', label: 'Chat with a real person' }
        ]
        data.tags = []
        data.categories = []
        data.faqSource = 'cf-faq'
        data.failureMessage = "Sorry, I don't know how to answer that question"
        data.retryMessage = "Ok, let's try this again. Ask me a question"
      } else if (data.type === 'lead_score') {
        data.score = undefined
      } else if (data.type === 'lead_stage') {
        data.stage = ''
      } else if (data.type === 'email') {
        data.body = 'Please enter your email below.'
        data.unwanted = { spam: false, personal: false, blocked: false }
        data.invalidResponse = 'Sorry, but I need a valid email address to proceed.'
        data.unwantedResponse = 'Sorry, but I need a business email address to proceed.'
        data.secondResponse = 'Let\'s try this one more time. Please enter a valid business email. (your.name@yourcompany.com)'
        data.captureTimes = 3
        data.ports = [
          { id: 'invalid', label: 'Failed to capture email' },
          { id: 'valid', label: 'Successfully captured email' }
        ]
      } else if (data.type === 'tag') {
        data.tags = []
      } else if (data.type === 'goal') {
        data.label = ''
        data.goalType = 'primary'
      } else if (data.type === 'conversation_status') {
        data.closedMessage = ''
        data.status = 'auto-close'
      } else if (data.type === 'alert_agent') {
        data.alertUnavailableAgents = false
        data.routeOptions = [
          { method: 'single_agent', type: 'round_robin', objectIDs: null }
        ]
        data.notification = {
          title: 'New Conversation!',
          message: 'Signals Conversation Alert: {{ visitor.first_name or "SIG_FNAME" }} {{ visitor.last_name or "SIG_LNAME" }} from {{ visitor.company_name or "SIG_COMP_NAME" }} is chatting in, if you\'re available you should join the conversation.'
        }
        data.ports = [
          { id: 'default', label: 'Next' }
        ]
      } else if (data.type === 'live_chat') {
        data.routeMessage = 'Connecting you to someone momentarily.'
        data.ports = [
          { id: 'away', label: 'No one available' },
          { id: 'no_response', label: 'No one responds' }
        ]
        data.fallbackDelay = 30
        data.routeOptions = [
          { method: 'single_agent', type: 'round_robin', objectIDs: null }
        ]
      } else if (data.type === 'route') {
        data.route_type = ''
        data.team_id = null
        data.user_ids = []
        data.method = 'single_agent'
        data.fallback = false
        data.fallback_delay = null
        data.ports = [
          { id: 'routed', label: 'Routed' },
          { id: 'away', label: 'Away' }
        ]
      } else if (data.type === 'calendar') {
        data.method = 'single_agent'
        data.meeting_type = null
        data.route_type = ''
        data.team_id = null
        data.user_ids = []
        data.body = 'Pick a time below 👇'
        data.text_message_reminders = false
        data.follow_up_email = false
        data.ports = [
          { id: 'complete', label: 'Meeting Scheduled' }
        ]
      } else if (data.type === 'phone') {
        data.body = 'Can I get your phone number?'
        data.invalidResponse = "Sorry, I didn't get that. Please enter a valid phone number (e.g. 000-000-0000)"
        data.secondResponse = "Still didn't get that. Please enter a valid number"
        data.captureTimes = 3
        data.country = 'US'
        data.ports = [
          { id: 'invalid', label: 'Failed to capture phone' },
          { id: 'valid', label: 'Successfully captured phone' }
        ]
      } else if (data.type === 'conversation_rating') {
        data.ratingType = 'emojis'
        data.askFeedback = true
        data.ratingPrompt = 'How\'d we do?'
        data.feedbackPrompt = 'We\'d love to hear any feedback about your experience. Please leave it below.'
        // data.endMessage = '';
        data.ports = [
          { id: 'dissatisfied', label: 'Dissatisfied Rating' },
          { id: 'neutral', label: 'Neutral Rating' },
          { id: 'satisfied', label: 'Satisfied Rating' }
        ]
      } else if (data.type === 'end_flow') {
        data.ports = []
      } else if (data.type === 'send_email') {
        data.ports = [
          { id: 'email_known', label: 'Next' }]
        data.emailID = ''
        data.sendDelay = 0
      } else if (data.type === 'conditional') {
        data.ports = [
          { id: 'default', label: 'Fallback Path' }
        ]
      } else if (data.type === 'sequence') {
        if (!data.title) {
          data.title = 'Sequence'
        }
        if (!data.description) {
          data.description = 'Sequence of bot actions'
        }
        data.trackTemplate = data.trackTemplate || false
      } else if (data.type === 'cta_button') {
        data.label = 'Click Here'
        data.open_target = '_parent'
        data.url = ''
        data.color = '#9900EF'
        data.prop_mapping = []
        data.ports = [
          { id: 'default', label: 'Next' }
        ]
      }
      callback(data)
    },
    portFactory: ({ node, type }, data, callback) => {
      node.data.ports.push(data)
      callback(data)
    }
  })
}

export { getView, renderParams, initializeToolkit, mouseOverElement }
