import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BaseCard from './BaseCard'
import { ReactComponent as BotIcon } from 'img/botIcon.svg'
import LanguageIcon from '@material-ui/icons/Language'
import Link from 'cf-components/Link'
import { SessionContext } from 'session-context'
import { ConversationContext } from '../../ConversationContext'
import { Icon } from 'library/materialUI'
import Tooltip from '@material-ui/core/Tooltip'
import TrafficSourceInfo from 'cf-components/TrafficSourceInfo'
import { getCloudAgentByBotId } from 'api/ai_settings'

const useStyles = makeStyles(theme => ({
  icon: {
    fill: theme.palette.primary.main,
    height: 35,
    width: 35,
    marginRight: 10
  },
  details: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
    gridGap: 10,
    height: 40,
    alignItems: 'center',
    marginBottom: 5,
    cursor: 'pointer'
  },
  contents: {
    padding: 8
  },
  text: {
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '215px'
  },
  trafficDetails: {
    display: 'grid',
    gridTemplateColumns: '40px auto',
    gridGap: 10,
    height: 40,
    alignItems: 'center',
    marginBottom: 5,
    cursor: 'default'
  },
  trafficText: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'horizontal'
  }
}))

function BotLink (props) {
  const classes = useStyles()
  const { user } = useContext(SessionContext)
  const conversation = props.conversation
  const botID = conversation?.relationships.bot.data.id
  const botPerms = user.attributes.perms.semi_admin
  const formName = conversation.attributes.form_name
  const formID = conversation.attributes.form_id
  const [link, setLink] = useState(formID ? '/forms/' + formID : '/bots/' + botID)
  const url = conversation?.attributes?.start_url
  let botName = conversation?.attributes.bot_name

  useEffect(() => {
    if (botID) {
      getCloudAgentByBotId(botID).then(res => {
        if (res) {
          setLink('/v2/cloud_agent/settings/' + res.id)
        }
      })
    }
  }, [botID])

  if (!botName) {
    botName = (url.includes('mychatfunnels.com') || url.includes('connect.getsignals.ai') || url.includes('local.chatfunnels.dev:12347')) ? 'Agent Profile Page' : 'Live View'
  }

  if (botPerms) {
    return (
      <Link to={link} target='_blank'>
        <div style={{ fontWeight: 'bold' }}>
          {formID ? (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '10px' }}>
              <div style={{ marginLeft: '2px', marginRight: '16px' }}>
                <Icon icon='factCheckLarge' />
              </div>
              <div className={classes.text} style={{ marginTop: '4px' }}>
                {formName}
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '10px' }}>
              <BotIcon className={classes.icon} style={{ transform: 'scale(1.3)' }} />
              <div className={classes.text} style={{ marginTop: '10px' }}>
                {botName}
              </div>
            </div>
          )}
        </div>
      </Link>
    )
  }

  return (
    <div className={classes.details} style={{ fontWeight: 'bold', cursor: 'auto' }}>
      <BotIcon className={classes.icon} style={{ transform: 'scale(1.3)' }} />
      <div className={classes.text}>
        {botName}
      </div>
    </div>
  )
}

function CardContents (props) {
  const classes = useStyles()
  const startUrl = props.conversation.attributes.start_url
  const url = startUrl ? (startUrl.split('?')[0]).replace('https://', '') : ''
  const referrer = props.conversation.attributes.referrer
  const trafficSource = props.conversation.attributes.traffic_source
  const trafficCampaign = props.conversation.attributes.traffic_campaign

  return (
    <div className={classes.contents}>
      <BotLink
        conversation={props.conversation}
      />
      <a href={startUrl} target='_blank' rel='noopener noreferrer'>
        <div className={classes.details}>
          <LanguageIcon className={classes.icon} />
          <div className={classes.text}>
            {url}
          </div>
        </div>
      </a>
      <div className={classes.trafficDetails}>
        <Tooltip title='Traffic Source' placement='bottom'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon='trafficSource' color='#000000' specificSize={35} />
          </div>
        </Tooltip>
        <div className={classes.trafficText}>
          {referrer}
          <TrafficSourceInfo
            trafficSource={trafficSource}
            trafficCampaign={trafficCampaign}
          />
        </div>
      </div>
    </div>
  )
}

function DetailsCard (props) {
  const conversation = props.conversation

  return (
    <BaseCard
      title='Conversation Details'
      id={props.id}
    >
      <CardContents
        conversation={conversation}
      />
    </BaseCard>
  )
}

function ConversationDetailsCard (props) {
  return (
    <ConversationContext.Consumer>
      {state => (
        <DetailsCard
          conversation={state.conversation}
          id={props.id}
        />
      )}
    </ConversationContext.Consumer>
  )
}

export default ConversationDetailsCard
