import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_usaLow from '@amcharts/amcharts4-geodata/usaLow'
import am4geodata_canadaLow from '@amcharts/amcharts4-geodata/canadaLow'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import OpenOppIcon from 'img/Icon open-target.svg'
import highSignalsScoreIcon from 'img/high-signals-score.svg'
import BuyingCommitteeRoundIcon from 'img/buying-committee-round.svg'
import IcpCircleIcon from 'img/icp-circle.svg'
import DotIcon from 'img/dot_icon.svg'

export const liveViewRelevance = {
  'first-visit': 1,
  'return-visit': 2,
  'high-intent-page-visit': 3,
  'visitor-id': 4,
  'email-visitor': 5,
  'conversation-started': 6,
  'live-chat': 7,
  'calendar-drop': 8,
  'meeting-booked': 9
}

// eslint-disable-next-line
const agentEvents = ['live-chat', 'meeting-booked']
const contactEvents = ['visitor-id']

function createLiveViewMap ({ data, id, setAnchorEl, setHoverParticipant, chartRef, dataRef, displayRegion, rotateRef, setEvents, removeEvent, addEvent, setMostRelevantEvent, autoFocus, currentlyFocused }) {
  am4core.addLicense('CH213476655')
  am4core.addLicense('MP330981081')
  let animate = true
  if (data.length > 80) {
    animate = false
  }
  if (!document.getElementById(id)) {
    return
  }

  let chart

  let timeoutFunction
  if (!chartRef.current) {
    chart = am4core.create('chartdiv', am4maps.MapChart)
    chart.maxZoomLevel = 64
    chart.geodata = am4geodata_worldLow
    chart.projection = new am4maps.projections.Miller()

    const africaCountries = ['AO', 'BF', 'BI', 'BJ', 'BW', 'CD', 'CF', 'CG', 'CI', 'CM', 'DJ', 'DZ', 'EG', 'EH', 'ER', 'ET', 'GA', 'GH', 'GM', 'GN', 'GQ', 'GW', 'KE', 'LR', 'LS', 'LY', 'MA', 'MG', 'ML', 'MR', 'MW', 'MZ', 'NA', 'NE', 'NG', 'RW', 'SD', 'SL', 'SN', 'SO', 'SS', 'SZ', 'TD', 'TG', 'TN', 'TZ', 'UG', 'ZA', 'ZM', 'ZW']
    const asiaCountries = ['AE', 'AF', 'AZ', 'BD', 'BT', 'CN', 'GE', 'ID', 'IN', 'IQ', 'IR', 'JO', 'JP', 'KG', 'KP', 'KR', 'KZ', 'LA', 'LB', 'LK', 'MM', 'MN', 'MY', 'NP', 'PH', 'PK', 'PS', 'RU', 'SA', 'TH', 'TJ', 'TM', 'UZ', 'VN', 'YE']
    const europeCountries = ['AD', 'AL', 'AT', 'AX', 'BA', 'BE', 'BG', 'CH', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FO', 'FR', 'GB', 'GG', 'GI', 'HR', 'HU', 'IE', 'IM', 'IS', 'IT', 'JE', 'LI', 'LT', 'LU', 'LV', 'MC', 'MD', 'ME', 'MK', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'RS', 'SE', 'SI', 'SK', 'SM', 'UA', 'VA', 'XK']
    const northAmericaCountries = ['AG', 'BB', 'BS', 'BZ', 'CA', 'CR', 'CU', 'DM', 'DO', 'GD', 'GT', 'HN', 'HT', 'JM', 'KN', 'LC', 'MX', 'NI', 'PA', 'SV', 'TT', 'US', 'VC']
    const southAmericaCountries = ['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GY', 'PE', 'PY', 'SR', 'UY', 'VE']
    const oceaniaCountries = ['AS', 'AU', 'FJ', 'FM', 'GU', 'KI', 'MH', 'MP', 'NC', 'NR', 'NU', 'NZ', 'PG', 'PN', 'PW', 'SB', 'TK', 'TO', 'TV', 'VU', 'WF', 'WS']

    const worldSeries = chart.series.push(new am4maps.MapPolygonSeries())
    worldSeries.useGeodata = true
    worldSeries.calculateVisualCenter = true
    worldSeries.exclude = ['AQ']

    if (displayRegion === 'unitedStates') {
      worldSeries.include = ['US']
      chart.projection = new am4maps.projections.AlbersUsa()
      chart.homeGeoPoint = { latitude: 38, longitude: -100 }
    } else if (displayRegion === 'africa') {
      worldSeries.include = africaCountries
    } else if (displayRegion === 'asia') {
      worldSeries.include = asiaCountries
      chart.deltaLongitude = -110
      chart.deltaLatitude = 0
      chart.homeGeoPoint = { latitude: 46.0479, longitude: 100.6197 }
      chart.minZoomLevel = 1.3
    } else if (displayRegion === 'europe') {
      worldSeries.include = europeCountries
      chart.homeGeoPoint = { latitude: 52.5260, longitude: 10.2551 }
    } else if (displayRegion === 'northAmerica') {
      worldSeries.include = northAmericaCountries
      chart.deltaLongitude = 100
      chart.deltaLatitude = 0
      chart.minZoomLevel = 2.4
      chart.homeGeoPoint = { latitude: 52.5260, longitude: -105.2551 }
    } else if (displayRegion === 'southAmerica') {
      worldSeries.include = southAmericaCountries
      chart.homeGeoPoint = { latitude: -24.2350, longitude: -60.9253 }
    } else if (displayRegion === 'oceania') {
      worldSeries.include = oceaniaCountries
      chart.deltaLongitude = -140
      chart.deltaLatitude = 0
      chart.homeGeoPoint = { latitude: -25.2744, longitude: 138.7751 }
      chart.minZoomLevel = 2.4
    } else if (displayRegion === 'globe') {
      chart.projection = new am4maps.projections.Orthographic()
      chart.deltaLongitude = 100
      chart.deltaLatitude = -24
      chart.panBehavior = 'rotateLongLat'
    } else if (displayRegion === 'world') {
      chart.homeZoomLevel = 1.5
      chart.homeGeoPoint = { latitude: 25, longitude: 0 }
    }

    const worldGradient = new am4core.LinearGradient()
    worldGradient.addColor(am4core.color('#95C7D8'))
    worldGradient.addColor(am4core.color('#64A3B8'))
    worldGradient.addColor(am4core.color('#3E616D'))
    worldGradient.gradientUnits = 'userSpaceOnUse'
    worldGradient.rotation = 90

    const polygonTemplate = worldSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}'
    polygonTemplate.fill = worldGradient
    polygonTemplate.fillOpacity = 1
    polygonTemplate.stroke = am4core.color('black')
    polygonTemplate.strokeWidth = 0.5

    if (displayRegion === 'globe' || displayRegion === 'world' || displayRegion === 'northAmerica' || displayRegion === 'unitedStates') {
      worldSeries.exclude.push('US')
      const usaSeries = chart.series.push(new am4maps.MapPolygonSeries())
      usaSeries.geodata = am4geodata_usaLow

      const usPolygonTemplate = usaSeries.mapPolygons.template
      usPolygonTemplate.tooltipText = '{name}'
      usPolygonTemplate.fill = worldGradient
      usPolygonTemplate.stroke = am4core.color('black')
      usPolygonTemplate.strokeWidth = 0.5
      const worldPolySeries = chart.series.push(new am4maps.MapPolygonSeries())
      worldPolySeries.north = 90
      worldPolySeries.south = -90
      if (displayRegion !== 'unitedStates') {
        worldSeries.exclude.push('CA')
        const caSeries = chart.series.push(new am4maps.MapPolygonSeries())
        caSeries.geodata = am4geodata_canadaLow
        const caPolygonTemplate = caSeries.mapPolygons.template
        caPolygonTemplate.tooltipText = '{name}'
        caPolygonTemplate.fill = worldGradient
        caPolygonTemplate.stroke = am4core.color('black')
        caPolygonTemplate.strokeWidth = 0.5
      }
    }

    if (displayRegion === 'globe') {
      // Add grid
      const gridSeries = chart.series.push(new am4maps.GraticuleSeries())
      gridSeries.mapLines.template.stroke = am4core.color('#FFFFFF')
      gridSeries.mapLines.template.opacity = 1
      gridSeries.mapLines.template.strokeOpacity = 1
      gridSeries.mapLines.template.fillOpacity = 1
      gridSeries.mapLines.template.opacity = 1
      // Grid version that is less noisy
      // gridSeries.mapLines.template.strokeWidth = 2
      // gridSeries.latitudeStep = 15
      // gridSeries.longitudeStep = 15
      gridSeries.mapLines.template.strokeWidth = 4
      gridSeries.fitExtent = false
      gridSeries.toBack()

      chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color('#2A2A2A')
      chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1
      chart.backgroundSeries.toBack()
    }

    // Add zoom control
    chart.zoomControl = new am4maps.ZoomControl()
    chart.zoomControl.cursorOverStyle = am4core.MouseCursorStyle.pointer
    const plusButton = chart.zoomControl.plusButton
    const minusButton = chart.zoomControl.minusButton
    const buttons = [plusButton, minusButton]
    buttons.forEach(button => {
      button.background.cornerRadius(5, 5, 5, 5)
      button.background.fill = '#ffffff'
      button.background.stroke = '#D3D3D3'
      button.margin(5, 5, 5, 5)
    })

    chart.events.on('ready', setupLiveView)
    chart.events.on('mappositionchanged', updatePositions)
    if (displayRegion === 'globe') {
      chart.events.on('propertychanged', updatePositions)
      chart.events.on('down', function () {
        clearAnimations()
      })
      chart.events.on('up', function () {
        chart.updateCenterGeoPoint()
        rotateGlobe()
      })
    }
  } else {
    chart = chartRef.current
    loadParticipantData()
  }

  if (displayRegion === 'globe') {
    rotateGlobe()
  }

  if (displayRegion === 'world') {
    chart.events.on('wheeldown', (e) => {
      console.log(e.target.zoomLevel)
      if (e.target.zoomLevel <= 1.7) {
        chart.goHome(100)
      }
    })
  }

  function rotateGlobe () {
    if (displayRegion !== 'globe') return
    if (!rotateRef.current) {
      clearAnimations()
      return
    }
    if ((chart.animations.length > 0) || currentlyFocused.current) return
    chart.animate({ property: 'deltaLongitude', to: chart.deltaLongitude + 360 }, 50000, am4core.ease.linear).loop()
  }

  function animatePulse (circle) {
    // const animation = circle.animate([{ property: 'radius', from: 1, to: 20 }, { property: 'strokeWidth', from: 10, to: 2 }, { property: 'strokeOpacity', from: 1, to: 0 }], 2000, am4core.ease.circleOut).loop()
    const animation = circle.animate([{ property: 'strokeWidth', from: 2, to: 0.2 }, { property: 'scale', from: 1, to: 4 }, { property: 'opacity', from: 1, to: 0 }], 2000, am4core.ease.circleOut)
    animation.events.once('animationended', function (event) {
      animatePulse(event.target.object)
    })
  }

  function animateIconChange (image, data) {
    const specialIcons = []
    if (data?.opportunityStatus === 'Open Opp') {
      specialIcons.push(OpenOppIcon)
    }
    if (data?.signalsScore >= 70) {
      specialIcons.push(highSignalsScoreIcon)
    }
    if (data?.fromBuyingCommittee) {
      specialIcons.push(BuyingCommitteeRoundIcon)
    }
    if (data?.isICP) {
      specialIcons.push(IcpCircleIcon)
    }
    if (specialIcons.length === 0) {
      return
    } else {
      image.width = 25
      image.height = 25
    }

    function getNextIcon (currentIcon) {
      const currentIndex = specialIcons.indexOf(currentIcon)
      if (currentIndex === -1 || currentIndex === specialIcons.length - 1) {
        return specialIcons[0]
      } else {
        return specialIcons[currentIndex + 1]
      }
    }

    image.href = getNextIcon(image.href)

    const animation = image.animate([{ property: 'scale', from: 1, to: 1 }], 2000, am4core.ease.circleOut)
    animation.events.once('animationended', function (event) {
      animateIconChange(event.target.object, data)
    })
  }

  function createSeries (heatfield) {
    const series = chart.series.push(new am4maps.MapImageSeries())
    series.dataFields.value = heatfield
    series.tooltip.background.fill = '#00ff00'

    const template = series.mapImages.template
    template.verticalCenter = 'middle'
    template.horizontalCenter = 'middle'
    template.propertyFields.latitude = 'lat'
    template.propertyFields.longitude = 'long'
    template.tooltipHTML = null

    if (animate) {
      const pulse = template.createChild(am4core.Circle)
      pulse.propertyFields.radius = 'blinkRadius'
      pulse.fillOpacity = 0
      pulse.strokeWidth = 2
      pulse.propertyFields.stroke = 'color'
      pulse.verticalCenter = 'middle'
      pulse.horizontalCenter = 'middle'
      // pulse.nonScaling = true

      pulse.events.on('inited', function (event) {
        animatePulse(event.target)
      })
    }
    const marker = template.createChild(am4core.Image)
    marker.href = DotIcon
    marker.width = 10
    marker.height = 10
    marker.nonScaling = true
    marker.horizontalCenter = 'middle'
    marker.verticalCenter = 'middle'
    marker.cursorOverStyle = am4core.MouseCursorStyle.pointer
    // marker.fill = am4core.color('#C4C4C4')
    // marker.stroke = am4core.color('#C4C4C4')

    marker.events.on('inited', function (event) {
      animateIconChange(event.target, event.target.dataItem.dataContext.row)
      const liveEvent = getEvent(event.target)
      if (liveEvent) {
        addEvent(liveEvent)
        setTimeout(() => {
          event.target.dataItem.dataContext.row.eventType = null
          removeEvent(event.target.dataItem.dataContext.row.id)
        }, 5000)
      }
    })

    marker.events.on('over', (ev) => {
      showParticipantInfo(ev.event.target, ev.target.dataItem.dataContext.row)
    })

    marker.events.on('out', (ev) => {
      const mouseX = ev.event.clientX
      const mouseY = ev.event.clientY

      const elementUnderMouse = document.elementFromPoint(mouseX, mouseY)

      const hasParentWithClass = (element, className) => {
        while (element) {
          if (element.classList && element.classList.contains(className)) {
            return true
          }
          element = element.parentElement
        }
        return false
      }

      // Check to see if mouse is hovering over tooltip card
      if (!elementUnderMouse || !hasParentWithClass(elementUnderMouse, 'tooltipCard')) {
        timeoutFunction = setTimeout(() => {
          setAnchorEl(null)
        }, 250)
      } else {
        // Set event listener for when mouse leaves the tooltip card
        const tooltipDiv = document.getElementsByClassName('tooltipCard')[0]
        tooltipDiv.addEventListener('mouseleave', (event) => {
          setAnchorEl(null)
        })
      }
    })

    const label = template.createChild(am4core.Label)
    label.text = '{visitors}'
    label.fill = am4core.color('#fff')
    label.verticalCenter = 'middle'
    label.horizontalCenter = 'middle'
    label.nonScaling = true

    return series
  }

  function loadParticipantData () {
    const defaultSeries = []
    const specialSeries = []
    am4core.array.each(data, function (row) {
      const participant = {
        state: row.region,
        long: am4core.type.toNumber(row.longitude),
        lat: am4core.type.toNumber(row.latitude),
        location: row.location,
        city: row.city,
        count: 1,
        id: row.id
      }

      const newItem = {
        name: participant.location,
        count: participant.count,
        visitors: '',
        lat: participant.lat,
        long: participant.long,
        state: participant.state,
        color: '#C4C4C4',
        radius: 3,
        blinkRadius: 3,
        id: participant.id,
        row: row
      }

      if (row.opportunityStatus === 'Open Opp' || row.signalsScore >= 70 || row.fromBuyingCommittee || row.isICP) {
        specialSeries.push(newItem)
      } else {
        defaultSeries.push(newItem)
      }
    })

    if (dataRef.current) {
      dataRef.current.series.data = [...defaultSeries, ...specialSeries]
    }
  }

  function setupLiveView () {
    dataRef.current = {
      series: createSeries('participants'),
      shownEvents: []
    }
    loadParticipantData()
  }

  function clearAnimations () {
    for (let i = 0; i < chart.animations.length; i++) {
      chart.animations[i].dispose()
    }
  }

  function showParticipantInfo (target, participant) {
    clearTimeout(timeoutFunction)
    setAnchorEl(target)
    setHoverParticipant(participant)
  }

  function getEvent (image) {
    const dataItem = image.dataItem
    if (dataItem.dataContext.row?.eventType) {
      if (image?.dom?.outerHTML.split('<g')[1].includes('display="none"')) return
      const type = dataItem.dataContext.row.eventType
      const id = dataItem.dataContext.row.id
      const anchorEl = image.element.node // Image if there are changes to the series make sure this still will work
      // hidden and visible values are not being set so right now just scraping the html element to see if it's display is none
      const hidden = image?.dom?.outerHTML.split('<g')[1].includes('display="none"')
      let avatar = ''
      let name = ''
      if (contactEvents.includes(type)) {
        name = dataItem.dataContext.row.name
        avatar = dataItem.dataContext.row.person?.avatar
      }
      return { type, id, anchorEl, hidden, name, avatar }
    }
    return null
  }

  function updatePositions () {
    if (dataRef?.current?.series) {
      const events = []
      dataRef.current.series.mapImages.each(function (image) {
        const event = getEvent(image.children.values[1])
        if (event) {
          events.push(event)
        }
      })
      setEvents(events)
    }
  }

  // This function has turned into an absolute mess. Right now it's just set up to work.
  // Eventually we will want to figure out a more clean coded way unless there is none.
  // function determineMostRelevant () {
  //   if (dataRef?.current?.series) {
  //     let mostRelevant = null
  //     let mostRelevantRelevance = 0
  //     let mostRelevantLat = null
  //     let mostRelevantLong = null
  //     let mostRelevantDot = null
  //     let mostRelevantParticipant = null
  //     let mostRelevantEventID = null
  //     const shownEvents = dataRef.current.shownEvents

  //     dataRef.current.series.mapImages.each(function (image) {
  //       if (mostRelevant === 'meeting-booked') return
  //       const type = image.dataItem.dataContext.row?.eventType
  //       const eventID = image.dataItem.dataContext.row?.eventID
  //       if (type && eventID) {
  //         if (dataRef.current.shownEvents.includes(eventID)) return
  //         const relevanceValue = liveViewRelevance[type]
  //         if (relevanceValue > mostRelevantRelevance) {
  //           mostRelevant = type
  //           mostRelevantRelevance = relevanceValue
  //           mostRelevantLat = image.dataItem.dataContext.lat
  //           mostRelevantLong = image.dataItem.dataContext.long
  //           mostRelevantParticipant = image.dataItem.dataContext.row
  //           mostRelevantDot = image.children.values[1].element.node // Image if there are changes to the series make sure this still will work
  //           mostRelevantEventID = eventID
  //         }
  //       }
  //     })
  //     const animationTime = 1500
  //     if (mostRelevant) {
  //       if (!shownEvents.includes(mostRelevantEventID)) setMostRelevantEvent(mostRelevant)
  //       if (autoFocus) {
  //         if (currentlyFocused.current) showParticipantInfo(mostRelevantDot, mostRelevantParticipant) // Since long focus time we don't want refreshes to kill the popup from showing
  //         if (shownEvents.includes(mostRelevantEventID)) return
  //         dataRef.current.shownEvents.push(mostRelevantEventID)
  //         currentlyFocused.current = true // This is to prevent the globe from rotating while focused on an event
  //         if (displayRegion === 'globe') {
  //           clearAnimations()
  //           const animation1 = chart.animate({ property: 'deltaLongitude', to: -mostRelevantLong }, animationTime)
  //           const animation2 = chart.animate({ property: 'deltaLatitude', to: -mostRelevantLat }, animationTime)
  //           const animations = [animation1, animation2]
  //           animations.forEach(animation => {
  //             animation.events.on('animationended', () => {
  //               if (!animation1.isFinished || !animation2.isFinished) return
  //               chart.zoomToGeoPoint({ latitude: mostRelevantLat, longitude: mostRelevantLong }, 4, true, animationTime)
  //               showParticipantInfo(mostRelevantDot, mostRelevantParticipant)
  //               setTimeout(() => {
  //                 chart.goHome(animationTime)
  //                 if (rotateRef.current) {
  //                   const revertAnimate = chart.animate({ property: 'deltaLatitude', to: -24 }, animationTime)
  //                   revertAnimate.events.on('animationended', () => {
  //                     setAnchorEl(null)
  //                     clearAnimations()
  //                     currentlyFocused.current = false
  //                     rotateGlobe()
  //                   })
  //                 } else {
  //                   setAnchorEl(null)
  //                   clearAnimations()
  //                   currentlyFocused.current = false
  //                 }
  //               }, [10000])
  //             })
  //           })
  //         } else {
  //           chart.goHome(animationTime)
  //           chart.zoomToGeoPoint({ latitude: mostRelevantLat, longitude: mostRelevantLong }, 4, true, animationTime)
  //           showParticipantInfo(mostRelevantDot, mostRelevantParticipant)
  //           setTimeout(() => {
  //             setAnchorEl(null)
  //             chart.goHome(animationTime)
  //             currentlyFocused.current = false
  //           }, [10000])
  //         }
  //       }
  //     }
  //   }
  // }

  return chart
}

export { createLiveViewMap }
