import SettingsCard from 'cf-components/SettingsCard'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SalesforceIcon from 'img/salesforce_icon.png'
import GoogleAnalyticsIcon from 'img/ga_icon.png'
import HubSpotIcon from 'img/hubspot.svg'
import ZapierIcon from 'img/zapier_icon.png'
import DomoIcon from 'img/domo_icon.png'
import MarketoIcon from 'img/marketo_icon.png'
import EloquaLogo from 'img/eloqua_logo.png'
import SlackIcon from 'img/slack_icon.png'
import TeamsIcon from 'img/ms_teams_logo.png'
import BrevoLogo from 'img/brevo_logo.png'
import { Checkbox, Radio, FormControlLabel, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles({
  container: {
    width: '100%'
  },
  searchBar: {
    width: '15%',
    minWidth: 200,
    height: '100%',
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 30
  },
  integrationsDashboard: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'rgba(0, 0, 0, 0.8)',
    alignItems: 'center',
    padding: '20px 20px 10px 20px',
    boxShadow: '0px 4px 4px -5px rgba(0,0,0,0.5)',
    height: 40
  },
  headerImage: {
    height: 20,
    top: 6,
    position: 'relative'
  },
  disabledHeaderImage: {
    height: 20,
    top: 6,
    position: 'relative',
    filter: 'grayscale(100%)'
  },
  icon: {
    width: 40
  },
  description: {
    height: 60,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '2'
  }
})

const IntegrationSettings = (props) => {
  const classes = useStyles()
  const state = props.state
  const dispatch = props.dispatch

  const getLogo = (label, integration, enabled) => {
    let src = null
    switch (integration) {
      case 'salesforce':
        src = SalesforceIcon
        break
      // case 'helpdocs':
      //   src = HelpdocsLogo
      //   break
      case 'googleanalytics':
        src = GoogleAnalyticsIcon
        break
      case 'hubspot':
        src = HubSpotIcon
        break
      case 'zapier':
        src = ZapierIcon
        break
      case 'domo':
        src = DomoIcon
        break
      case 'marketo':
        src = MarketoIcon
        break
      case 'eloqua':
        src = EloquaLogo
        break
      case 'slack':
        src = SlackIcon
        break
      case 'msteams':
        src = TeamsIcon
        break
      case 'sendinblue':
        src = BrevoLogo
        break
      default:
        src = null
    }
    let img = (<></>)
    if (src) {
      img = (
        <img
          alt={label + ' Logo'}
          src={src}
          className={
            enabled
              ? classes.headerImage
              : classes.disabledHeaderImage
          }
        />
      )
    }
    return img
  }
  const setIntegrationFeature = (feature, integration, checked) => {
    const settings = []
    state.featureSettings.forEach((fs) => {
      if (fs.feature === feature) {
        if (checked) {
          if (fs.allow_multiple) {
            fs.integrations.push({ name: integration })
          } else {
            if (integration) {
              fs.integration = { name: integration }
            } else {
              fs.integration = ''
            }
          }
        } else {
          if (fs.allow_multiple) {
            const i = fs.integrations.findIndex(i => i.name === integration)
            if (i > -1) {
              fs.integrations.splice(i, 1)
            }
          } else {
            fs.integration = ''
          }
        }
        props.settingsChanged(feature, fs)
      }
      settings.push(fs)
    })
    dispatch({ type: 'updateFeatureSettings', featureSettings: settings })
  }

  if (!state.featureSettings) {
    return <Loader type='spinner' />
  }

  return (
    <>
      <div className={classes.container}>
        <SettingsCard
          title='Settings'
          subtitle='Select the integrations you would like to have enabled for each feature. For certain features, only one integration can be enabled. Other integrations can have multiple integrations enabled.'
          expanded
        >
          <div className={classes.manageBlock}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left' colSpan={1}>
                      <Typography color='primary'>
                        Feature
                      </Typography>
                    </TableCell>
                    <TableCell align='center' colSpan={state.maxIntegrations}>
                      <Typography color='primary'>
                        Integration(s)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.featureSettings
                    .map((row, rowIdx) => {
                      const noneChecked = Boolean(row.integration === '' || row.integration === null)
                      return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={rowIdx}>
                          <TableCell key={rowIdx} align='left'>
                            {row.featureLabel}
                          </TableCell>
                          {row.allow_multiple !== false ? (<></>) : (
                            <TableCell key={rowIdx + '-none'} align='left'>
                              <FormControlLabel
                                control={
                                  <Radio
                                    value=''
                                    checked={noneChecked}
                                    onClick={(e) => setIntegrationFeature(row.feature, '', e.target.checked)}
                                    color='primary'
                                  />
                                }
                                label='None'
                                className={classes.radio}
                              />
                            </TableCell>)}
                          {row.available_integrations.map((i, colIdx) => {
                            const key = `${rowIdx}-${colIdx}`
                            const checked = Boolean(row.allow_multiple ? !!row.integrations.find(ri => i.name === ri.name) : row.integration && row.integration.name === i.name)
                            const disabledMessage = i.enabled ? '' : 'This integration has not been activated and/or enabled.'
                            return (
                              <Tooltip key={key} title={disabledMessage}>
                                <TableCell align='left'>
                                  {row.allow_multiple ? (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={checked}
                                          value={i.integration}
                                          onClick={(e) => setIntegrationFeature(row.feature, i.name, e.target.checked)}
                                          color='primary'
                                          disabled={!i.enabled}
                                        />
                                      }
                                      label={i.label}
                                      className={classes.radio}
                                    />
                                  )
                                    : (
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            checked={checked}
                                            value={i.integration}
                                            onClick={(e) => setIntegrationFeature(row.feature, i.name, e.target.checked)}
                                            color='primary'
                                            disabled={!i.enabled}
                                          />
                                        }
                                        label={i.label}
                                        className={classes.radio}
                                      />)}
                                  {getLogo(i.label, i.name, i.enabled)}
                                </TableCell>
                              </Tooltip>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </SettingsCard>
      </div>
    </>
  )
}

export default IntegrationSettings
