import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  fadeIn: {
    opacity: 1,
    transition: 'opacity .5s ease'
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity .5s ease'
  }
}))

type FaderProps = {
  onScreen: boolean
  text: string
  color: string
  fontSize: string
  fontStyle: string
  marginLeft?: string
  fontWeight?: React.CSSProperties['fontWeight']
}

export function Fader (props: FaderProps) {
  const classes = useStyles()

  return (
    <div
      className={
        clsx({
          [classes.fadeIn]: props.onScreen,
          [classes.fadeOut]: !props.onScreen
        })
      }
    >
      <div style={{
        color: props.color,
        fontSize: props.fontSize,
        fontStyle: props.fontStyle,
        fontWeight: props.fontWeight ? props.fontWeight : 'normal',
        marginLeft: props.marginLeft ? props.marginLeft : '0px',
        fontFamily: 'Poppins'
      }}
      >
        {props.text}
      </div>
    </div>
  )
}
