import { useEffect, useState } from 'react'
import { deleteTrainingData } from 'api/ai_settings'
import CoachingModal from 'pages/settings/AIContent/CoachingModalV2'
import DeleteModal from 'cf-components/DeleteModal'

const mywindow = window as any

function CoachingEventHandler () {
  const [coachingModalOpen, setCoachingModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteID, setDeleteID] = useState(0)
  const [coachingID, setCoachingID] = useState(0)
  const [agentID, setAgentID] = useState(0)
  const [file, setFile] = useState<File | undefined>(undefined)

  useEffect(() => {
    mywindow.addEventListener('rowEvent', function (e: any) {
      const tableID = e.detail.tableID
      const rowID = e.detail.rowID
      const rowAction = e.detail.action
      setAgentID(e.detail.agentID)

      if (tableID === 'training-data-list-bodyv2') {
        switch (rowAction) {
          case 'edit':
            setCoachingID(parseInt(rowID))
            break
          case 'delete':
            setDeleteID(parseInt(rowID))
            break
          default:
            console.warn('Unknown row action', rowAction)
        }
      }
    })

    // tableEventListener
    mywindow.addEventListener('tableEvent', function (e: any) {
      const tableID = e.detail.tableID
      const action = e.detail.action
      setAgentID(e.detail.agentID)

      // coaching table events
      if (tableID === 'training-data-list-bodyv2') {
        switch (action) {
          case 'coaching':
            setCoachingModalOpen(true)
            break
          case 'upload': {
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.accept = '.txt'
            fileInput.onchange = (event) => {
              const target = event.target as HTMLInputElement
              if (target.files && target.files.length > 0) {
                setFile(target.files[0])
              }
            }
            fileInput.click()
            break
          }
          default:
            console.warn('Unknown table action', action)
        }
      }
    })

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (coachingID > 0) {
      setCoachingModalOpen(true)
    }
  }, [coachingID])

  useEffect(() => {
    if (file) {
      setCoachingModalOpen(true)
    }
  }, [file])

  useEffect(() => {
    if (deleteID > 0) {
      setDeleteModalOpen(true)
    }
  }, [deleteID])

  return (
    <>
      <CoachingModal
        open={coachingModalOpen}
        setOpen={setCoachingModalOpen}
        coachingID={coachingID}
        agentID={agentID}
        onSave={() => {
          setCoachingModalOpen(false)
          setCoachingID(0)
          mywindow.htmx.trigger('#training-data-listv2', 'reload')
        }}
        onHide={() => {
          setCoachingModalOpen(false)
          setCoachingID(0)
          setFile(undefined)
        }}
        file={file}
      />
      <DeleteModal
        open={deleteModalOpen}
        title='Delete Coaching'
        object='Coaching data'
        onHide={() => {
          setDeleteModalOpen(false)
          setDeleteID(0)
        }}
        confirmAction={() => {
          deleteTrainingData(deleteID).then(() => {
            setDeleteModalOpen(false)
            setDeleteID(0)
            mywindow.htmx.trigger('#training-data-listv2', 'reload')
          })
        }}
      />
    </>
  )
}

export default CoachingEventHandler
