import { makeStyles } from '@material-ui/core/styles'
import AccessControl from 'components/AccessControl'
import { Route } from 'react-router-dom'
import UserDetailsPage from '../users/UserDetailsPage'
import SavedRepliesPage from './SavedReplies/SavedRepliesPage'
import ConversationTagsPage from './Tags/ConversationTagsPage'
import IntakeSurvey from '../CompanyIntakeForm'
import AgentSurvey from '../AgentIntakeForm'
import InstallPage from './InstallPage/InstallPage'
import MyCalendarPage from './Calendars/MyCalendarPage'
import ChatSettingsPage from './ChatSettings/ChatSettingsPage'
import MySettingsPage from './MySettingsPage'
import EmailManagementPage from './EmailManagement/EmailManagementPage'
import KeyWordGroupsPage from './KeyWordGroups/KeyWordGroupsPage'
import NavBar from './SettingsNavBar'
import MeetingTypesPage from './Meetings/MeetingTypesPage'
import GoogleAnalyticsIntegrationPage from './Integrations/GoogleAnalyticsIntegrationPage'
import GoogleAdsIntegrationPage from './Integrations/GoogleAdsIntegrationPage'
import FAQManagement from './FAQ/FAQManagement'
import SecurityPage from './SecurityPage'
import WarningEmailSnoozePage from './WarningEmailSnoozePage'
import DataPrivacyPage from './DataPrivacyPage'
import ZapierIntegrationPage from './Integrations/ZapierIntegrationPage'
import IntegrationsPage from './Integrations/IntegrationsPage'
import SalesforceIntegrationPages from './Integrations/Salesforce/SalesforceIntegrationPages'
import MarketoIntegrationPage from './Integrations/MarketoIntegrationPage'
import DOMOIntegrationPage from './Integrations/DOMOIntegrationPage'
import ContentPageManagement from '../content_pages/ContentPageManagement'
import HubSpotIntegrationPage from './Integrations/HubSpot/HubSpotIntegrationPage'
import EmailSettingsPage from './EmailSettings/EmailSettingsPage'
import EloquaIntegrationPage from './Integrations/EloquaIntegrationPage'
import SlackIntegrationPage from './Integrations/Slack/SlackIntegrationPage'
import TeamsIntegrationPage from './Integrations/Teams/TeamsIntegrationPage'
import SendinblueIntegrationPage from './Integrations/SendinblueIntegrationPage'
import { HighIntent } from './HighIntentPages/HighIntent'
import BlockedPagesPage from 'pages/settings/BlockedPages/BlockedPages'
import { SignalsScoringPage } from './Scoring/SignalsScoringPage'
import { BillingOverview } from 'pages/billing/BillingOverview'
import OktaIntegrationPage from './Integrations/OktaIntegrationPage'
import GlobalRoutingRulesPage from './GlobalRoutingRulesPage'
import CustomSoundsPage from './CustomSoundsPage/CustomSoundsPage'
import EmailQueryStringPage from './EmailQueryString/EmailQueryStringPage'
import ConnectAndSellIntegrationPage from './Integrations/ConnectAndSellIntegrationPage'
import AIContentPage from './AIContent/AIContentPage'
import { components } from 'session-context'
import { useContext } from 'react'
import { ShareContext, FAQ_FLAG } from 'share-context'

const useStyles = makeStyles(theme => ({
  navbar: {
    marginTop: 0,
    height: '100%',
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderColor: '#d3d3d3'
  },
  settingsContent: {
    height: '100%',
    width: '100%',
    overflowY: 'auto'
  },
  container: {
    display: 'flex',
    alignItems: 'stretch',
    height: '100%'
  }
}))

function SettingsRouter ({ match, location }) {
  const classes = useStyles()
  const { flags } = useContext(ShareContext)

  return (
    <div className={classes.container}>
      <div className={classes.navbar}>
        <NavBar location={location} />
      </div>
      <main className={classes.settingsContent}>
        <Route exact path={`${match.path}`} />
        <Route
          path={`${match.path}/my_settings`}
          component={MySettingsPage}
        />
        <Route
          exact
          path={`${match.path}/my_calendar`}
          component={MyCalendarPage}
        />
        <Route
          exact
          path={`${match.path}/profile/:user_id`}
          component={UserDetailsPage}
        />
        <AccessControl requiredComponent={components.CHAT}>
          <Route
            exact
            path={`${match.path}/saved_replies`}
            component={SavedRepliesPage}
          />
        </AccessControl>
        <AccessControl requiredComponent={components.CHAT}>
          <Route
            exact
            path={`${match.path}/conversation_tags`}
            component={ConversationTagsPage}
          />
        </AccessControl>
        <Route
          exact
          path={`${match.path}/company_profile`}
          component={IntakeSurvey}
        />
        <Route
          exact
          path={`${match.path}/agent_survey`}
          component={AgentSurvey}
        />
        <Route
          path={`${match.path}/chat_settings`}
          component={ChatSettingsPage}
        />
        <AccessControl requiredComponent={components.CHAT}>
          <Route
            exact
            path={`${match.path}/keyword_groups`}
            component={KeyWordGroupsPage}
          />
        </AccessControl>
        <AccessControl requiredComponent={components.EMAILS}>
          <Route
            path={`${match.path}/email_management`}
            component={EmailManagementPage}
          />
        </AccessControl>
        <AccessControl flag={flags[FAQ_FLAG]} requiredComponent={components.CHAT}>
          <Route
            path={`${match.path}/faq_management`}
            component={FAQManagement}
          />
        </AccessControl>
        <Route
          exact
          path={`${match.path}/security`}
          component={SecurityPage}
        />
        <Route
          exact
          path={`${match.path}/data_privacy`}
          component={DataPrivacyPage}
        />
        <AccessControl requiredComponent={components.CALENDAR}>
          <Route
            exact
            path={`${match.path}/meeting_types`}
            component={MeetingTypesPage}
          />
        </AccessControl>
        <AccessControl requiredComponent={components.EMAILS}>
          <Route
            exact
            path={`${match.path}/email_settings`}
            component={EmailSettingsPage}
          />
        </AccessControl>
        <AccessControl requiredComponent={components.CHAT}>
          <Route
            exact
            path={`${match.path}/content_landing_pages`}
            component={ContentPageManagement}
          />
        </AccessControl>
        <Route
          path={`${match.path}/subscriptions`}
          component={BillingOverview}
        />
        {/* <Route
          path={`${match.path}/payment_methods`}
          component={PaymentsPage}
        /> */}
        <Route
          exact
          path={`${match.path}/snooze-warning-email`}
          component={WarningEmailSnoozePage}
        />
        <Route
          exact
          path={`${match.path}/google-analytics-sync`}
          component={GoogleAnalyticsIntegrationPage}
        />
        <Route
          exact
          path={`${match.path}/google-ads`}
          component={GoogleAdsIntegrationPage}
        />
        <Route
          path={`${match.path}/high_intent_pages`}
          component={HighIntent}
        />
        <AccessControl requiredComponent={components.PLAYMAKER}>
          <Route
            path={`${match.path}/blocked_pages`}
            component={BlockedPagesPage}
          />
        </AccessControl>
        <Route
          path={`${match.path}/ai_content`}
          component={AIContentPage}
        />
        <Route
          path={`${match.path}/signals_score`}
          component={SignalsScoringPage}
        />
        <Route
          path={`${match.path}/custom_sounds`}
          component={CustomSoundsPage}
        />
        <AccessControl requiredComponent={components.GLOBAL_ROUTING}>
          <Route
            path={`${match.path}/global_routing`}
            component={GlobalRoutingRulesPage}
          />
        </AccessControl>
        <Route
          path={`${match.path}/mql`}
          component={EmailQueryStringPage}
        />
        <Route
          path={`${match.path}/integrations`}
          component={IntegrationsPage}
        />
        <Route
          path={`${match.path}/salesforce`}
          component={SalesforceIntegrationPages}
        />
        <Route
          exact
          path={`${match.path}/domo`}
          component={DOMOIntegrationPage}
        />
        <Route
          exact
          path={`${match.path}/zapier`}
          component={ZapierIntegrationPage}
        />
        <Route
          exact
          path={`${match.path}/marketo`}
          component={MarketoIntegrationPage}
        />
        <Route
          path={`${match.path}/hubspot`}
          component={HubSpotIntegrationPage}
        />
        <Route
          exact
          path={`${match.path}/eloqua`}
          component={EloquaIntegrationPage}
        />
        <Route
          exact
          path={`${match.path}/slack`}
          component={SlackIntegrationPage}
        />
        {/* <Route
          exact
          path={`${match.path}/helpdocs`}
          component={HelpdocsIntegrationPage}
        /> */}
        <Route
          exact
          path={`${match.path}/sendinblue`}
          component={SendinblueIntegrationPage}
        />
        <Route
          exact
          path={`${match.path}/teams`}
          component={TeamsIntegrationPage}
        />
        <Route
          exact
          path={`${match.path}/okta`}
          component={OktaIntegrationPage}
        />
        <Route
          exact
          path={`${match.path}/connectandsell`}
          component={ConnectAndSellIntegrationPage}
        />
        <Route path={`${match.path}/installation`} component={InstallPage} />
      </main>
    </div>
  )
}

export default SettingsRouter
