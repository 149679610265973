import { nodeMapping } from 'pages/plays/playBuilder/nodes/nodeMapping';
import { SectionProps } from '../HelpCenter';
import { SectionCenter } from '../SectionCenter';
import { HelpCenterCardList } from '../HelpCenterCardList';

const nodeList = [
  'SendEmail', 'FireBot', 'AlertAgent', 'ContactSearch', 'AddToCampaign', 'Delay', 'ContactSearch', 'CampaignEnrollment', 'GeographyBranching', 'AvailabilityBranching', 'VisitorBranching', 'URLBranching', 'SegmentBranching', 'Salesforce', 'Marketo', 'Hubspot', 'Sendinblue', 'Eloqua'
]

export function PlayNodesCenter (props: SectionProps) {
  return (
    <SectionCenter
      homeComponent={<HelpCenterCardList nodeMapping={nodeMapping} nodeList={nodeList} />}
      links={props.links}
      type='skills'
    />
  )
}
