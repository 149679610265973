import {
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react'

const useBasicWebsocket = ({ url, onMessage, onOpen, onError, onClose, shouldReconnect, reconnectDelay, reconnectDelayBackoff, maxReconnectDelay, readyToConnect }) => {
  const socket = useRef(null)
  const [state, setState] = useState({
    readyState: null
  })
  const isOpenDoneRef = useRef(false)
  const sendBuffer = useRef([])
  const reconnectDelayRef = useRef(reconnectDelay || 1000)
  const actualReconnectDelayBackoff = reconnectDelayBackoff || 1
  const actualMaxReconnectDelay = maxReconnectDelay || 10000

  const getReconnectDelay = useCallback(() => {
    const current = reconnectDelayRef.current
    const floor = Math.floor(current * Math.max(actualReconnectDelayBackoff, 1))
    reconnectDelayRef.current = Math.min(floor, actualMaxReconnectDelay)
    return current
  }, [actualMaxReconnectDelay, actualReconnectDelayBackoff])

  if (url && (!socket.current || url !== socket.current.url) && readyToConnect) {
    if (socket.current) {
      socket.current.close(3001, 'url changed')
    }
    socket.current = new WebSocket(url)
  }

  if (!url && socket.current) {
    socket.current.close(3002, 'url is empty')
    socket.current = null
  }

  const updateState = () => {
    setState(state => {
      return { ...state, readyState: socket.current.readyState }
    })
  }

  const drainBuffer = () => {
    if (!isOpenDoneRef.current) {
      return
    }
    if (!socket.current || (socket.current && socket.current.readyState !== WebSocket.OPEN)) {
      return
    }
    let msg
    while ((msg = sendBuffer.current.shift()) !== undefined) {
      socket.current.send(msg)
    }
  }

  if (socket.current) {
    socket.current.onmessage = (event) => {
      onMessage && onMessage({ event, socket: socket.current })
    }
    socket.current.onerror = (event) => {
      updateState()
      onError && onError({ event, socket: socket.current })
    }
    socket.current.onopen = (event) => {
      reconnectDelayRef.current = reconnectDelay || 1000
      updateState()
      onOpen && onOpen({ event, socket: socket.current })
      isOpenDoneRef.current = true
      drainBuffer()
    }
    socket.current.onclose = (event) => {
      if (event.target !== socket.current) {
        return
      }
      updateState()
      if (shouldReconnect && shouldReconnect()) {
        setTimeout(() => {
          reconnect()
        }, getReconnectDelay())
      }
      isOpenDoneRef.current = false
      onClose && onClose({ event, socket: socket.current })
    }
  }

  useEffect(() => {
    if (socket.current && socket.current.readyState !== state.readyState) {
      setState(state => {
        return { ...state, readyState: socket.current.readyState }
      })
    }
  }, [state.readyState])

  const reconnect = useCallback(() => {
    if (socket.current.readyState === WebSocket.OPEN) {
      socket.current.close(3014, 'slow ping')
    } else {
      socket.current = new WebSocket(url)
      updateState()
    }
  }, [url])

  const sendMessage = useCallback((msg) => {
    if (typeof msg !== 'string') {
      msg = JSON.stringify(msg)
    }
    sendBuffer.current.push(msg)
    drainBuffer()
  }, [])

  const disconnect = useCallback((code, reason) => {
    if (socket.current) {
      socket.current.close(code, reason)
    }
  }, [])
  window.onbeforeunload = () => {
    disconnect(1001, 'page change/unload')
  }

  useEffect(() => {
    return () => {
      if (socket.current) {
        socket.current.close(3000, 'socket cleanup')
      }
    }
  }, [])

  return {
    socket,
    readyState: state.readyState,
    reconnect,
    disconnect,
    sendMessage
  }
}

export default useBasicWebsocket
