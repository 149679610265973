export default function SegmentDefaultPage () {
  return (
    <div
      hx-get='/api/core/web/segments/rules'
      hx-target='this'
      hx-swap='outerHTML'
      hx-trigger='load'
    />
  );
}
