import { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import useInterval from 'use-interval'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import ChatIcon from '@material-ui/icons/Chat'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import Badge from '@material-ui/core/Badge'
import ReactDOM from 'react-dom'
import { Redirect } from 'react-router-dom'
import { NotificationContext } from 'notification-context'
import { Button } from '@material-ui/core'
import audio_alert from 'assets/audio_alert.wav'
import NoteIcon from '@material-ui/icons/NoteAdd'
import AlarmOnIcon from '@material-ui/icons/AlarmOn'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import MegaPhoneIconWhite from 'img/MegaPhoneIconWhite.svg'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { getLiveViewOld as getLiveView, contactSearch } from 'api/contacts'
import { MapParticipantList, TempParticipant } from '../pages/chat/live-view/LiveViewPage'
import { NotificationTypes } from 'classes/notifications'
import { Icon } from 'library/materialUI'

const useStyles = makeStyles(theme => ({
  snackBar: {
    color: 'white',
    backgroundColor: '#F09252',
    padding: 0,
    minWidth: 221,
    position: 'relative',
    cursor: 'grab'
  },
  snackBarContent: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'poppins',
    fontSize: '14px',
    padding: '5px 10px',
    maxWidth: 221,
    minHeight: 60
  },
  snackBarMessage: {
    padding: 0
  },
  snackBarAction: {
    marginRight: 0,
    paddingLeft: 0,
    width: 100
  },
  snackBarActionDiv: {
    borderLeft: '1px solid white',
    width: '100%',
    height: 80,
    display: 'grid',
    gridTemplateRows: '1fr 1fr'
  },
  snackBarActionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E3965E'
    }
  },
  badge: {
    zIndex: 1401,
    top: '-8px',
    right: 0
  },
  minimize: {
    position: 'absolute',
    top: '4px',
    fontWeight: 'bolder',
    left: 170,
    cursor: 'pointer'
  },
  dismiss: {
    position: 'absolute',
    top: '4px',
    fontWeight: 'bolder',
    left: 195,
    cursor: 'pointer'
  },
  dismissButton: {
    color: '#FD9038',
    backgroundColor: 'white',
    textAlign: 'center',
    font: 'normal normal normal 12px/17px Poppins',
    letterSpacing: 0,
    border: 'none',
    '&:hover': {
      color: '#FD9038',
      backgroundColor: 'white',
      border: 'none'
    }
  },
  secondWindowDisplay: {
    display: 'flex'
  },
  backArrowDiv: {
    width: 10,
    marginLeft: 5,
    marginTop: '-6px'
  },
  backArrow: {
    height: 20,
    cursor: 'pointer'
  },
  notificationContent: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    paddingLeft: 15,
    cursor: 'pointer',
    width: 150,
    minHeight: 'inherit'
  },
  notificationTitle: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginTop: '8px',
    marginBottom: '8px'
  },
  notificationBody: {
    fontSize: '12px',
    lineHeight: '1.1em',
    marginBottom: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  notificationMainWindowDisplay: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'inherit'
  }
}))

const icons: Record<NotificationTypes, JSX.Element> = {
  meetings: <EventAvailableIcon />,
  notes: <NoteIcon />,
  botTests: <AlarmOnIcon />,
  followees: <GroupAddIcon />,
  alerts: <NotificationsActiveIcon />,
  featureAnnouncement: <img src={MegaPhoneIconWhite} alt='Feature Icon' />,
  routes: <></>,
  calendars: <></>
}

function InAppNotification (props: any) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [showBadge, setShowBadge] = useState(false)
  const [redirect, setRedirect] = useState<string | null>(null)
  const [lastLength, setLastLength] = useState(0)
  const [showSecondWindow, setShowSecondWindow] = useState(false)
  const [dismissedAll, setDismissedAll] = useState(false)
  const hash = window.location.hash
  const { notifications, resolve, dismiss, newNotifications, dismissAll, hide, setHide } = useContext(NotificationContext)
  const playSound = props?.user?.attributes?.notification_settings?.chimes?.notificatison
  const [participant, setParticipant] = useState<TempParticipant | null>(null)
  const [seekParticipant, setSeekParticipant] = useState(false)
  const [siteTitle] = useState(document.title)

  const refreshParticipant = useCallback(() => {
    setTimeout(() => {
      getLiveView()
        .then(response => {
          if (response.data && newNotifications.length > 0) {
            const notif = response.data.filter((row: any) => row.id === newNotifications[0]?.participantId)
            if (notif.length > 0) {
              const contactIDs = response.data.map((row: any) => row.relationships?.is_person?.data?.id).filter((id: any) => id)
              if (contactIDs.length) {
                contactSearch({ contactIDs }).then(contactResponse => {
                  let contactDict = {}
                  contactResponse.data.map(
                    (contact: any) => (contactDict = { ...contactDict, [contact.id]: { ...contact } })
                  )
                  const participantList = MapParticipantList(notif, contactDict)
                  if (participantList) {
                    setParticipant(participantList[0])
                    setSeekParticipant(false)
                  }
                })
              } else {
                const participantList = MapParticipantList(notif, {})
                if (participantList) {
                  setParticipant(participantList[0])
                  setSeekParticipant(false)
                }
              }
            }
          }
        })
    }, 1000)
  }, [newNotifications])

  useEffect(() => {
    if (newNotifications.length === 0) {
      setSeekParticipant(false)
    } else if (!participant) {
      setSeekParticipant(true)
    } else {
      if (newNotifications.length !== lastLength && participant.id !== newNotifications[0].participantId) {
        setSeekParticipant(true)
      } else if (participant.id === newNotifications[0].participantId) {
        setSeekParticipant(false)
      }
    }
  }, [participant, newNotifications, lastLength])

  useInterval(() => {
    if (seekParticipant) {
      refreshParticipant()
    }
  }, 1000)

  useEffect(() => {
    if (hash.includes('chat/') && !hide && notifications.length) {
      const chatID = parseInt(hash.split('chat/')[1])
      if (!isNaN(chatID)) {
        for (const n of notifications) {
          if (n.objectId === chatID && !n.resolvedTimestamp) {
            resolve(n.id as number)
            ReactDOM.unstable_batchedUpdates(() => {
              setOpen(true)
              setShowBadge(true)
            })
          }
        }
      }
    }
  }, [hash, hide, notifications, resolve])

  function handleClose () {
    if (newNotifications.length > 1) {
      setShowSecondWindow(true)
    } else {
      dismiss(newNotifications[0].id as number)
      ReactDOM.unstable_batchedUpdates(() => {
        setOpen(false)
        setShowBadge(false)
      })
    }
  }

  function handleMinimize () {
    ReactDOM.unstable_batchedUpdates(() => {
      setOpen(false)
      setShowBadge(false)
      setHide(true)
    })
  }

  function handleDismiss () {
    dismiss(newNotifications[0].id as number)
    ReactDOM.unstable_batchedUpdates(() => {
      setOpen(false)
      setShowBadge(false)
    })
    setShowSecondWindow(false)
  }

  function handleDismissAll () {
    const ids = newNotifications.map(notification => notification.id)
    dismissAll(ids as number[])
    ReactDOM.unstable_batchedUpdates(() => {
      setOpen(false)
      setShowBadge(false)
    })
    setShowSecondWindow(false)
    setDismissedAll(true)
    setTimeout(() => {
      setDismissedAll(false)
    }, 10000)
  }

  function handleResolve () {
    const notification = newNotifications[0]
    let link = notification?.actionLink || '/#/chat'
    const splitLink = link.split('#')
    if (splitLink.length === 2) {
      link = splitLink[1]
    } else {
      link = '/chat'
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setOpen(false)
      setShowBadge(false)
      if (notification.notificationType !== 'featureAnnouncement') {
        setRedirect(link)
        resolve(notification.id as number)
      } else {
        dismiss(notification.id as number)
        props.openNotificationsDrawer()
      }
    })
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setShowBadge(true)
      }, 200)
    }
  }, [open])

  useEffect(() => {
    setRedirect(null)
  }, [redirect])

  const notificationHandler: { Vars: { OriginalTitle: string, Interval: any }, On: (notificaiton: string) => void, Off: () => void } = {
    Vars: {
      OriginalTitle: siteTitle,
      Interval: null
    },
    On: function (notification: string) {
      // eslint-disable-next-line
      const localThis = this
      localThis.Vars.Interval = setInterval(function () {
        window.document.title = (localThis.Vars.OriginalTitle === window.document.title)
          ? notification
          : localThis.Vars.OriginalTitle
      }, (1000))
    },
    Off: function () {
      clearInterval(this.Vars.Interval)
      this.Vars.Interval = null
      document.title = this.Vars.OriginalTitle
    }
  }
  // eslint-disable-next-line
  const PageTitleNotification = useMemo(() => (notificationHandler), [siteTitle])

  const toggleTitle = useCallback(() => {
    if (!PageTitleNotification.Vars.Interval) {
      PageTitleNotification.On('New Notification!')
    }
    setTimeout(() => {
      PageTitleNotification.Off()
    }, 7000)
  }, [PageTitleNotification])

  useEffect(() => {
    if (!hide && newNotifications.length) {
      setTimeout(() => {
        ReactDOM.unstable_batchedUpdates(() => {
          setOpen(true)
          if (newNotifications.length > lastLength) {
            if (playSound) {
              const snd = new Audio(audio_alert)
              snd.play().catch((e: Error) => {
                if (!e.message.includes('interact with the document first')) {
                  console.log('failed to play alert sound', { err: e })
                }
              })
            }
            toggleTitle()
          }
          setLastLength(newNotifications.length)
        })
      }, 250)
    } else {
      setLastLength(0)
    }
  }, [hide, hash, lastLength, newNotifications, playSound, toggleTitle])

  if (redirect) {
    return <Redirect to={redirect} push />
  }

  if (newNotifications.length === 0 || dismissedAll || hide) {
    return <></>
  }

  const notification = newNotifications[0]

  let icon = icons[notification.notificationType]
  if (!icon) {
    icon = <ChatIcon />
  }

  return (
    <div>
      <div style={{
        position: 'fixed',
        top: 24,
        right: 24,
        height: 80,
        width: 219,
        zIndex: 1400
      }}
      >
        <Badge
          badgeContent={newNotifications.length}
          color='primary'
          classes={{ badge: classes.badge }}
          style={{ visibility: showBadge ? 'visible' : 'hidden' }}
        >
          <Snackbar
            open={open}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            message={
              <div className={classes.snackBarContent}>
                {showSecondWindow ? (
                  <div className={classes.secondWindowDisplay}>
                    <div>
                      <Button
                        onClick={handleDismiss}
                        variant='outlined'
                        color='primary'
                        className={classes.dismissButton}
                      >
                        Dismiss this notification
                      </Button>
                      <div style={{ color: 'white', textAlign: 'center', paddingTop: 5, fontSize: 10, cursor: 'pointer' }} onClick={handleDismissAll}>
                        Dismiss all
                      </div>
                    </div>
                    <div className={classes.backArrowDiv}>
                      <ArrowBackIosIcon className={classes.backArrow} onClick={() => setShowSecondWindow(!showSecondWindow)} />
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className={classes.notificationMainWindowDisplay}>
                      {icon}
                      <div className={classes.notificationContent} onClick={handleResolve}>
                        <div className={classes.notificationTitle}>
                          {notification.title}
                        </div>
                        {notification.body && notification.notificationType !== 'featureAnnouncement' && (
                          <div className={classes.notificationBody}>
                            {notification.body}
                          </div>
                        )}
                      </div>
                      <div className={classes.minimize} onClick={handleMinimize}>
                        <Icon icon='minimize' />
                      </div>
                      <div className={classes.dismiss} onClick={handleClose}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
            ContentProps={{
              classes: {
                root: classes.snackBar,
                message: classes.snackBarMessage,
                action: classes.snackBarAction
              }
            }}
          />
        </Badge>
      </div>
    </div>
  )
}

export default InAppNotification
