import { BillingSkeleton, BillingSkeletonPlan } from 'pages/billing/MyPlan';

export function AdminCloudAgentPage (props: { tenantId: number | null }) {
  return (
    <div
      hx-get={`/api/core/web/cloud_agents/admin/${props.tenantId}`}
      hx-target='this'
      hx-swap='outerHTML'
      hx-trigger='load'
      style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', padding: '20px', width: '100%' }}
    >
      <BillingSkeletonPlan />
      <BillingSkeleton />
      <BillingSkeleton />
      <BillingSkeleton />
      <BillingSkeleton />
      <BillingSkeleton />
      <BillingSkeletonPlan />
    </div>
  );
}
